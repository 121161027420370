import { ErrorBoundary, IconVariant, ToastVariant, useHeaderContent, useNavigationState, useToast } from '@flyward/platform'
import { useGetReportDetailsQuery } from '@flyward/platform/store/slices'
import { isEmpty, isNil } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PageContainer } from '../../../layout'
import { ReportItemAssets } from './ReportItemAssets'
import { ReportBody } from './ReportBody'

const ReportPage = () => {
  const { reportId } = useParams()
  const { setTitle, setHasBackButton } = useHeaderContent()
  const { toggleVisibility } = useNavigationState()
  const { toast } = useToast()

  const onGetReportError = useCallback(() => {
    toast({
      variant: ToastVariant.Destructive,
      description: 'Error loading the report',
      icon: IconVariant.Error,
    })
  }, [toast])

  const {
    data: report,
    isError: isReportError,
    isLoading: isReportLoading,
    error: reportError,
  } = useGetReportDetailsQuery(
    { reportId: reportId! },
    {
      skip: isNil(reportId) || isEmpty(reportId),
    },
  )

  const [selectedAsset, setSelectedAsset] = useState<string | null>(report?.assetWithReportItemList?.[0]?.reportItemId ?? null)

  const handleAssetSelection = (reportId: string) => {
    setSelectedAsset(reportId)
  }

  useEffect(() => {
    if (isReportError) {
      onGetReportError()
    }
  }, [isReportError, onGetReportError])

  useEffect(() => {
    setHasBackButton(true)
    toggleVisibility()

    return () => {
      toggleVisibility()
    }
  }, [setHasBackButton, toggleVisibility])

  useEffect(() => {
    setTitle(`Report ${report?.reportName ?? ''}`)
    setSelectedAsset(report?.assetWithReportItemList?.[0]?.reportItemId ?? null)

    return () => {
      setTitle('')
    }
  }, [setTitle, report])

  if (isNil(report)) {
    return (
      <ErrorBoundary controlledError={reportError as Error} isLoadingControlled={isReportLoading}>
        <span data-testid="loading-report"></span>
      </ErrorBoundary>
    )
  }

  return (
    <PageContainer>
      <ErrorBoundary isLoadingControlled={isReportLoading}>
        <ReportItemAssets assetsData={report.assetWithReportItemList} selectedAsset={selectedAsset} onAssetSelect={handleAssetSelection} />
        {!isNil(selectedAsset) && <ReportBody reportItemId={selectedAsset} />}
      </ErrorBoundary>
    </PageContainer>
  )
}

export { ReportPage }
