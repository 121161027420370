import { APIRoutes } from '@flyward/platform/constants'
import { http, HttpResponse } from 'msw'
import { type IGetMeQueryResponse, type IGetUsersQueryResponse } from './types'
import { Role } from '@flyward/platform/models/enums/Role'
import { UserState } from '@flyward/platform/models/enums/UserState'

const usersApiMocks = [
  http.get(APIRoutes.AppIdentityModule.UserController.GetAllUsersNames(), () => {
    return HttpResponse.json<IGetUsersQueryResponse>([
      {
        userId: '4ffde1e8-4770-4d8e-902e-c19812b2f157',
        email: 'john.doe@example.com',
        firstName: 'John',
        lastName: 'Doe',
        role: Role.Admin,
        state: UserState.Active,
        createdAt: new Date('2022-01-01'),
        isEmailVerified: true,
      },
      {
        userId: '81076ea1-d89f-430a-8770-09c10b4bbb83',
        email: 'jane.smith@example.com',
        firstName: 'Jane',
        lastName: 'Smith',
        role: Role.SuperUser,
        state: UserState.PendingInvitation,
        createdAt: new Date('2023-02-15'),
        isEmailVerified: false,
      },
      {
        userId: 'd5162be4-3b30-4d46-84ed-6beeea4cb31e',
        email: 'bob.johnson@example.com',
        firstName: 'Bob',
        lastName: 'Johnson',
        role: Role.NormalUser,
        state: UserState.Inactive,
        createdAt: new Date('2021-11-30'),
        isEmailVerified: false,
      },
    ])
  }),

  http.get(APIRoutes.AppIdentityModule.UserController.GetMe(), () => {
    return HttpResponse.json<IGetMeQueryResponse>({
      id: '4ffde1e8-4770-4d8e-902e-c19812b2f157',
      email: 'john.doe@example.com',
      fullName: 'John Doe',
      role: Role.Admin,
    })
  }),
]

export { usersApiMocks }
