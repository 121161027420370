import { type ReportSearchDto, type ReportSearchInput, ReportsGrid, ReportsSearchFilter } from '@flyward/forecasts'
import {
  ButtonVariant,
  ComponentContainer,
  ErrorBoundary,
  type IButtonProps,
  IconVariant,
  type IdWithNameDto,
  OrderDirection,
  PageSizes,
  PaginatedResult,
  Size,
  isArrayEmptyOrNull,
  useHeaderContent,
} from '@flyward/platform'
import { useDeleteReportMutation, useGetReportsSearchQuery, useGetUsersNamesQuery } from '@flyward/platform/store/slices'
import { isNil } from 'lodash'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReportsEmptyState } from '../../../assets'
import { PageContainer } from '../../../layout'

export const ReportsPage = () => {
  const navigate = useNavigate()
  const { setTitle, setButtons } = useHeaderContent()

  const [reportSearchInput, setReportSearchInput] = useState<ReportSearchInput>({
    Name: '',
    StartDate: '',
    EndDate: '',
    UserIds: [],
    OrderDirection: OrderDirection.Desc,
    Page: 0,
    PageSize: PageSizes.Twenty,
  })

  // TODO: refactor this to use onFilterUpdateHandler
  const setCurrentPage = (page: number) => {
    setReportSearchInput((oldReportSearchInput: ReportSearchInput) => ({
      ...oldReportSearchInput,
      Page: page,
    }))
  }

  const onFilterUpdateHandler = (filter: Partial<ReportSearchInput>) => {
    setReportSearchInput((oldReportSearchInput: ReportSearchInput) => ({
      ...oldReportSearchInput,
      ...filter,
    }))
    setCurrentPage(0)
  }

  // TODO: refactor this to use onFilterUpdateHandler
  const setPageSize = (size: PageSizes) => {
    setReportSearchInput((oldReportSearchInput: ReportSearchInput) => ({
      ...oldReportSearchInput,
      PageSize: size,
    }))
  }

  // TODO: refactor this to use onFilterUpdateHandler
  const setOrderDirection = (orderDirection: OrderDirection) => {
    setReportSearchInput((oldReportSearchInput: ReportSearchInput) => ({
      ...oldReportSearchInput,
      OrderDirection: orderDirection,
    }))
  }

  useEffect(() => {
    const buttonProps: IButtonProps[] = [
      {
        variant: ButtonVariant.Primary,
        size: Size.Medium,
        label: 'Fly Forward',
        leftIcon: IconVariant.FlightTakeOff,
        onClick: () => {
          navigate('/flyforward')
        },
      },
    ]

    setTitle('Reports')
    setButtons(buttonProps)

    return () => {
      setTitle('')
      setButtons([])
    }
  }, [navigate, setButtons, setTitle])

  const { data: reports, isLoading: areReportsLoading } = useGetReportsSearchQuery(reportSearchInput)
  const userSearch: IdWithNameDto[] = []
  const { data: users, isSuccess: isUserSuccess } = useGetUsersNamesQuery({ hasReports: true })

  const searchByUsers = () => {
    users?.forEach((user) => {
      userSearch.push(user)
    })
  }

  if (isUserSuccess) {
    searchByUsers()
  }

  const [deleteReport] = useDeleteReportMutation()

  const onDeleteHandler = async (id: string): Promise<void> => {
    await deleteReport({ reportId: id })
  }

  return (
    <PageContainer>
      <ErrorBoundary>
        <ReportsSearchFilter users={userSearch ?? []} filter={reportSearchInput} onFilterUpdate={onFilterUpdateHandler} />
        <ComponentContainer className="flex-grow p-0">
          {(!isNil(reports) && !isArrayEmptyOrNull(reports.items)) || areReportsLoading ? (
            <ReportsGrid
              reports={reports ?? new PaginatedResult<ReportSearchDto>([], 0, 0, 0)}
              currentPage={reportSearchInput.Page}
              pageSize={reportSearchInput.PageSize}
              isLoading={areReportsLoading}
              setCurrentPage={setCurrentPage}
              setPageSize={setPageSize}
              orderDirection={reportSearchInput.OrderDirection}
              setOrderDirection={setOrderDirection}
              onDelete={onDeleteHandler}
            />
          ) : (
            <ReportsEmptyState width={911} height={580} />
          )}
        </ComponentContainer>
      </ErrorBoundary>
    </PageContainer>
  )
}
