/* eslint-disable max-len */
import React from 'react'
import style from './FullPageSpinner.module.css'
import { cn } from '../utils'

export interface ISpinnerProps {
  isShowing: boolean
}

export const FullPageLoadingSpinner: React.FC<ISpinnerProps> = ({ isShowing = false }) => {
  return (
    <div
      className={cn(
        'inset-0 z-[100] flex max-h-screen w-screen flex-col-reverse items-center justify-center bg-black-100-opacity-20 p-4 sm:inset-y-0 sm:left-0 sm:flex-col',
        isShowing ? 'fixed' : 'hidden',
      )}
    >
      <div className="relative flex items-center justify-center">
        <div className={style.spinner}></div>
        <div className="absolute text-center text-xl text-primary">Flyward</div>
      </div>
    </div>
  )
}
