import { Button, ButtonVariant, IconVariant, Size, ZitadelInstance } from '@flyward/platform'
import { type ZitadelAuth } from '@zitadel/react'
import { useCallback, useRef } from 'react'

export const LogIn = () => {
  const zitadel = useRef<ZitadelAuth>(ZitadelInstance.getInstance())
  const getIconSize = useCallback(() => {
    if (window.innerWidth >= 3000) {
      return Size.ExtraLarge
    } else if (window.innerWidth >= 1440) {
      return Size.Large
    } else {
      return Size.Medium
    }
  }, [])

  const login = () => {
    zitadel.current.authorize()
  }

  return (
    <div className="flex">
      {/** TODO: Check user's full name from token and display logout button */}
      <Button
        variant={ButtonVariant.Account}
        size={getIconSize()}
        leftIcon={IconVariant.AccountCircle}
        label={'Log In'}
        className="w-full text-black-100"
        onClick={login}
      />
    </div>
  )
}
