import { ComponentContainer, ErrorBoundary, fromIsoToFormatDate, TextCellWithLabel } from '@flyward/platform'
import { type StandAloneEngineDTO } from '../../../models'

interface IStandAloneEngineDetailsProps {
  standAloneEngine: StandAloneEngineDTO
}

export const StandAloneEngineDetails = ({ standAloneEngine }: IStandAloneEngineDetailsProps): React.ReactElement<IStandAloneEngineDetailsProps> => {
  return (
    <ComponentContainer className="p-6">
      <ErrorBoundary>
        <div className="flex w-full flex-col">
          <p className="mb-4 w-full border-b border-primary-light-2 pb-2 text-base font-semibold text-text-1">General Information</p>
          <div className="flex flex-col gap-x-6 gap-y-2">
            <div className="flex gap-x-6">
              <TextCellWithLabel className="basis-1/6" label="Operator" info={standAloneEngine.lessee} />
              <TextCellWithLabel className="basis-1/6" label="Lessee Habitual Base" info={standAloneEngine.lesseeHabitualBase} />
              <TextCellWithLabel className="basis-1/6" label="Lease Start" info={fromIsoToFormatDate(standAloneEngine.leaseStartDate)} />
              <TextCellWithLabel className="basis-1/6" label="Lease End" info={fromIsoToFormatDate(standAloneEngine.leaseEndDate)} />
              <TextCellWithLabel className="basis-1/6" label="Region" info={standAloneEngine.region} />
              <TextCellWithLabel className="basis-1/6" label="Portfolio" info={standAloneEngine.portfolio} />
            </div>
          </div>
        </div>
      </ErrorBoundary>
    </ComponentContainer>
  )
}
