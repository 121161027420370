import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { PageContainer } from '../../../layout'
import {
  AlertDialogWithTrigger,
  ErrorBoundary,
  IconVariant,
  OutletTabs,
  ToastVariant,
  useHeaderContent,
  useNavigationState,
  useToast,
} from '@flyward/platform'
import { EngineMaintenanceProgramPage, EngineTechSpec } from '@flyward/assets'
import { isEmpty } from 'lodash'
import {
  useGetAllEngineMaintenanceProgramsQuery,
  useGetAllEnginesByAssetIdQuery,
  useGetAllStandaloneEnginesByAssetIdQuery,
  useUpdateEnginesMaintenanceProgramMutation,
} from '@flyward/platform/store'

export const EnginePage = () => {
  const { toast } = useToast()

  const { id } = useParams()
  const { setTitle, setHasBackButton, setCustomElements } = useHeaderContent()
  const { toggleVisibility } = useNavigationState()

  const [hasChanges, setHasChanges] = useState(false)
  const [engineAssignedKBProgramId, setEngineAssignedKBProgramId] = useState('')

  const [updateEngineMaintenanceProgram] = useUpdateEnginesMaintenanceProgramMutation()

  const assetId: string = id ?? ''

  const { data: engines } = useGetAllEnginesByAssetIdQuery({ assetId, includeLLPs: true })
  const { data: standaloneEngines } = useGetAllStandaloneEnginesByAssetIdQuery({ assetId })

  const { data: availableEnginePrograms } = useGetAllEngineMaintenanceProgramsQuery(false)

  useEffect(() => {
    toggleVisibility()
    setTitle('View Engine')
    setHasBackButton(true)

    const handleSave = async () => {
      try {
        if (!isEmpty(engineAssignedKBProgramId)) {
          updateEngineMaintenanceProgram({ assetId, kbProgramId: engineAssignedKBProgramId })
        }
      } catch (error) {
        toast({
          variant: ToastVariant.Destructive,
          description: 'Asset update failed!',
          icon: IconVariant.Success,
        })
      }
      toast({
        variant: ToastVariant.Success,
        description: 'Asset has been updated!',
        icon: IconVariant.Success,
      })
    }

    const handleCancel = () => {
      setEngineAssignedKBProgramId(engines?.[0]?.assignedKBProgramId?.toString() ?? '')
      setHasChanges(false)
    }

    setCustomElements(
      hasChanges
        ? [
            <AlertDialogWithTrigger
              isValid={true}
              isTriggerVisible={hasChanges && !isEmpty(engineAssignedKBProgramId)}
              confirmBtnLabel="Save"
              triggerBtnIcon={IconVariant.Save}
              triggerBtnLabel="Save"
              key={`save-${assetId}`}
              onConfirm={handleSave}
              onCancel={handleCancel}
              dialogContent={<p>Save changes?</p>}
            />,
          ]
        : [],
    )

    return () => {
      toggleVisibility()
    }
  }, [
    assetId,
    engineAssignedKBProgramId,
    engines,
    hasChanges,
    setCustomElements,
    setHasBackButton,
    setTitle,
    toast,
    toggleVisibility,
    updateEngineMaintenanceProgram,
  ])

  useEffect(() => {
    setEngineAssignedKBProgramId(engines?.[0]?.assignedKBProgramId?.toString() ?? '')
  }, [engines])

  const changeEngineProgram = (engineProgramId: string) => {
    setEngineAssignedKBProgramId(engineProgramId)
    setHasChanges(true)
  }

  const tabs = {
    'Tech Spec': {
      content: <EngineTechSpec engine={engines?.[0]} standAloneEngine={standaloneEngines} />,
    },
    'Maintenance Program': {
      content: (
        <EngineMaintenanceProgramPage
          availablePrograms={{
            availableEnginePrograms:
              availableEnginePrograms?.map((program) => {
                return {
                  value: program.masterComponentKBProgramId,
                  label: program.maintenanceProgramName,
                }
              }) ?? [],
          }}
          changePrograms={{
            changeEngineProgram,
          }}
          assignedEngineProgramId={engineAssignedKBProgramId}
        />
      ),
    },
  }

  return (
    <PageContainer>
      <ErrorBoundary>
        <OutletTabs tabs={tabs} />
      </ErrorBoundary>
    </PageContainer>
  )
}
