import { type NavigateFunction, useNavigate } from 'react-router-dom'
import { useReactTable, getCoreRowModel, type ColumnDef, flexRender } from '@tanstack/react-table'
import { type MaintenanceProgram } from '../../models'
import {
  Button,
  ButtonVariant,
  CellWithButton,
  ComponentCategory,
  IconVariant,
  Size,
  Switch,
  TextCellWithLabel,
  cn,
  longDateFormat,
} from '@flyward/platform'
import { format } from 'date-fns'
import { useMemo } from 'react'

type ComponentTypes = ComponentCategory.Airframe | ComponentCategory.EngineUnit | ComponentCategory.LandingGear | ComponentCategory.AuxiliaryPowerUnit

const labels: Record<ComponentTypes, { singular: string; plural: string }> = {
  [ComponentCategory.EngineUnit]: { singular: 'Engine', plural: 'Engines' },
  [ComponentCategory.Airframe]: { singular: 'Airframe', plural: 'Airframes' },
  [ComponentCategory.AuxiliaryPowerUnit]: { singular: 'APU', plural: "APU's" },
  [ComponentCategory.LandingGear]: { singular: 'Landing Gear', plural: 'Landing Gears' },
}

const getFormattedValue = (getValue: () => unknown) => {
  const value = getValue()
  return value as string
}

const getFormattedDate = (getValue: () => unknown) => {
  const value = getFormattedValue(getValue)
  const date = new Date(value)
  return format(date, longDateFormat)
}

const generateColumns = (componentType: ComponentTypes, navigate: NavigateFunction, detailsPath: string): Array<ColumnDef<MaintenanceProgram>> => [
  {
    accessorKey: 'maintenanceProgramName',
    header: () => null,
    cell: ({ getValue, row }) => (
      <CellWithButton
        label={`${labels[componentType].singular} Name`}
        info={getFormattedValue(getValue)}
        onClick={() => {
          navigate(`${detailsPath}/${row.original.masterComponentKBProgramId}`)
        }}
      />
    ),
  },
  {
    accessorKey: 'createdAt',
    header: () => null,
    cell: ({ getValue }) => <TextCellWithLabel label="Date" info={getFormattedDate(getValue)} />,
  },
  {
    accessorKey: 'isActive',
    header: () => null,
    cell: ({ getValue }) => <Switch checked={getValue() as boolean} />,
  },
]

interface IMaintenanceProgramsGridProps {
  componentType: ComponentTypes
  programs: MaintenanceProgram[]
  detailsRoute: string
}

export const MaintenanceProgramsGrid = ({ programs, componentType, detailsRoute }: IMaintenanceProgramsGridProps) => {
  const navigate = useNavigate()

  const columns = useMemo(() => generateColumns(componentType, navigate, detailsRoute), [componentType, navigate, detailsRoute])

  const table = useReactTable<MaintenanceProgram>({
    data: programs,
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection: false,
  })

  return (
    <div className="flex h-full flex-grow flex-col px-4">
      <div className="mb-auto flex flex-grow flex-col">
        <div className="flex justify-between py-4 pr-6">
          <Button
            variant={ButtonVariant.Secondary}
            leftIcon={IconVariant.Add}
            disabled
            label={`Add a new ${labels[componentType].singular}`}
            size={Size.Small}
          />
          <div className="flex gap-x-4">
            <p className="text-base text-text-1">Active All</p>
            <Switch checked={false} disabled />
          </div>
        </div>
        <div className="block w-full">
          <table className="w-full">
            <tbody className="w-full">
              {table.getRowModel().rows.map((row) => {
                return (
                  <tr key={row.id} className={cn('flex flex-grow gap-x-10 border-t px-6 py-2', 'items-center border-primary-light-2')}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td key={cell.id} className="flex flex-grow flex-row first:basis-24 last:justify-end">
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      <p className="ml-2 mt-2">{`Total ${labels[componentType].plural}: ${programs.length}`}</p>
    </div>
  )
}

export default MaintenanceProgramsGrid
