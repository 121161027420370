import { BooleanCellWithLabel, NumberCellWithLabel, NumberDisplayType, TextCellWithLabel } from '@flyward/platform'
import { Fragment } from 'react'
import { type LessorContribution } from '../../../../../models'

interface ILessorContributionRatesProps {
  lessorContributions: LessorContribution
}

export const LessorContributionRates = ({
  lessorContributions,
}: ILessorContributionRatesProps): React.ReactElement<ILessorContributionRatesProps> => {
  return (
    <Fragment key={lessorContributions.componentContractId}>
      <BooleanCellWithLabel className="basis-1/6" label="Lessor Contribution" info={lessorContributions.hasLessorContributions} />
      <TextCellWithLabel
        className="basis-1/6"
        label="Lessor Contribution Type"
        preventInfo={!lessorContributions.hasLessorContributions}
        info={lessorContributions.lessorContributionType}
      />
      <NumberCellWithLabel
        className="basis-1/6"
        label="Lessor Contribution Rate"
        preventInfo={!lessorContributions.hasLessorContributions}
        info={lessorContributions.lessorContributionRate}
        displayType={NumberDisplayType.Currency}
      />
    </Fragment>
  )
}
