import { ComponentContainer, ComponentTabs, ErrorBoundary, TextCellWithLabel } from '@flyward/platform'
import { type AuxiliaryPowerUnit } from '../../../../../models'
import { APUContractualTab } from './APUContractualTab'
import { APUTechnicalTab } from './APUTechnicalTab'
import { useState } from 'react'
interface IAuxiliaryPowerUnitDetailsProps {
  auxiliaryPowerUnit: AuxiliaryPowerUnit
}

export const AuxiliaryPowerUnitDetails = ({
  auxiliaryPowerUnit,
}: IAuxiliaryPowerUnitDetailsProps): React.ReactElement<IAuxiliaryPowerUnitDetailsProps> => {
  const [currentTab, setCurrentTab] = useState<string>('Technical')
  const onTabChanged = (activeTab: string) => {
    setCurrentTab(activeTab)
  }
  const tabs = {
    Technical: {
      content: (
        <ErrorBoundary>
          <APUTechnicalTab auxiliaryPowerUnit={auxiliaryPowerUnit} />
        </ErrorBoundary>
      ),
    },
    Contractual: {
      content: (
        <ErrorBoundary>
          <APUContractualTab auxiliaryPowerUnit={auxiliaryPowerUnit} />{' '}
        </ErrorBoundary>
      ),
    },
  }

  return (
    <ComponentContainer className="p-6">
      <div className="flex w-full flex-col">
        <p className="mb-4 w-full border-b border-primary-light-2 pb-2 text-base font-semibold text-text-1">APU</p>
        <div className="flex flex-col gap-y-2">
          <div className="flex gap-x-6">
            <TextCellWithLabel className="mr-6 basis-2/6" label="Manufacturer" info={auxiliaryPowerUnit.manufacturingDetail.manufacturer} />
            <TextCellWithLabel className="basis-1/6" label="Model" info={auxiliaryPowerUnit.componentModel} />
            <TextCellWithLabel className="basis-1/6" label="P/N" info={auxiliaryPowerUnit.partNumber ?? '-'} />
            <TextCellWithLabel className="basis-1/6" label="S/N" info={auxiliaryPowerUnit.componentSerialNumber} />
            <div className="basis-1/6" />
          </div>
          <ComponentTabs
            tabTriggerHeight="h-9"
            tabTriggerLabelHeight="h-8"
            tabs={tabs}
            controlledActiveTab={currentTab}
            onTabChanged={onTabChanged}
          />
        </div>
      </div>
    </ComponentContainer>
  )
}
