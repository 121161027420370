import { cn, useNavigationState } from '@flyward/platform'
import { useLayoutEffect, useState } from 'react'

interface IPageContainerPros {
  children?: React.ReactNode
  className?: string
}

/**
 * This wraps pages
 */
export const PageContainer = ({ children, className }: IPageContainerPros): React.ReactElement<IPageContainerPros> => {
  const { isCollapsed, isNavigationHidden, isHidden } = useNavigationState()

  const [maxWidth, setMaxWidth] = useState('w-[calc(100vw-.25rem)]')

  useLayoutEffect(() => {
    if (isNavigationHidden || isHidden) {
      setMaxWidth('w-[calc(100vw-.25rem)]')
    } else if (isCollapsed) {
      setMaxWidth('w-[calc(100vw-5.25rem)]')
    } else {
      setMaxWidth('w-[calc(100vw-15.25rem)]')
    }
  }, [isNavigationHidden, isCollapsed, isHidden])

  return (
    <div
      data-testid="page-container"
      className={cn(
        `h-max-[calc(100vh-5.25rem)] flex h-[calc(100vh-5.25rem)]
         flex-col gap-y-6 overflow-auto px-6 pb-6`,
        maxWidth,
        className,
      )}
    >
      {children}
    </div>
  )
}
