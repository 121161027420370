import { type IconVariant } from '@flyward/platform'
import { NavHeader } from './NavHeader'
import { NavMenu } from './NavMenu'

interface IMasterLayoutProps {
  children?: React.ReactNode
  routes: Array<{ path: string; label: string; icon: IconVariant }>
}

/**
 * This wraps the protected routes that have a side-nav and a header
 */
export const MasterLayout = ({ children, routes }: IMasterLayoutProps): React.ReactElement<IMasterLayoutProps> => {
  return (
    <div className="flex h-full w-full flex-col bg-background-light">
      <NavHeader />
      <div className="flex flex-grow">
        <NavMenu routes={routes} />
        <div className="h-full w-full">{children}</div>
      </div>
    </div>
  )
}
