import { APIRoutes } from '@flyward/platform/constants'
import { flywardApi } from '@flyward/platform/store/slices/api/flywardApi/flywardApiSlice'
import {
  type IGetAllLandingGearsByAssetIdQueryResponse,
  type IGetAllLandingGearsByAssetIdQueryRequest,
  type IPutLandingGearsMaintenanceProgramMutationRequest,
  type IGetAllLandingGearChecksWithDetailsByAssetIdQueryResponse,
  type IGetAllLandingGearChecksWithDetailsByAssetIdQueryRequest,
} from './types'
import { ComponentCategory, ComponentTypeURI } from '@flyward/platform'

const landingGearsApi = flywardApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllLandingGearsByAssetId: builder.query<IGetAllLandingGearsByAssetIdQueryResponse, IGetAllLandingGearsByAssetIdQueryRequest>({
      query: ({ assetId }) => ({
        url: APIRoutes.AssetsModule.Components.LandingGearsController.GetAll(assetId),
        method: 'GET',
      }),
      providesTags: (_, __, request) => [{ type: 'landingGears', id: request.assetId }],
    }),
    getAllLandingGearChecksWithDetailsByAssetId: builder.query<
      IGetAllLandingGearChecksWithDetailsByAssetIdQueryResponse,
      IGetAllLandingGearChecksWithDetailsByAssetIdQueryRequest
    >({
      query: ({ assetId }) => ({
        url: APIRoutes.AssetsModule.Components.LandingGearsController.GetAllChecksWithDetails(assetId),
        method: 'GET',
      }),
      providesTags: (_, __, request) => [{ type: 'componentChecks', id: `${request.assetId}-${ComponentTypeURI(ComponentCategory.LandingGear)}` }],
    }),
    updateLandingGearsMaintenanceProgram: builder.mutation<void, IPutLandingGearsMaintenanceProgramMutationRequest>({
      query: ({ assetId, kbProgramId }) => ({
        url: APIRoutes.AssetsModule.Components.LandingGearsController.MaintenanceProgram(assetId),
        method: 'PUT',
        data: kbProgramId,
      }),
      invalidatesTags: (_, __, request) => [{ type: 'landingGears', id: request.assetId }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAllLandingGearsByAssetIdQuery,
  useGetAllLandingGearChecksWithDetailsByAssetIdQuery,
  useUpdateLandingGearsMaintenanceProgramMutation,
  util: landingGearsApiSliceUtil,
} = landingGearsApi

type Endpoints = typeof landingGearsApi.endpoints
type InitiateActions = {
  [K in keyof Endpoints]: ReturnType<ReturnType<Endpoints[K]['initiate']>>
}
export type TLandingGearsApiActions = InitiateActions[keyof InitiateActions]
