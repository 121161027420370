import { NumberDisplay, type TableColumn } from '@flyward/platform'
import { type FlightHoursFlightCyclesRatio } from '../../../models'
import { MaintenanceProgramDetailsTable } from '../MaintenanceProgramDetailsTable'

interface ICyclesHoursRatioTableProps {
  flightHoursFlightCyclesRatios: FlightHoursFlightCyclesRatio[] | undefined
}

const tableColumns: Array<TableColumn<FlightHoursFlightCyclesRatio>> = [
  {
    accessor: 'id',
    isIdField: true,
    title: '',
    isHidden: true,
  },
  {
    accessor: 'flightHoursFlightCyclesRatioIdentifier',
    title: 'Ratio',
  },
  {
    accessor: 'firstRunLimit',
    title: 'First run limit',
    valueGetter: (value) => <NumberDisplay value={value} />,
  },
  {
    accessor: 'matureLimit',
    title: 'Mature limit',
    valueGetter: (value) => <NumberDisplay value={value} />,
  },
]

export const FlightHoursFlightCyclesRatioTable = ({
  flightHoursFlightCyclesRatios,
}: ICyclesHoursRatioTableProps): React.ReactElement<ICyclesHoursRatioTableProps> => {
  return <MaintenanceProgramDetailsTable columns={tableColumns} data={flightHoursFlightCyclesRatios} />
}
