import { Input, InputType } from '@flyward/platform'
import { type BasicAssumption } from '../../models'

interface IBasicAssumptionsDisplayProps {
  basicAssumption: BasicAssumption | undefined
}

export const BasicAssumptionsDisplay = ({ basicAssumption }: IBasicAssumptionsDisplayProps): React.ReactElement<IBasicAssumptionsDisplayProps> => {
  return (
    <div className="flex gap-6">
      <Input
        id="check-downtime-months"
        controlledValue={basicAssumption?.checkDowntimeMonths.toString()}
        label="Check downtime (months)"
        type={InputType.NaturalNumber}
      />
      <Input
        id="delay-in-payment-on-claims-months"
        controlledValue={basicAssumption?.monthsDelayInPaymentOnClaims.toString()}
        label="Delay in Payment on Claims(months)"
        type={InputType.NaturalNumber}
      />
    </div>
  )
}
