import { Table, type TableRowData, type TableColumn } from '@flyward/platform'

interface IMaintenanceProgramDetailsTableProps<T> {
  columns: Array<TableColumn<T>>
  data: TableRowData<T | undefined> | undefined
  canAddNewRow?: boolean
  canUploadFromFile?: boolean
  summaryRow?: React.ReactElement
}

export const MaintenanceProgramDetailsTable = <T extends object>({
  columns,
  data,
  canAddNewRow,
  canUploadFromFile,
  summaryRow,
}: IMaintenanceProgramDetailsTableProps<T>): React.ReactElement<IMaintenanceProgramDetailsTableProps<T>> => (
  <div className="flex flex-grow">
    <Table columns={columns} data={data} canAddNewRow={canAddNewRow} canUploadFromFile={canUploadFromFile} summaryRow={summaryRow} />
  </div>
)
