import { type AirframeCheck } from '../../../models'
import { type ChecksDisplay, ChecksTable } from '../ChecksTable'

interface IAirframeChecksTableProps {
  checks?: AirframeCheck[]
}

export const AirframeChecksTable = ({ checks = [] }: IAirframeChecksTableProps) => {
  const mappedData = checks.map((check: AirframeCheck): ChecksDisplay => {
    return {
      checkId: check.checkId,
      checkType: check.checkType,
      utilizationUnit: check.utilizationUnit,
      utilization: check.airframeCheckLimit.utilization,
      baseCost: check.baseCost,
    }
  })
  return <ChecksTable canAddNewRow checks={mappedData} />
}
