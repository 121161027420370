import React, { useState } from 'react'
import {
  AlertDialog as CnAlertDialog,
  AlertDialogAction as CnAlertDialogAction,
  AlertDialogCancel as CnAlertDialogCancel,
  AlertDialogContent as CnAlertDialogContent,
  AlertDialogFooter as CnAlertDialogFooter,
  cn,
} from '../_shadcn'
import { Button, ButtonVariant } from '../Button'
import { Size } from '../common'
import { type IconVariant } from '../Icon'

export interface IAlertDialogProps {
  triggerBtnLabel?: string
  triggerBtnIcon?: IconVariant
  triggerBtnSize?: Size
  triggerBtnWidth?: string
  triggerBtnVariant?: ButtonVariant
  triggerClassName?: string
  isCollapsible?: boolean
  isValid: boolean
  isTriggerVisible: boolean
  confirmBtnLabel: string
  onConfirm: () => Promise<void>
  onSuccess?: () => void
  onCancel?: () => void
  dialogContent: React.ReactNode
  dialogHeader?: string
}

/**
 * @deprecated The method will be replaced with ActionDialog
 */
const AlertDialogWithTrigger = ({
  triggerBtnLabel,
  triggerBtnIcon,
  triggerBtnSize = Size.Medium,
  triggerBtnVariant = ButtonVariant.Primary,
  triggerClassName,
  triggerBtnWidth = 'w-11',
  isCollapsible = true,
  isValid,
  isTriggerVisible,
  confirmBtnLabel,
  onConfirm,
  onSuccess = () => {},
  onCancel = () => {},
  dialogContent,
  dialogHeader,
}: IAlertDialogProps) => {
  const [isOpen, setIsOpen] = useState<boolean>()
  const alertTriggerClassName = isTriggerVisible ? 'flex' : 'hidden'

  const cancel = () => {
    onCancel()
    setIsOpen(false)
  }

  const confirm = () => {
    onConfirm().then(() => {
      onSuccess()
      setIsOpen(false)
    })
  }

  return (
    <CnAlertDialog open={isOpen}>
      <div data-testid="triggerButtonContainer" className={cn(triggerClassName, alertTriggerClassName)}>
        <Button
          variant={triggerBtnVariant}
          size={triggerBtnSize}
          leftIcon={triggerBtnIcon}
          label={triggerBtnLabel}
          key={'dialog-trigger'}
          className={cn('group items-center justify-center ', triggerBtnWidth + ' ', triggerBtnLabel != null && isCollapsible && 'hover:w-20')}
          labelClassName={cn(isCollapsible && `hidden group-hover:block`)}
          onClick={() => {
            setIsOpen(true)
          }}
        />
      </div>
      <CnAlertDialogContent>
        <h1 className="font-bold">{dialogHeader}</h1>
        {dialogContent}
        <CnAlertDialogFooter className="mt-5 gap-x-4">
          <CnAlertDialogCancel onClick={cancel}>Cancel</CnAlertDialogCancel>
          <CnAlertDialogAction onClick={confirm} disabled={!isValid}>
            {confirmBtnLabel}
          </CnAlertDialogAction>
        </CnAlertDialogFooter>
      </CnAlertDialogContent>
    </CnAlertDialog>
  )
}

export { AlertDialogWithTrigger }
