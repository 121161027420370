import { createContext, useContext, useEffect, useMemo, useState, type ReactNode } from 'react'
import { type UserAuthenticatedContextType } from './UserAuthenticatedContextType'
import { useZitadelAuthenticated } from '../ZitadelAuthenticated/ZitadelAuthenticatedContext'
import { type LoggedInUserDto } from '@flyward/platform/models/DTOs/LoggedInUserDto'
import { useGetMeQuery } from '@flyward/appIdentity/store/api/users/endpoints'
import { isNil } from 'lodash'

const UserAuthenticatedContext = createContext<UserAuthenticatedContextType | undefined>(undefined)

export const useUserAuthenticated = (): UserAuthenticatedContextType => {
  const context = useContext(UserAuthenticatedContext)
  if (context === undefined) {
    throw new Error('useUserAuthenticated must be used within an UserAuthorizationProvider')
  }
  return context
}

interface IUserAuthenticatedProviderProps {
  children: ReactNode
}

export const UserAuthorizationProvider = ({ children }: IUserAuthenticatedProviderProps) => {
  const [loggedUser, setLoggedUser] = useState<LoggedInUserDto | null>(null)
  const { isUserAuthenticated } = useZitadelAuthenticated()
  const { data: loggedUserDto, refetch } = useGetMeQuery(undefined, { skip: isUserAuthenticated !== true })

  useEffect(() => {
    if (isUserAuthenticated ?? false) {
      refetch()
    }
    if (!isNil(loggedUserDto)) {
      setLoggedUser(loggedUserDto)
    }
  }, [isUserAuthenticated, loggedUserDto, refetch])

  const userContextValue = useMemo(() => ({ loggedUser, isUserLogged: isUserAuthenticated }), [isUserAuthenticated, loggedUser])

  return <UserAuthenticatedContext.Provider value={userContextValue}>{children}</UserAuthenticatedContext.Provider>
}
