/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Icon, IconVariant } from '../Icon'
import {
  Button as CnButton,
  ButtonVariant as CnButtonVariant,
  Command as CnCommand,
  CommandEmpty as CnCommandEmpty,
  CommandGroup as CnCommandGroup,
  CommandInput as CnCommandInput,
  CommandItem as CnCommandItem,
  CommandList as CnCommandList,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../_shadcn'
import { Size } from '../common'
import { cn } from './../utils'
import { type ISelectOption } from '@flyward/platform/models/wrappers'

interface IComboboxProps {
  className?: string
  disabled?: boolean
  id?: string
  itemLabel?: string
  items: ISelectOption[]
  itemsIcon?: Array<{ value: string; icon: IconVariant }>
  label?: string
  labelClassName?: string
  selectedValues?: string[]
  setSelectedValues?: (value: string[]) => void
  isMultiselect?: boolean
  hasSearchInput?: boolean
  hasOutline?: boolean
}

const Combobox = ({
  className,
  disabled,
  id = uuidv4(),
  itemLabel = '',
  items,
  itemsIcon,
  label,
  labelClassName,
  selectedValues: initialSelectedValues = [],
  setSelectedValues: setSelectedValuesInParent,
  isMultiselect = false,
  hasSearchInput = true,
  hasOutline = true,
}: Readonly<IComboboxProps>) => {
  const [open, setOpen] = useState(false)
  const [icon, setIcon] = useState<IconVariant | undefined>(undefined)
  const [selectedValues, setSelectedValues] = useState<string[]>([])
  const labelColor = disabled ?? false ? 'text-text-3' : 'text-text-1'

  useEffect(() => {
    if (initialSelectedValues && JSON.stringify(initialSelectedValues) !== JSON.stringify(selectedValues)) {
      setSelectedValues(initialSelectedValues)
    }
  }, [initialSelectedValues, selectedValues])

  const handleSelect = (value: string) => {
    const newSelectedValues = isMultiselect
      ? selectedValues.includes(value)
        ? selectedValues.filter((val) => val !== value)
        : [...selectedValues, value]
      : [value]
    if (itemsIcon && !isMultiselect) {
      setIcon(itemsIcon?.find((el) => el.value === value)?.icon)
    }
    setSelectedValues(newSelectedValues)
    setSelectedValuesInParent?.(newSelectedValues)
    if (!isMultiselect) {
      setOpen(false)
    }
  }

  const handleSelectAll = () => {
    const allValues = items.map((item) => item.value)
    setSelectedValues(allValues)
    setSelectedValuesInParent?.(allValues)
  }

  const handleSelectNone = () => {
    setSelectedValues([])
    setSelectedValuesInParent?.([])
  }

  const getButtonLabel = () => {
    if (selectedValues.length === 0) return `Select ${itemLabel}s`
    if (selectedValues.length === items.length && isMultiselect) return `All ${itemLabel}s`
    if (selectedValues.length > 1) return 'Multiple'
    const selectedItem = items.find((item) => item.value === selectedValues[0])
    return selectedItem ? selectedItem.label : `Select ${itemLabel}...`
  }

  const getButtonLabelWithIcon = () => {
    return (
      <div className="flex justify-start">
        {itemsIcon && icon !== undefined && <Icon variant={icon} className="mr-2 text-text-1" size={Size.Medium} />} {getButtonLabel()}
      </div>
    )
  }

  return (
    <div className={cn('flex w-80 flex-col justify-center gap-y-0.4', className)}>
      {label && (
        <Label htmlFor={id}>
          <p className={cn(`w-full !text-xs font-semibold ${labelColor}`, labelClassName)}>{label}</p>
        </Label>
      )}
      <div id={id} className={cn('h-9 w-80', className)}>
        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <CnButton
              variant={hasOutline ? CnButtonVariant.Outline : CnButtonVariant.Ghost}
              disabled={disabled}
              className={cn(
                '!text-black-100',
                'h-9 w-80 justify-between bg-black-0 px-3 py-2',
                selectedValues.length === 0 && 'text-text-1 text-opacity-40',
                hasOutline && 'rounded border-black-20',
                open && 'border-primary text-primary',
                className,
              )}
            >
              {itemsIcon ? getButtonLabelWithIcon() : getButtonLabel()}
              {<Icon variant={open ? IconVariant.ExpandLess : IconVariant.ExpandMore} size={Size.Medium} />}
            </CnButton>
          </PopoverTrigger>
          <PopoverContent className={cn('w-80 border-none bg-black-0 p-0 shadow-dropdown', className)}>
            <CnCommand>
              {hasSearchInput && (
                <>
                  <CnCommandInput placeholder={`Search ${itemLabel}...`} className="h-9 w-full text-text-2" />
                  <CnCommandEmpty>{`No ${itemLabel} found.`}</CnCommandEmpty>
                </>
              )}
              <CnCommandGroup>
                <CnCommandList>
                  {isMultiselect && (
                    <>
                      <CnCommandItem value="select-all" className="w-full" onSelect={handleSelectAll}>
                        All
                      </CnCommandItem>
                      <CnCommandItem value="select-none" className="w-full" onSelect={handleSelectNone}>
                        None
                      </CnCommandItem>
                    </>
                  )}
                  {items.map((item, index) => (
                    <CnCommandItem
                      value={item.label}
                      key={item.value}
                      className={cn(
                        'flex w-full items-center aria-selected:bg-text-4 aria-selected:text-text-1',
                        selectedValues.includes(item.value) && !isMultiselect,
                      )}
                      onSelect={() => {
                        handleSelect(item.value)
                      }}
                    >
                      {isMultiselect && (
                        <input
                          type="checkbox"
                          checked={selectedValues.includes(item.value)}
                          onChange={() => {
                            handleSelect(item.value)
                          }}
                          className="mr-2 text-primary"
                        />
                      )}
                      {itemsIcon && <Icon variant={itemsIcon[index].icon} className="mr-2 text-text-1" size={Size.Medium} />}
                      {item.label}
                      {!isMultiselect && selectedValues.includes(item.value) && (
                        <Icon variant={IconVariant.Tick} className="ml-auto text-text-1" size={Size.Small} />
                      )}
                    </CnCommandItem>
                  ))}
                </CnCommandList>
              </CnCommandGroup>
            </CnCommand>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  )
}

export { Combobox }
