import { AssetInformation, type AssetSearchDTO } from '@flyward/assets'
import {
  APIRoutes,
  axiosInstance,
  Button,
  ButtonVariant,
  ErrorBoundary,
  FlyToType,
  Icon,
  IconVariant,
  Input,
  MultiSelect,
  type MultiSelectValue,
  Size,
} from '@flyward/platform'
import {
  type IReportAsset,
  removeReportAssetByAssetId,
  setReportAssetsWithInitialParameters,
  setSelectedAssetId,
  useFlyForwardDefaultParametersPrefetch,
  type IFlyForwardDefaultParametersQueryResponse,
  flyForwardDefaultParametersApiSliceUtil,
} from '@flyward/platform/store'
import { useAppDispatch, useAppSelector } from '@flyward/platform/store/configureHooks'
import { selectSortedReportAssets, selectSelectedAssetId } from '@flyward/platform/store/slices/features/flyForward/flyForwardSelectors'
import { isEmpty, isNil } from 'lodash'
import { useEffect, useState } from 'react'
import { AssetSelectionBadge } from './AssetSelectionBadge'
import { ReportItemStatus } from '@flyward/forecasts/models/enums'
import { useSearchParams } from 'react-router-dom'
import { type FlyForwardParametersDTO } from '@flyward/platform/models/DTOs/FlyForwardParametersDTO'

interface IFlyForwardAssetSelectionProps {
  allAssetsData: AssetSearchDTO[]
}

const FlyForwardAssetSelection = ({ allAssetsData }: IFlyForwardAssetSelectionProps) => {
  const [searchParams] = useSearchParams()

  const dispatch = useAppDispatch()
  const reportAssets: IReportAsset[] = useAppSelector(selectSortedReportAssets)

  const prefetchGetAllAssetDefaultFlyForwardParameters = useFlyForwardDefaultParametersPrefetch('getFlyForwardDefaultParameters')

  const globalSelectedAssetId = useAppSelector(selectSelectedAssetId)

  const selectedAsset = allAssetsData.find((asset: AssetSearchDTO) => asset.assetId === globalSelectedAssetId)

  const [isInputOpen, setIsInputOpen] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')

  useEffect(() => {
    if (isEmpty(searchValue)) {
      setIsInputOpen(false)
    }
  }, [searchValue])

  const availableAssets = allAssetsData.map((asset: AssetSearchDTO) => {
    const icon = asset.assetType === 1 ? IconVariant.PlaneZoomOut : IconVariant.Engine

    return {
      value: asset.assetId,
      label: (
        <span className="flex flex-row items-center gap-1">
          <Icon variant={icon} size={Size.Medium} />
          {asset.serialNumber}
        </span>
      ),
    } satisfies MultiSelectValue
  })

  const getInitialFlyForwardParameters = (
    asset: AssetSearchDTO,
    assetFlyForwardDefaultParameters: FlyForwardParametersDTO,
  ): FlyForwardParametersDTO => {
    const averageMonthlyFlightHours = Number(assetFlyForwardDefaultParameters.averageMonthlyFlightHours)
    const averageMonthlyFlightCycles = Number(assetFlyForwardDefaultParameters.averageMonthlyFlightCycles)
    const averageMonthlyAPUHours = Number(assetFlyForwardDefaultParameters.averageMonthlyAPUHours)

    return {
      averageMonthlyFlightHours,
      averageMonthlyFlightCycles,
      averageMonthlyAPUHours,
      assetType: asset.assetType,
      engineLifeLimitedPartsBuildGoal: Number(assetFlyForwardDefaultParameters.engineLifeLimitedPartsBuildGoal),
      endDate: assetFlyForwardDefaultParameters.endDate ?? '',
      flyToType: FlyToType.Redelivery,
      hoursToCyclesRatio: averageMonthlyFlightHours / averageMonthlyFlightCycles,
      masterComponentsRates: assetFlyForwardDefaultParameters.masterComponentsRates,
      assetKbChecks: assetFlyForwardDefaultParameters.assetKbChecks,
      assetId: asset.assetId,
      aircraftDetails: assetFlyForwardDefaultParameters.aircraftDetails,
      standaloneEngineDetails: assetFlyForwardDefaultParameters.standaloneEngineDetails,
    }
  }

  const handleAssetSelectionAndGetData = async (newSelectedAssetId: string) => {
    const reportAssetIds = reportAssets.map((reportAsset) => reportAsset.assetId)

    if (reportAssetIds.some((reportAssetId) => reportAssetId === newSelectedAssetId)) {
      dispatch(removeReportAssetByAssetId({ assetId: newSelectedAssetId }))
      return
    }

    // the reason we cannot use RTK query is because RTK query creates
    // an observable behind and if we use
    // even useLazyQuerySubscription, it will cause rerenders
    const assetFlyForwardDefaultParameters = (
      await axiosInstance.get<IFlyForwardDefaultParametersQueryResponse>(
        APIRoutes.AssetsModule.AssetsController.GetFlyForwardDefaultParameters(newSelectedAssetId),
      )
    ).data

    flyForwardDefaultParametersApiSliceUtil.updateQueryData(
      'getFlyForwardDefaultParameters',
      {
        assetId: newSelectedAssetId,
      },
      (_draft) => (_draft = assetFlyForwardDefaultParameters),
    )

    const selectedAsset = allAssetsData.find((t) => t.assetId === newSelectedAssetId)!

    const reportAssetWithInitialParams: IReportAsset = {
      assetId: selectedAsset.assetId,
      assetSerialNumber: selectedAsset.serialNumber,
      assetComponentsMonthlyStatistics: undefined,
      eventSchedule: [],
      flyForwardParameters: getInitialFlyForwardParameters(selectedAsset, assetFlyForwardDefaultParameters),
      initialFlyForwardParameters: getInitialFlyForwardParameters(selectedAsset, assetFlyForwardDefaultParameters),
      reportItemId: undefined,
      reportItemStatus: ReportItemStatus.Initial,
    }

    dispatch(
      setReportAssetsWithInitialParameters({
        newReportAssets: [reportAssetWithInitialParams],
      }),
    )

    if (isEmpty(globalSelectedAssetId)) {
      dispatch(setSelectedAssetId({ assetId: newSelectedAssetId }))
    }
  }

  const handleAssetRemoval = (assetId: string) => {
    dispatch(
      removeReportAssetByAssetId({
        assetId,
      }),
    )
  }

  useEffect(() => {
    const addInitialSelection = () => {
      searchParams.getAll('assetIds').forEach((preSelectedAssetId: string) => {
        handleAssetSelectionAndGetData(preSelectedAssetId)
      })
    }

    addInitialSelection()
    // intended to have it as [] so that we only run it once
  }, [])

  return (
    <ErrorBoundary>
      <div className="m-0 flex w-full flex-col gap-y-4 p-0">
        <div className="flex w-73 flex-row items-center justify-between">
          <MultiSelect
            label="MSN/ESN"
            placeholder="You can select another asset..."
            onValueSelected={(selectedValue: MultiSelectValue) => {
              prefetchGetAllAssetDefaultFlyForwardParameters({ assetId: selectedValue.value })
              handleAssetSelectionAndGetData(selectedValue.value)
            }}
            selectedValues={reportAssets?.map((reportAsset) => ({
              value: reportAsset.assetId,
            }))}
            values={availableAssets}
          />
        </div>
        <div className="flex flex-col items-start justify-center gap-2">
          <span className="text-xs font-normal text-text-1">
            {reportAssets?.length} {reportAssets?.length === 1 ? 'asset' : 'assets'} selected
          </span>
          {reportAssets.length > 0 && (
            <div className="flex  w-full flex-row items-center rounded-lg bg-background-light">
              <ul className="flex basis-11/12 flex-row flex-wrap items-center overflow-x-auto text-pretty ">
                {allAssetsData
                  .filter(
                    (availableAsset: AssetSearchDTO) =>
                      reportAssets.some((reportAsset) => reportAsset.assetId === availableAsset.assetId) &&
                      availableAsset.serialNumber.includes(searchValue),
                  )
                  .map((availableAsset: AssetSearchDTO) => {
                    const availableReportAssets = reportAssets.find((reportAsset) => reportAsset.assetId === availableAsset.assetId)
                    return (
                      <AssetSelectionBadge
                        key={availableAsset.assetId}
                        assetId={availableAsset.assetId}
                        isSelected={availableAsset.assetId === globalSelectedAssetId}
                        status={availableReportAssets?.reportItemStatus ?? ReportItemStatus.Initial}
                        onItemClicked={() => {
                          dispatch(setSelectedAssetId({ assetId: availableAsset.assetId }))
                        }}
                        onClearIconClicked={() => {
                          handleAssetRemoval(availableAsset.assetId)
                        }}
                        text={availableAsset.serialNumber}
                      />
                    )
                  })}
              </ul>
              <div className="flex basis-1/12 flex-row gap-1 self-start">
                {!isInputOpen ? (
                  <Button
                    className="ml-auto"
                    onClick={() => {
                      setIsInputOpen(true)
                    }}
                    variant={ButtonVariant.Ghost}
                  >
                    <Icon
                      className="ml-auto !h-9 !w-9 cursor-pointer rounded-full bg-primary p-1.5 text-black-0"
                      size={Size.None}
                      variant={IconVariant.Search}
                    />
                  </Button>
                ) : (
                  <div
                    className="ml-auto flex h-9 w-60 flex-row items-center gap-2 rounded-full border
                    border-solid border-primary bg-black-0 p-1.5 px-2.5 pl-2.5 pr-6"
                  >
                    <Button
                      onClick={() => {
                        setIsInputOpen(false)
                      }}
                      variant={ButtonVariant.Ghost}
                    >
                      <Icon className="cursor-pointer text-primary" size={Size.Medium} variant={IconVariant.Search} />
                    </Button>
                    <Input
                      hasXButton
                      inputClassName="border-none w-40 pl-0 h-full"
                      setValueAfterValidation={setSearchValue}
                      controlledValue={searchValue}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {!isNil(selectedAsset) && <AssetInformation asset={selectedAsset} showSerialNumber />}
      </div>
    </ErrorBoundary>
  )
}

export { FlyForwardAssetSelection }
