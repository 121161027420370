import type { StandaloneEngineMaintenancePrograms } from '@flyward/assets/models'
import { ComponentContainer, Role } from '@flyward/platform'
import { EditEngineMaintenanceProgram } from './EditEngineMaintenanceProgram'
import { useUserAuthenticated } from '@flyward/appIdentity'
import { isNil } from 'lodash'
import { ReadOnlyEngineMaintenanceProgram } from './ReadOnlyEngineMaintenanceProgram'

export const EngineMaintenanceProgramPage = ({ assignedEngineProgramId, availablePrograms, changePrograms }: StandaloneEngineMaintenancePrograms) => {
  const { loggedUser } = useUserAuthenticated()
  return (
    <ComponentContainer className="p-6">
      {!isNil(loggedUser) && (
        <div className="flex w-full gap-x-6">
          {loggedUser.role === Role.NormalUser ? (
            <ReadOnlyEngineMaintenanceProgram
              assignedEngineProgramId={assignedEngineProgramId}
              availablePrograms={availablePrograms}
              changePrograms={changePrograms}
            />
          ) : (
            <EditEngineMaintenanceProgram
              assignedEngineProgramId={assignedEngineProgramId}
              availablePrograms={availablePrograms}
              changePrograms={changePrograms}
            />
          )}
          <div className="mr-12 basis-2/6"></div>
        </div>
      )}
    </ComponentContainer>
  )
}
