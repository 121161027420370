import { Input, InputType } from '@flyward/platform'
import { isNil } from 'lodash'
import { type Inflation } from '../../models'

interface IInflationDisplayProps {
  inflation?: Inflation
}

export const InflationDisplay = ({ inflation = undefined }: IInflationDisplayProps) => {
  return (
    <>
      {!isNil(inflation) && (
        <div className="flex gap-6">
          <Input
            key={inflation.id}
            id={inflation.id}
            controlledValue={inflation.percentage.toString()}
            label={`Percentage`}
            type={InputType.Percentage}
          />
        </div>
      )}
    </>
  )
}
