import { type AuxiliaryPowerUnitCheck } from '../../../models'
import { ChecksTable } from '../ChecksTable'

interface ICyclesHoursRatioTableProps {
  checks: AuxiliaryPowerUnitCheck[] | undefined
  canAddNewRow: boolean
}

export const AuxiliaryPowerUnitChecksTable = ({
  checks,
  canAddNewRow,
}: ICyclesHoursRatioTableProps): React.ReactElement<ICyclesHoursRatioTableProps> => {
  const mappedData = checks?.map((t) => {
    return {
      checkId: t.checkId,
      checkType: t.checkType,
      utilizationUnit: t.utilizationUnit,
      utilization: t.auxiliaryPowerUnitCheckLimit.utilization,
      baseCost: t.baseCost,
    }
  })
  return <ChecksTable canAddNewRow={canAddNewRow} checks={mappedData} />
}
