export enum UtilizationUnits {
  Months = 1,
  FlightHours = 2,
  FlightCycles = 3,
  AuxiliaryPowerUnitHours = 4,
}

export const UtilizationUnitsDisplay = (value: UtilizationUnits | undefined | null) => {
  if (value === null || value === undefined) {
    return '-'
  }
  switch (value) {
    case UtilizationUnits.AuxiliaryPowerUnitHours:
      return 'APU Hours'
    case UtilizationUnits.FlightCycles:
      return 'Flight Cycles'
    case UtilizationUnits.FlightHours:
      return 'Flight Hours'
    case UtilizationUnits.Months:
      return 'Months'
  }
}
