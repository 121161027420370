import { ComponentContainer, ComponentTabs, ErrorBoundary, TextCellWithLabel } from '@flyward/platform'
import { type Aircraft, type Airframe } from '@flyward/assets/models'
import { AirframeContractualTab } from './AirframeContractualTab'
import { AirframeTechnicalTab } from './AirframeTechnicalTab'
import { useState } from 'react'

interface IAirframeDetailsProps {
  aircraft: Aircraft
  airframe: Airframe
}

export const AirframeDetails = ({ aircraft, airframe }: IAirframeDetailsProps): React.ReactElement<IAirframeDetailsProps> => {
  const [currentTab, setCurrentTab] = useState<string>('Technical')
  const onTabChanged = (activeTab: string) => {
    setCurrentTab(activeTab)
  }

  const tabs = {
    Technical: {
      content: (
        <ErrorBoundary>
          <AirframeTechnicalTab airframe={airframe} />
        </ErrorBoundary>
      ),
    },
    Contractual: {
      content: (
        <ErrorBoundary>
          <AirframeContractualTab airframe={airframe} />
        </ErrorBoundary>
      ),
    },
  }

  return (
    <ComponentContainer className="p-6">
      <div className="flex w-full flex-col">
        <p className="mb-4 w-full border-b border-primary-light-2 pb-2 text-base font-semibold text-text-1">Airframe</p>
        <div className="flex flex-col gap-y-2">
          <div className="flex gap-x-6">
            <TextCellWithLabel className="basis-1/6" label="Aircraft Type" info={aircraft.aircraftType} />
            <TextCellWithLabel className="basis-1/6" label="MSN" info={aircraft.serialNumber} />
            <div className="mr-18 basis-4/6" />
          </div>
          <ErrorBoundary>
            <ComponentTabs
              tabTriggerHeight="h-9"
              tabTriggerLabelHeight="h-8"
              tabs={tabs}
              controlledActiveTab={currentTab}
              onTabChanged={onTabChanged}
            />
          </ErrorBoundary>
        </div>
      </div>
    </ComponentContainer>
  )
}
