import { type CheckTyeRateDTO } from '@flyward/platform/models/DTOs/CheckTyeRateDTO'
import {
  AssetType,
  Button,
  ButtonVariant,
  type CheckTypes,
  Combobox,
  DatePicker,
  FlyToType,
  IconVariant,
  Input,
  InputType,
  Size,
  cn,
  getFlyToTypeDisplayName,
} from '@flyward/platform'
import { resetFlyForwardParameters, setFlyForwardParameters, setSuccessfulReportId } from '@flyward/platform/store'
import { useAppDispatch, useAppSelector } from '@flyward/platform/store/configureHooks'
import { selectReportAssetFlyForwardParametersByAssetId } from '@flyward/platform/store/slices/features/flyForward/flyForwardSelectors'
import { formatDate } from 'date-fns'
import { isNil, isEmpty } from 'lodash'
import { useCallback, useState } from 'react'
import { EditableRatesParameters } from './Rates/EditableRatesParameters'
import { type FlyForwardParametersDTO } from '@flyward/platform/models/DTOs/FlyForwardParametersDTO'

export interface IFlyForwardParametersProps {
  assetId: string
  assetType: AssetType
}

export const FlyForwardParameters = ({ assetId, assetType }: IFlyForwardParametersProps) => {
  const [showAdditionalParams, setShowAdditionalParams] = useState<boolean>(false)

  const dispatch = useAppDispatch()
  const flyForwardParameters = useAppSelector((state) => selectReportAssetFlyForwardParametersByAssetId(state, assetId))

  const handleInputChange = useCallback(
    (flyForwardParameters: FlyForwardParametersDTO, field: keyof FlyForwardParametersDTO, value: string | number): void => {
      let newValue = value
      if (field === 'hoursToCyclesRatio') {
        const ratio = parseFloat(value.toString())
        newValue = ratio % 1 === 0 ? ratio.toString() : ratio.toFixed(2)
      }

      const oldValue = flyForwardParameters[field]

      if (String(oldValue) !== String(newValue)) {
        dispatch(setSuccessfulReportId({ reportId: '' }))
      }

      let newFlyForwardParameters: FlyForwardParametersDTO = {
        ...flyForwardParameters,
        [field]: newValue,
      }

      if (field === 'averageMonthlyFlightHours' || field === 'averageMonthlyFlightCycles') {
        const hours = newFlyForwardParameters.averageMonthlyFlightHours
        const cycles = newFlyForwardParameters.averageMonthlyFlightCycles
        newFlyForwardParameters = {
          ...newFlyForwardParameters,
          hoursToCyclesRatio: hours / cycles,
        }
      }

      const resetDate = field === 'flyToType' && value === FlyToType.Redelivery

      if (field === 'flyToType' && value === FlyToType.Redelivery) {
        newFlyForwardParameters = { ...newFlyForwardParameters, endDate: new Date().toString(), [field as keyof FlyForwardParametersDTO]: newValue }
      } else if (field === 'flyToType' && value === FlyToType.Custom) {
        newFlyForwardParameters = { ...newFlyForwardParameters, endDate: '', [field as keyof FlyForwardParametersDTO]: newValue }
      }

      dispatch(
        setFlyForwardParameters({
          assetId,
          flyForwardParameters: newFlyForwardParameters,
          resetDate,
        }),
      )
    },
    [assetId, dispatch],
  )

  const handleRateChange = useCallback(
    (
      originalFlyForwardParameters: FlyForwardParametersDTO,
      masterComponentSerialNumber: string,
      rateType: 'mrRates' | 'eolRates',
      checkType: CheckTypes,
      field: keyof CheckTyeRateDTO,
      value: number,
    ): void => {
      const newValue = value

      const componentRateToChange = originalFlyForwardParameters.masterComponentsRates.find(
        (rate) => rate.masterComponentSerialNumber === masterComponentSerialNumber,
      )

      if (isNil(componentRateToChange)) {
        return
      }

      const unchangedComponentRates = originalFlyForwardParameters.masterComponentsRates.filter(
        (rate) => rate.masterComponentSerialNumber !== masterComponentSerialNumber,
      )

      const checkTypeRateToSave = componentRateToChange[rateType].find((rate) => rate.checkType === checkType)

      if (isNil(checkTypeRateToSave)) {
        return
      }

      const oldValue = checkTypeRateToSave[field]

      if (oldValue !== newValue) {
        dispatch(setSuccessfulReportId({ reportId: '' }))
      }

      const checkTypeRateChanged = { ...checkTypeRateToSave, [field]: newValue }

      const unchangedCheckTypeRates = componentRateToChange[rateType].filter((rate) => rate.checkType !== checkType)

      const changedCheckTypeRates = [checkTypeRateChanged, ...unchangedCheckTypeRates].sort((a, b) => {
        if (a.checkType < b.checkType) return -1
        if (a.checkType > b.checkType) return 1
        return 0
      })

      const componentRateChanged = {
        ...componentRateToChange,
        [rateType]: changedCheckTypeRates,
      }

      const sortedRates = [componentRateChanged, ...unchangedComponentRates].sort((a, b) => {
        if (a.componentCategory < b.componentCategory) return -1
        if (a.componentCategory > b.componentCategory) return 1

        if (a.masterComponentSerialNumber > b.masterComponentSerialNumber) return -1
        if (a.masterComponentSerialNumber < b.masterComponentSerialNumber) return 1

        return 0
      })

      const newFlyForwardParameters: FlyForwardParametersDTO = {
        ...originalFlyForwardParameters,
        masterComponentsRates: sortedRates,
      }

      dispatch(
        setFlyForwardParameters({
          assetId,
          flyForwardParameters: newFlyForwardParameters,
        }),
      )
    },
    [assetId, dispatch],
  )

  const comboBoxItems = [
    { value: FlyToType.Redelivery.toString(), label: getFlyToTypeDisplayName(FlyToType.Redelivery) },
    { value: FlyToType.Custom.toString(), label: getFlyToTypeDisplayName(FlyToType.Custom) },
  ]

  if (isNil(flyForwardParameters)) {
    return <></>
  }

  return (
    <div className="w-full">
      <div className="flex items-center gap-x-6">
        <div className="flex gap-x-2">
          <Combobox
            items={comboBoxItems}
            itemLabel="label"
            label="Fly to"
            selectedValues={isNil(flyForwardParameters.flyToType) ? [] : [flyForwardParameters.flyToType.toString()]}
            setSelectedValues={(value: string[]) => {
              if (value.length > 0) {
                const firstValue = parseInt(value[0]) as FlyToType
                handleInputChange(flyForwardParameters, 'flyToType', firstValue)
              }
            }}
            className="!w-65"
            hasSearchInput={false}
          />
          <div className="mb-4 mt-auto h-1 w-2 bg-primary" />
          <DatePicker
            key={new Date(flyForwardParameters.endDate).toString()}
            disabled={flyForwardParameters.flyToType === FlyToType.Redelivery}
            label="End"
            setValue={(value?: Date) => {
              if (isNil(value)) {
                handleInputChange(flyForwardParameters, 'endDate', flyForwardParameters.endDate)
                return
              }

              const formattedDate = formatDate(value, 'yyyy-MM-dd')
              handleInputChange(flyForwardParameters, 'endDate', formattedDate)
            }}
            value={isEmpty(flyForwardParameters.endDate) ? undefined : new Date(flyForwardParameters.endDate)}
          />
        </div>
        <Input
          label={'Build Goal'}
          controlledValue={String(flyForwardParameters.engineLifeLimitedPartsBuildGoal)}
          setValueAfterValidation={(value) => {
            handleInputChange(flyForwardParameters, 'engineLifeLimitedPartsBuildGoal', Number(value))
          }}
          type={InputType.NaturalNumber}
          inputClassName="w-65"
        />
        <Button
          variant={ButtonVariant.Square}
          size={Size.Small}
          onClick={() =>
            dispatch(
              resetFlyForwardParameters({
                assetId,
              }),
            )
          }
          leftIcon={IconVariant.Refresh}
          className="mt-auto w-9 px-[0.5625rem]"
        />
      </div>
      <p className="mt-4 !text-sm font-semibold">Average monthly utilisation</p>
      <div className="mt-2 flex items-center gap-x-6">
        <Input
          label={'Flight Hours'}
          controlledValue={String(flyForwardParameters.averageMonthlyFlightHours)}
          setValueAfterValidation={(value) => {
            handleInputChange(flyForwardParameters, 'averageMonthlyFlightHours', parseInt(value))
          }}
          type={InputType.NaturalNumber}
          inputClassName="w-65"
        />
        <Input
          label={'Flight Cycles'}
          controlledValue={String(flyForwardParameters.averageMonthlyFlightCycles)}
          setValueAfterValidation={(value) => {
            handleInputChange(flyForwardParameters, 'averageMonthlyFlightCycles', parseInt(value))
          }}
          type={InputType.NaturalNumber}
          inputClassName="w-65"
        />
        <Input
          disabled
          label={'FH/FC Ratio'}
          controlledValue={String(flyForwardParameters.hoursToCyclesRatio)}
          setValueAfterValidation={(value) => {
            handleInputChange(flyForwardParameters, 'hoursToCyclesRatio', Number(value).toFixed(2))
          }}
          type={InputType.Decimal}
          inputClassName="w-65"
        />
      </div>

      <Button
        variant={ButtonVariant.Ghost}
        size={Size.Medium}
        label={showAdditionalParams ? 'Close Parameters' : 'Additional Parameters'}
        className="ml-[56.625rem] mt-2"
        onClick={() => {
          setShowAdditionalParams((prevValue) => !prevValue)
        }}
      />
      <div className={cn(showAdditionalParams ? 'block' : 'hidden')}>
        {assetType === AssetType.Aircraft && (
          <div className="mt-2 flex h-[3.375rem] items-center gap-x-6">
            <Input
              label="Average APU Hours"
              controlledValue={String(flyForwardParameters.averageMonthlyAPUHours)}
              setValueAfterValidation={(value) => {
                handleInputChange(flyForwardParameters, 'averageMonthlyAPUHours', parseInt(value))
              }}
              type={InputType.NaturalNumber}
              inputClassName="w-65"
            />
          </div>
        )}
        <EditableRatesParameters flyForwardParameters={flyForwardParameters} handleRateChange={handleRateChange} />
      </div>
    </div>
  )
}

export default FlyForwardParameters
