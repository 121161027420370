import * as React from 'react'
import { type IIconProps } from '../IIconProps'
import { IconVariant } from '../IconVariant'

export const ExpandLess = ({ className, variant }: IIconProps): React.ReactElement<IIconProps> => {
  return (
    <svg
      className={className}
      data-variant={IconVariant[variant]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 14L12 9L17 14H7Z" />
    </svg>
  )
}
