import { useReactTable, getCoreRowModel, type ColumnDef, type Row, flexRender } from '@tanstack/react-table'
import {
  Button,
  ButtonVariant,
  IconVariant,
  type PaginatedResult,
  SimpleCell,
  Size,
  cn,
  OrderDirection,
  PageSizes,
  AlertDialogWithTrigger,
  Pagination,
  longDateFormat,
  type LoggedInUserDto,
} from '@flyward/platform'
import { type ReportSearchDto } from '../../../models/DTOs/Reports'
import { format } from 'date-fns'
import { isNil } from 'lodash'
import { useMemo } from 'react'
import { type NavigateFunction, useNavigate } from 'react-router-dom'
import { useUserAuthenticated } from '@flyward/appIdentity/context/UserAuthorization/UserAuthorizationContext'
import { AuthorizedElement } from '@flyward/appIdentity'

const getFormattedValue = (getValue: () => unknown) => {
  const value = getValue()
  return value as string
}

const getFormattedDate = (getValue: () => unknown) => {
  const value = getFormattedValue(getValue)
  if (value === undefined) {
    return ''
  }
  const date = new Date(value)
  return format(date, longDateFormat)
}
interface IReportsGridProps {
  reports: PaginatedResult<ReportSearchDto>
  currentPage: number
  pageSize: PageSizes
  isLoading: boolean
  orderDirection: OrderDirection
  setCurrentPage: (page: number) => void
  setPageSize: (size: PageSizes) => void
  setOrderDirection: (orderDirection: OrderDirection) => void
  onDelete: (reportId: string) => Promise<void>
}

const actionCell = (row: Row<ReportSearchDto>, onDelete: (reportId: string) => Promise<void>, loggedUser: LoggedInUserDto | null) => {
  return (
    <>
      <AuthorizedElement>
        {loggedUser?.id === row.original.createdByUser.id && (
          <AlertDialogWithTrigger
            data-permission-element-id="delete-own-report"
            isValid={true}
            isTriggerVisible={!isNil(row.original.id)}
            confirmBtnLabel="Delete"
            triggerBtnSize={Size.Small}
            triggerBtnIcon={IconVariant.Delete}
            triggerBtnVariant={ButtonVariant.Ghost}
            triggerClassName="justify-end"
            key={`delete-${row.original.id}`}
            onConfirm={async () => {
              await onDelete(row.original.id)
            }}
            dialogContent={
              <p>
                Are you sure you want to delete the report <strong>{row.original.name}</strong> ?
              </p>
            }
          />
        )}
      </AuthorizedElement>
      <AuthorizedElement>
        <AlertDialogWithTrigger
          data-permission-element-id="delete-any-report"
          isValid={true}
          isTriggerVisible={!isNil(row.original.id)}
          confirmBtnLabel="Delete"
          triggerBtnSize={Size.Small}
          triggerBtnIcon={IconVariant.Delete}
          triggerBtnVariant={ButtonVariant.Ghost}
          triggerClassName="justify-end"
          key={`delete-${row.original.id}`}
          onConfirm={async () => {
            await onDelete(row.original.id)
          }}
          dialogContent={
            <p>
              Are you sure you want to delete the report <strong>{row.original.name}</strong> ?
            </p>
          }
        />
      </AuthorizedElement>
    </>
  )
}

const getStringValue = (getValue: () => unknown) => {
  const value = getValue()
  return value as string
}

const generateColumns = (
  orderDirection: OrderDirection,
  setOrderDirection: (orderDirection: OrderDirection) => void,
  onDelete: (reportId: string) => Promise<void>,
  navigate: NavigateFunction,
  loggedUser: LoggedInUserDto | null,
): Array<ColumnDef<ReportSearchDto>> => [
  {
    accessorKey: 'name',
    header: () => 'Report name',
    cell: ({ getValue, row }) => (
      <Button
        className="w-full p-2 text-sm text-text-2"
        variant={ButtonVariant.Ghost}
        label={getStringValue(getValue)}
        onClick={() => {
          navigate(`/reports/${row.original.id}`)
        }}
      />
    ),
  },
  {
    accessorKey: 'createdAt',
    header: () => (
      <Button
        variant={ButtonVariant.Ghost}
        size={Size.Small}
        label="Date"
        className="pl-0 !text-sm font-normal !text-text-2"
        rightIconClassName="text-text-1"
        rightIcon={orderDirection === OrderDirection.Asc ? IconVariant.ArrowUp : IconVariant.ArrowDown}
        onClick={() => {
          setOrderDirection(orderDirection === OrderDirection.Asc ? OrderDirection.Desc : OrderDirection.Asc)
        }}
      />
    ),
    cell: ({ getValue }) => <SimpleCell className="text-sm">{getFormattedDate(getValue)}</SimpleCell>,
  },
  {
    accessorKey: 'createdByUser.name',
    header: () => 'User',
    cell: ({ getValue }) => <SimpleCell className="text-sm">{getStringValue(getValue)}</SimpleCell>,
  },
  {
    accessorKey: 'id',
    header: () => '',
    cell: ({ row }) => actionCell(row, onDelete, loggedUser),
  },
]

export const ReportsGrid = ({
  reports,
  currentPage,
  pageSize,
  isLoading,
  setCurrentPage,
  orderDirection,
  setOrderDirection,
  setPageSize,
  onDelete,
}: IReportsGridProps) => {
  const navigate = useNavigate()
  const { loggedUser } = useUserAuthenticated()

  const columns = useMemo(
    () => generateColumns(orderDirection, setOrderDirection, onDelete, navigate, loggedUser),
    [loggedUser, navigate, onDelete, orderDirection, setOrderDirection],
  )

  const table = useReactTable<ReportSearchDto>({
    data: reports.items,
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection: false,
  })

  const totalPages = pageSize === PageSizes.All ? 1 : Math.ceil(reports.totalCount / pageSize)
  const isLastPage = currentPage + 1 === totalPages
  const tableItems = isLastPage ? reports.items.length : Math.max(pageSize, PageSizes.Ten)
  const tableRowHeight = 44

  return (
    <div className="flex h-full w-full flex-col justify-between bg-header-table font-normal">
      <div className="block h-[calc(100vh-16rem)] w-full overflow-x-auto">
        <table
          className="m-0 w-full p-0"
          style={{
            minHeight: `${tableItems * tableRowHeight}px`,
            maxHeight: `${tableItems * tableRowHeight}px`,
          }}
        >
          <thead className="sticky top-0 z-10 bg-header-table">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="px-4 py-2">
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan} className="pb-1 pt-1 text-left text-sm font-normal text-text-2">
                    {header.isPlaceholder ? null : <div className="pl-2">{flexRender(header.column.columnDef.header, header.getContext())}</div>}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, index) => (
              <tr key={row.id} className={cn(`gap-x-10 px-4 py-2`, index % 2 === 0 ? 'bg-row-even' : 'bg-row-odd')}>
                {row.getVisibleCells().map((cell) => {
                  return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        entityLabel="Reports"
        currentPage={currentPage}
        totalPages={totalPages}
        pageSize={pageSize}
        isLoading={isLoading}
        totalCount={reports.totalCount}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
      />
    </div>
  )
}
