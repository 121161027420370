import { APIRoutes } from '@flyward/platform/constants'
import { flywardApi } from '@flyward/platform/store/slices/api/flywardApi/flywardApiSlice'
import { type IGetAllAssetsBySearchQueryRequest, type IGetAllAssetsBySearchQueryResponse, type IGetAllFiltersResponse } from './types'
import { isNil } from 'lodash'

const assetsApi = flywardApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllAssetsBySearch: builder.query<IGetAllAssetsBySearchQueryResponse, IGetAllAssetsBySearchQueryRequest>({
      query: ({ searchTerm, expand, assetType, filterData, Page, PageSize }) => ({
        url: APIRoutes.AssetsModule.AssetsController.GetAllAssets(),
        data: {
          expand,
          SerialNumber: searchTerm,
          AssetType: !isNil(assetType) ? Number(assetType) : null,
          FilterData: filterData,
          Page,
          PageSize,
        },
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      providesTags: (_, __, request) => [{ type: 'assets', id: `${request.assetType}-${request.searchTerm}` }],
    }),
    getFilters: builder.query<IGetAllFiltersResponse, void>({
      query: () => ({
        url: APIRoutes.AssetsModule.AssetsController.GetAllFilters(),
        method: 'GET',
      }),
      providesTags: (_, __, _request) => [{ type: 'assetsFilters' }],
    }),
  }),
  overrideExisting: false,
})

export const { useGetAllAssetsBySearchQuery, useLazyGetFiltersQuery, util: assetsApiSliceUtil } = assetsApi

type Endpoints = typeof assetsApi.endpoints
type InitiateActions = {
  [K in keyof Endpoints]: ReturnType<ReturnType<Endpoints[K]['initiate']>>
}
export type TAssetsApiActions = InitiateActions[keyof InitiateActions]
