import {
  type EnvironmentalImpactType,
  EnvironmentalImpactTypeDisplay,
  NumberDisplay,
  NumberDisplayType,
  type TableColumn,
} from '@flyward/platform'
import { type EnvironmentalImpact } from '../../../models'
import { MaintenanceProgramDetailsTable } from '../MaintenanceProgramDetailsTable'

interface IEnvironmentalImpactsTableProps {
  environmentalImpacts: EnvironmentalImpact[] | undefined
}

const tableColumns: Array<TableColumn<EnvironmentalImpact>> = [
  {
    accessor: 'id',
    isIdField: true,
    title: '',
    isHidden: true,
  },
  {
    accessor: 'environmentalImpactType',
    title: 'Environment',
    valueGetter: (value) => {
      return EnvironmentalImpactTypeDisplay(value as EnvironmentalImpactType)
    },
  },
  {
    accessor: 'percentage',
    title: 'Percentage',
    valueGetter: (value) => <NumberDisplay value={value} displayType={NumberDisplayType.Percentage} />,
  },
]

export const EnvironmentalImpactsTable = ({
  environmentalImpacts,
}: IEnvironmentalImpactsTableProps): React.ReactElement<IEnvironmentalImpactsTableProps> => {
  return <MaintenanceProgramDetailsTable columns={tableColumns} data={environmentalImpacts} />
}
