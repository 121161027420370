import { NumberCellWithLabel, NumberDisplayType, TextCellWithLabel, formatDate } from '@flyward/platform'

interface IPRTechnicalTabProps {
  csn: number
  tsn: number
  cslpr?: number
  csnAtLastPR?: number
  dateAtLastPR?: string
  lastPrDateAtContractDelivery?: string
  tslpr?: number
  tsnAtLastPR?: number
}

const PRTechnicalTab = ({
  csn,
  tsn,
  cslpr = undefined,
  csnAtLastPR = undefined,
  dateAtLastPR = undefined,
  lastPrDateAtContractDelivery = undefined,
  tslpr = undefined,
  tsnAtLastPR = undefined,
}: IPRTechnicalTabProps) => {
  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex gap-x-6">
        <NumberCellWithLabel className="basis-1/6" label="TSN" info={tsn} displayType={NumberDisplayType.Integer} />
        <NumberCellWithLabel className="basis-1/6" label="CSN" info={csn} displayType={NumberDisplayType.Integer} />
        <NumberCellWithLabel className="basis-1/6" label="TSN @ last PR" info={tsnAtLastPR} displayType={NumberDisplayType.Integer} />
        <NumberCellWithLabel className="basis-1/6" label="CSN @ last PR" info={csnAtLastPR} displayType={NumberDisplayType.Integer} />
        <TextCellWithLabel className="basis-1/6" label="Date - last PR" info={formatDate(dateAtLastPR)} />
        <div className="basis-1/6"></div>
      </div>
      <div className="flex gap-x-6">
        <NumberCellWithLabel className="basis-1/6" label="TSLPR" info={tslpr} displayType={NumberDisplayType.Integer} />
        <NumberCellWithLabel className="basis-1/6" label="CSLPR" info={cslpr} displayType={NumberDisplayType.Integer} />
        <TextCellWithLabel className="basis-1/6" label="Last PR date @ contract delivery" info={formatDate(lastPrDateAtContractDelivery)} />
        <div className="mr-12 basis-3/6"></div>
      </div>
    </div>
  )
}

export { PRTechnicalTab }
export type { IPRTechnicalTabProps }
