import {
  AlertDialog as CnAlertDialog,
  AlertDialogAction as CnAlertDialogAction,
  AlertDialogCancel as CnAlertDialogCancel,
  AlertDialogContent as CnAlertDialogContent,
  AlertDialogFooter as CnAlertDialogFooter,
} from '../../_shadcn'

export interface IActionDialogProps {
  isOpen: boolean
  dialogContent: React.ReactNode
  dialogHeader?: string
  onConfirm: () => Promise<void>
  onSuccess?: () => void
  onCancel?: () => void
  setIsOpen: (isOpen: boolean) => void
  isValid: boolean
  confirmBtnLabel: string
  cancelBtnLabel?: string
}

const ActionDialog = ({
  isOpen,
  dialogContent,
  dialogHeader,
  onSuccess = () => {},
  onCancel = () => {},
  setIsOpen,
  onConfirm,
  isValid,
  confirmBtnLabel,
  cancelBtnLabel = undefined,
}: IActionDialogProps) => {
  const cancel = () => {
    onCancel()
    setIsOpen(false)
  }

  const confirm = () => {
    onConfirm().then(() => {
      onSuccess()
      setIsOpen(false)
    })
  }
  return (
    <CnAlertDialog open={isOpen}>
      <CnAlertDialogContent>
        <h1 className="font-bold">{dialogHeader}</h1>
        {dialogContent}
        <CnAlertDialogFooter className="mt-5 gap-x-4">
          <CnAlertDialogCancel onClick={cancel}>{cancelBtnLabel ?? 'Cancel'}</CnAlertDialogCancel>
          <CnAlertDialogAction onClick={confirm} disabled={!isValid}>
            {confirmBtnLabel}
          </CnAlertDialogAction>
        </CnAlertDialogFooter>
      </CnAlertDialogContent>
    </CnAlertDialog>
  )
}

export { ActionDialog }
