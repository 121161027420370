/* eslint-disable max-len */
import { APIRoutes } from '@flyward/platform/constants'
import { flywardApi } from '@flyward/platform/store/slices/api/flywardApi/flywardApiSlice'
import { type IGetReportsSearchQueryRequest, type IGetReportsSearchQueryResponse } from './types'
import { isNil } from 'lodash'

const reportsSearchApi = flywardApi.injectEndpoints({
  endpoints: (builder) => ({
    getReportsSearch: builder.query<IGetReportsSearchQueryResponse, IGetReportsSearchQueryRequest>({
      query: ({ Name, StartDate, EndDate, UserIds, OrderDirection, Page, PageSize }) => {
        const urlSearchParams = new URLSearchParams()

        !isNil(Name) && urlSearchParams.append('Name', Name)
        !isNil(StartDate) && urlSearchParams.append('StartDate', StartDate)
        !isNil(EndDate) && urlSearchParams.append('EndDate', EndDate)
        UserIds.forEach((userId: string) => {
          urlSearchParams.append('UserIds', userId)
        })
        !isNil(OrderDirection) && urlSearchParams.append('OrderDirection', OrderDirection.toString())
        !isNil(Page) && urlSearchParams.append('Page', Page.toString())
        !isNil(PageSize) && urlSearchParams.append('PageSize', PageSize.toString())

        return {
          url: APIRoutes.ForecastModule.ReportsController.SearchReports(),
          params: urlSearchParams,
          method: 'GET',
        }
      },
      providesTags: (_, __, request) => [
        {
          type: 'reportsSearch',
          id: `${request.Name}-${request.StartDate}-${request.EndDate}-${request.UserIds.join('-')}-${request.OrderDirection.toString()}-${request.Page}-${request.PageSize}`,
        },
      ],
    }),
  }),
  overrideExisting: false,
})

export const { useGetReportsSearchQuery, util: reportsSearchApiSliceUtil } = reportsSearchApi

type Endpoints = typeof reportsSearchApi.endpoints
type InitiateActions = {
  [K in keyof Endpoints]: ReturnType<ReturnType<Endpoints[K]['initiate']>>
}
export type TReportsSearchApiActions = InitiateActions[keyof InitiateActions]
