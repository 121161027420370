import {
  AssetFilterDialog,
  AssetsDataGrid,
  type AssetSearchDTO,
  AssetTypeFilter,
  type IFilterData,
  initialFilterData,
  type FleetSearchInput,
} from '@flyward/assets'
import { useGetAllAssetsBySearchQuery, useLazyGetFiltersQuery } from '@flyward/assets/store'
import { Size, IconVariant, useHeaderContent, type AssetType, ErrorBoundary, ComponentContainer, SearchInput, PageSizes } from '@flyward/platform'
import { Button, ButtonVariant, type IButtonProps } from '@flyward/platform/components/Button/Button'
import { useEffect, useState } from 'react'
import { PageContainer } from '../../layout'
import { isNil } from 'lodash'
import { FleetEmptyState } from '@flyward/main-app/assets'
import { useNavigate } from 'react-router-dom'
import queryString from 'query-string'

interface IAssetsState {
  assets: AssetSearchDTO[]
  filterData: IFilterData
}

const emptyState: IAssetsState = {
  assets: [],
  filterData: initialFilterData,
}

export const FleetPage = () => {
  const navigate = useNavigate()
  const { setTitle, setButtons } = useHeaderContent()
  const [initialState, setInitialState] = useState<IAssetsState>(emptyState)
  const [assetFilterData, setAssetFilterData] = useState<IFilterData | undefined>(undefined)
  const [assetFilterSelectedData, setAssetFilterSelectedData] = useState<IFilterData | null>(null)
  const [assetTypeFilter, setAssetTypeFilter] = useState<AssetType | undefined>(undefined)
  const [selectedAssetIds, setSelectedAssetIds] = useState<string[]>([])
  const [fleetSearchInput, setFleetSearchInput] = useState<FleetSearchInput>({
    searchTerm: '',
    expand: true,
    assetType: assetTypeFilter,
    filterData: assetFilterSelectedData,
    Page: 0,
    PageSize: PageSizes.Twenty,
  })

  const { data: allAssets } = useGetAllAssetsBySearchQuery(fleetSearchInput)
  useEffect(() => {
    const buttonProps: IButtonProps[] = [
      {
        variant: ButtonVariant.Primary,
        size: Size.Medium,
        label: 'Fly Forward',
        leftIcon: IconVariant.FlightTakeOff,
        onClick: () => {
          const assetIds: string[] = []
          selectedAssetIds.forEach((assetSerialNumber) => {
            const asset = initialState.assets.find((t) => t.serialNumber === assetSerialNumber)
            const assetId = asset?.assetId
            if (assetId !== undefined) {
              assetIds.push(assetId)
            }
          })
          navigate(`/flyforward/?${queryString.stringify({ assetIds })}`)
        },
      },
    ]

    setTitle('Fleet Summary')
    setButtons(buttonProps)

    return () => {
      setTitle('')
      setButtons([])
    }
  }, [initialState.assets, navigate, selectedAssetIds, setButtons, setTitle])

  const [trigger, { data: filterData }] = useLazyGetFiltersQuery()

  useEffect(() => {
    trigger()
    setAssetFilterData(filterData)
    if (isNil(assetFilterSelectedData)) {
      setAssetFilterSelectedData(filterData!)
    }
    const initialState: IAssetsState = {
      assets: allAssets?.items ?? [],
      filterData: filterData!,
    }
    setInitialState(initialState)
  }, [allAssets, filterData, trigger])

  const applyFilter = (filterData: IFilterData) => {
    setAssetFilterSelectedData(filterData)
  }

  const setCurrentPage = (page: number) => {
    setFleetSearchInput((oldFleetSearchInput: FleetSearchInput) => ({
      ...oldFleetSearchInput,
      Page: page,
    }))
  }

  const setPageSize = (size: PageSizes) => {
    setFleetSearchInput((oldFleetSearchInput: FleetSearchInput) => ({
      ...oldFleetSearchInput,
      PageSize: size,
    }))
  }

  const onFilterUpdateHandler = (filter: Partial<FleetSearchInput>) => {
    setFleetSearchInput((oldFleetSearchInput: FleetSearchInput) => ({
      ...oldFleetSearchInput,
      ...filter,
    }))
    setCurrentPage(0)
  }

  return (
    <PageContainer>
      <ErrorBoundary>
        <div className="flex flex-grow flex-col gap-y-2">
          <ComponentContainer className="flex-grow justify-start gap-0">
            <div className="flex w-full items-center justify-between bg-header-table p-6">
              <div className="flex gap-x-6">
                <SearchInput
                  inputClassName="w-49"
                  setValueAfterValidation={(value: string) => {
                    onFilterUpdateHandler({ ...fleetSearchInput, searchTerm: value })
                  }}
                  resetValue={() => {
                    onFilterUpdateHandler({ ...fleetSearchInput, searchTerm: '' })
                  }}
                />
                <AssetTypeFilter
                  assetType={assetTypeFilter}
                  setAssetType={(assetTypeChosen) => {
                    setAssetTypeFilter(assetTypeChosen)
                    onFilterUpdateHandler({ ...fleetSearchInput, assetType: assetTypeChosen })
                  }}
                />
                {!isNil(assetFilterData) && !isNil(assetFilterSelectedData) && (
                  <AssetFilterDialog
                    filterData={assetFilterSelectedData}
                    applyFilter={applyFilter}
                    availableFilterOptions={{
                      msns: initialState.filterData.msns,
                      lessees: initialState.filterData.lessees,
                      aircraftModels: initialState.filterData.aircraftModels,
                      engineTypes: initialState.filterData.engineTypes,
                      aircraftAges: initialState.filterData.aircraftAges,
                      redeliveryDates: initialState.filterData.redeliveryDates,
                      regions: initialState.filterData.regions,
                      portfolio: initialState.filterData.portfolio,
                    }}
                    filter={fleetSearchInput}
                    onFilterUpdate={onFilterUpdateHandler}
                  />
                )}
              </div>
              <Button variant={ButtonVariant.Secondary} size={Size.Small} label="New Aircraft/Engine" onClick={() => {}} leftIcon={IconVariant.Add} />
            </div>
            {isNil(allAssets) ? (
              <FleetEmptyState width={998} height={580} />
            ) : (
              <AssetsDataGrid
                key={assetTypeFilter}
                selectedAssetIds={selectedAssetIds}
                setSelectedAssetsIds={setSelectedAssetIds}
                fleet={allAssets}
                setCurrentPage={setCurrentPage}
                setPageSize={setPageSize}
                currentPage={fleetSearchInput.Page}
                pageSize={fleetSearchInput.PageSize}
              />
            )}
          </ComponentContainer>
        </div>
      </ErrorBoundary>
    </PageContainer>
  )
}
