import { AirframeChecksTable, BasicAssumptionsDisplay, InflationDisplay, MaintenanceProgramDetailsArea } from '@flyward/knowledgeBase'
import { PageContainer } from '@flyward/main-app/layout'
import {
  ButtonVariant,
  ErrorBoundary,
  Input,
  InputType,
  Size,
  useHeaderContent,
  useNavigationState,
  useSpinnerState,
  type IButtonProps,
  type IInputProps,
} from '@flyward/platform'
import { useGetAirframeMaintenanceProgramDetailsQuery } from '@flyward/platform/store/slices'
import { isEmpty, isNil } from 'lodash'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

export const AirframeMaintenanceProgramPage = () => {
  const { id } = useParams()
  const { setTitle, setInputs, setButtons, setHasBackButton } = useHeaderContent()
  const { toggleVisibility } = useNavigationState()
  const { showSpinner, hideSpinner } = useSpinnerState()

  const { data: airframeMaintenanceProgram, isLoading } = useGetAirframeMaintenanceProgramDetailsQuery(
    { programId: id! },
    {
      skip: isNil(id) || isEmpty(id),
    },
  )

  useEffect(() => {
    const buttonProps: IButtonProps[] = [
      {
        variant: ButtonVariant.Primary,
        size: Size.Medium,
        label: 'Save',
        onClick: () => {},
      },
    ]

    const inputProps: IInputProps[] = [
      {
        label: 'Base year',
        controlledValue: (airframeMaintenanceProgram?.kbReferenceYear ?? 0).toString(),
        setValueAfterValidation: () => {},
        type: InputType.NaturalNumber,
        inputClassName: 'w-20',
      },
    ]

    toggleVisibility()
    setTitle('Airframe Maintenance Program')
    setButtons(buttonProps)
    setHasBackButton(true)
    setInputs(inputProps)

    return () => {
      setTitle('')
      setButtons([])
      setHasBackButton(false)
      setInputs([])
      toggleVisibility()
    }
  }, [airframeMaintenanceProgram?.kbReferenceYear, setButtons, setHasBackButton, setInputs, setTitle, toggleVisibility])

  isLoading ? showSpinner() : hideSpinner()

  return (
    <PageContainer>
      <ErrorBoundary>
        <MaintenanceProgramDetailsArea title="Airframe">
          <div className="flex gap-6">
            <Input
              id="manufacturer-air-maintenance-program"
              controlledValue={airframeMaintenanceProgram?.originalEquipmentManufacturer}
              label="Manufacturer"
            />
            <Input
              id="airframe-model-air-maintenance-program"
              controlledValue={airframeMaintenanceProgram?.compatibleComponentModels?.join(', ')}
              label="Airframe Model"
            />
          </div>
          <div className="flex gap-6">
            <Input id="aircraft-air-maintenance-program" controlledValue={airframeMaintenanceProgram?.aircraft} label="Aircraft" />
            <Input id="series-air-maintenance-program" controlledValue={airframeMaintenanceProgram?.series} label="Series" />
          </div>
        </MaintenanceProgramDetailsArea>
        <MaintenanceProgramDetailsArea title="Checks">
          <AirframeChecksTable checks={airframeMaintenanceProgram?.checks} />
        </MaintenanceProgramDetailsArea>
        <MaintenanceProgramDetailsArea title="Inflation">
          <InflationDisplay inflation={airframeMaintenanceProgram?.inflation} />
        </MaintenanceProgramDetailsArea>
        <MaintenanceProgramDetailsArea title="Basic Assumptions">
          <BasicAssumptionsDisplay basicAssumption={airframeMaintenanceProgram?.basicAssumptions} />
        </MaintenanceProgramDetailsArea>
      </ErrorBoundary>
    </PageContainer>
  )
}
