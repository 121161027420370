import { APIRoutes } from '@flyward/platform/constants'
import { flywardApi } from '@flyward/platform/store/slices/api/flywardApi/flywardApiSlice'
import {
  type IGetAllAirframeMaintenanceProgramsQueryResponse,
  type IGetAirframeMaintenanceProgramDetailsQueryRequest,
  type IGetAirframeMaintenanceProgramDetailsQueryResponse,
} from './types'

const airframeMaintenanceProgramsApi = flywardApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllAirframeMaintenancePrograms: builder.query<IGetAllAirframeMaintenanceProgramsQueryResponse, boolean>({
      query: (includeInactive) => ({
        url: APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController.AirframesPrograms.GetAllAirframePrograms(),
        method: 'GET',
        params: { includeInactive },
      }),
      providesTags: ['airframeMaintenancePrograms'],
    }),
    getAirframeMaintenanceProgramDetails: builder.query<
      IGetAirframeMaintenanceProgramDetailsQueryResponse,
      IGetAirframeMaintenanceProgramDetailsQueryRequest
    >({
      query: ({ programId }) => ({
        url: APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController.AirframesPrograms.GetSingleAirframeProgram(programId),
        method: 'GET',
      }),
      providesTags: (_, __, request) => [{ type: 'airframeMaintenancePrograms', id: request.programId }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAllAirframeMaintenanceProgramsQuery,
  useGetAirframeMaintenanceProgramDetailsQuery,
  util: airframeMaintenanceProgramsApiSliceUtil,
} = airframeMaintenanceProgramsApi

type Endpoints = typeof airframeMaintenanceProgramsApi.endpoints
type InitiateActions = {
  [K in keyof Endpoints]: ReturnType<ReturnType<Endpoints[K]['initiate']>>
}
export type TAirframeMaintenanceProgramsApiActions = InitiateActions[keyof InitiateActions]
