/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  ButtonVariant,
  FlyToType,
  IconVariant,
  isFutureMonth,
  isWithinFiftyYears,
  Size,
  useHeaderContent,
  type IButtonProps,
} from '@flyward/platform'
import { useAppDispatch, useAppSelector } from '@flyward/platform/store/configureHooks'
import { selectSortedReportAssets } from '@flyward/platform/store/slices/features/flyForward/flyForwardSelectors'
import { isNil } from 'lodash'
import { useEffect, useState } from 'react'
import { FlyForwardSave } from './FlyForwardSave'
import { useComputeSchedule } from './useComputeSchedule'
import { setSuccessfulReportId } from '@flyward/platform/store/slices'

const FlyForwardPageHeader = () => {
  const reportAssets = useAppSelector(selectSortedReportAssets)

  const { setTitle, setButtons } = useHeaderContent()

  const dispatch = useAppDispatch()

  const [canFlyForward, setCanFlyForward] = useState<boolean>(false)

  const { computeSchedule } = useComputeSchedule()

  useEffect(() => {
    const flyButtonProps: IButtonProps[] = [
      {
        variant: ButtonVariant.Primary,
        size: Size.Medium,
        label: 'Fly',
        leftIcon: IconVariant.FlightTakeOff,
        disabled: !canFlyForward,
        onClick: () => {
          dispatch(setSuccessfulReportId({ reportId: '' }))

          computeSchedule(reportAssets).then((reportId) => {
            if (!isNil(reportId)) {
              dispatch(setSuccessfulReportId({ reportId }))
            }
          })
        },
      },
    ]

    setTitle('Fly Forward')
    setButtons(flyButtonProps)

    return () => {
      setTitle('')
      setButtons([])
    }
  }, [setButtons, setTitle, computeSchedule, reportAssets, canFlyForward, dispatch])

  useEffect(() => {
    setCanFlyForward(false)

    if (reportAssets.length === 0) {
      setCanFlyForward(false)
      return
    }

    const canFlyForward = reportAssets.every(
      (reportAsset) =>
        reportAsset.flyForwardParameters.flyToType === FlyToType.Redelivery ||
        (isFutureMonth(reportAsset.flyForwardParameters.endDate) && isWithinFiftyYears(reportAsset.flyForwardParameters.endDate)),
    )

    setCanFlyForward(canFlyForward)
  }, [reportAssets])

  return <FlyForwardSave />
}

export { FlyForwardPageHeader }
