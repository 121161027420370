import { APIRoutes } from '@flyward/platform/constants'
import { http, HttpResponse } from 'msw'
import { type IGetAirframeMaintenanceProgramDetailsQueryResponse } from './types'
import { ComponentCategory } from '@flyward/platform/models'

const airframeMaintenanceProgramApiMocks = [
  http.get(APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController.AirframesPrograms.GetSingleAirframeProgram('*'), () => {
    return HttpResponse.json<IGetAirframeMaintenanceProgramDetailsQueryResponse>(
      {
        checks: [
          {
            airframekbProgramId: '1438f8d6-3268-4f72-8a3b-21786e1f4878',
            componentCategory: ComponentCategory.LandingGear,
            componentModel: 'test',
            kbReferenceYear: 2024,
            masterComponentKBProgramId: 'asdasdsa',
            airframeCheckLimit: {
              airframeCheckId: '7c36be61-ec9e-4e17-be17-25a4bdfac39d',
              limitId: '66e445e5-0ee8-40a7-a095-f851ce8483af',
              utilizationUnit: 1,
              utilization: 144,
              id: 'd6aa080f-1970-45b8-8cc7-7aaefd87ee34',
            },
            checkId: '7c36be61-ec9e-4e17-be17-25a4bdfac39d',
            checkType: 144,
            utilizationUnit: 1,
            baseCost: 1500000,
          },
          {
            airframekbProgramId: '1438f8d6-3268-4f72-8a3b-21786e1f4878',
            componentCategory: ComponentCategory.LandingGear,
            componentModel: 'test',
            kbReferenceYear: 2024,
            masterComponentKBProgramId: 'asdasdsa',
            airframeCheckLimit: {
              airframeCheckId: 'c2176b88-df29-445f-a48e-4ba637c3d015',
              limitId: 'd6aa080f-1970-45b8-8cc7-7aaefd87ee34',
              utilizationUnit: 1,
              utilization: 72,
              id: 'd6aa080f-1970-45b8-8cc7-7aaefd87ee34',
            },
            checkId: 'c2176b88-df29-445f-a48e-4ba637c3d015',
            checkType: 72,
            utilizationUnit: 1,
            baseCost: 708079.725,
          },
        ],
        inflation: {
          referenceYear: 2017,
          percentage: 3.5,
          id: '9989d2f0-ba9a-4440-89f1-99e715185ac8',
          componentType: ComponentCategory.Airframe,
          kbProgramId: '1438f8d6-3268-4f72-8a3b-21786e1f4878',
        },
        maintenanceProgramName: 'Boeing - B737-800 - 2021',
        isActive: true,
        dateOfCreation: '2024-07-10T06:50:07.3901530Z',
        masterComponentKBProgramId: '1438f8d6-3268-4f72-8a3b-21786e1f4878',
        compatibleComponentModels: ['B737-800'],
        kbReferenceYear: 2021,
        series: '800',
        aircraft: 'Boeing 737/800',
        originalEquipmentManufacturer: 'Boeing',
        basicAssumptions: {
          kbProgramId: '1438f8d6-3268-4f72-8a3b-21786e1f4878',
          checkDowntimeMonths: 2,
          monthsDelayInPaymentOnClaims: 1,
        },
        createdAt: '2024-07-16T05:34:24.214085+00:00',
        lastModifiedAt: '2024-07-16T05:34:24.214085+00:00',
      },
      {
        status: 200,
      },
    )
  }),
]

export { airframeMaintenanceProgramApiMocks }
