import type { StandaloneEngineMaintenancePrograms } from '@flyward/assets/models'
import { Combobox } from '@flyward/platform'
import { isNil } from 'lodash'

export const EditEngineMaintenanceProgram = ({ assignedEngineProgramId, availablePrograms, changePrograms }: StandaloneEngineMaintenancePrograms) => (
  <div className="flex w-full gap-x-6">
    <Combobox
      items={availablePrograms.availableEnginePrograms}
      itemLabel="engine program"
      className="basis-3/12"
      label="Assign Engine Program"
      selectedValues={isNil(assignedEngineProgramId) ? [] : [assignedEngineProgramId?.toString()]}
      setSelectedValues={(value: string[]) => {
        changePrograms.changeEngineProgram(value[0])
      }}
    />
    <div className="mr-12 basis-2/6"></div>
  </div>
)
