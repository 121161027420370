import { persistZitadelUserAccessToken, removePersistedZitadelUserAccessToken, ZitadelInstance, type ZitadelTokenResponse } from '@flyward/platform'
import { useAppDispatch } from '@flyward/platform/store/configureHooks'
import { clearZitadelUserInformation, setZitadelUserInformation } from '@flyward/platform/store/slices/features'
import { type ZitadelAuth } from '@zitadel/react'
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState, type ReactNode } from 'react'
import { type ZitadelAuthenticatedContextType } from './ZitadelAuthenticatedContextType'

const ZitadelAuthenticatedContext = createContext<ZitadelAuthenticatedContextType | undefined>(undefined)

export const useZitadelAuthenticated = (): ZitadelAuthenticatedContextType => {
  const context = useContext(ZitadelAuthenticatedContext)
  if (context === undefined) {
    throw new Error('useZitadelAuthenticated must be used within an ZitadelAuthenticatedProvider')
  }
  return context
}

interface IZitadelAuthProviderProps {
  children: ReactNode
}

export const ZitadelAuthenticatedProvider = ({ children }: IZitadelAuthProviderProps) => {
  const dispatch = useAppDispatch()

  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null)

  const zitadel = useRef<ZitadelAuth>(ZitadelInstance.getInstance())

  useEffect(() => {
    zitadel.current.userManager
      .getUser()
      .then((user) => {
        if (user === null) {
          zitadel.current.authorize()
        } else {
          setIsAuthenticated(true)
          const tokenResponse: ZitadelTokenResponse = {
            accessToken: user.access_token,
          }
          persistZitadelUserAccessToken(tokenResponse)
          dispatch(
            setZitadelUserInformation({
              user: {
                token: tokenResponse,
              },
            }),
          )
        }
      })
      .catch(() => {
        setIsAuthenticated(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zitadel])

  const logout = useCallback((): void => {
    zitadel.current.signout()
    removePersistedZitadelUserAccessToken()
    dispatch(clearZitadelUserInformation())
  }, [dispatch, zitadel])

  const authContextValue = useMemo(() => ({ isUserAuthenticated: isAuthenticated, logout }), [isAuthenticated, logout])

  return <ZitadelAuthenticatedContext.Provider value={authContextValue}>{children}</ZitadelAuthenticatedContext.Provider>
}
