import {
  type CheckTypes,
  CheckTypesDisplay,
  NumberDisplay,
  NumberDisplayType,
  type TableColumn,
  type UtilizationUnits,
  UtilizationUnitsDisplay,
} from '@flyward/platform'
import { MaintenanceProgramDetailsTable } from './MaintenanceProgramDetailsTable'

export interface ChecksDisplay {
  checkId: string
  checkType: CheckTypes
  utilizationUnit: UtilizationUnits
  utilization: number
  baseCost: number
}

interface IChecksTableProps {
  checks: ChecksDisplay[] | undefined
  canAddNewRow: boolean
}

const tableColumns: Array<TableColumn<ChecksDisplay>> = [
  {
    accessor: 'checkId',
    title: '',
    isIdField: true,
    isHidden: true,
  },
  {
    accessor: 'checkType',
    title: 'Event Type',
    valueGetter: (value) => {
      return CheckTypesDisplay(value as CheckTypes)
    },
  },
  {
    accessor: 'utilizationUnit',
    title: 'Unit',
    valueGetter: (value) => {
      return UtilizationUnitsDisplay(value as UtilizationUnits)
    },
  },
  {
    accessor: 'utilization',
    title: 'Limit',
    valueGetter: (value) => <NumberDisplay value={value} />,
  },
  {
    accessor: 'baseCost',
    title: 'Cost',
    valueGetter: (value) => <NumberDisplay displayType={NumberDisplayType.CurrencyRounded} value={value} />,
  },
]

export const ChecksTable = ({ checks, canAddNewRow }: IChecksTableProps): React.ReactElement<IChecksTableProps> => {
  return <MaintenanceProgramDetailsTable canAddNewRow={canAddNewRow} columns={tableColumns} data={checks} />
}
