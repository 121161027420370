import { type ContractualFlightHoursToFlightCyclesPerformanceRestorationReserve } from '@flyward/assets/models'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { contractualReserveColumns } from './constants'
import { GenericTable } from '@flyward/platform/components/Table/GenericTable'

interface IContractualReserveTabProps {
  performanceRestorationReserves: ContractualFlightHoursToFlightCyclesPerformanceRestorationReserve[]
}

const ContractualReserveTab = ({ performanceRestorationReserves }: IContractualReserveTabProps) => {
  const contractualReserveTable = useReactTable<ContractualFlightHoursToFlightCyclesPerformanceRestorationReserve>({
    data: performanceRestorationReserves,
    columns: contractualReserveColumns,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection: false,
  })

  return <GenericTable {...contractualReserveTable} />
}

export { ContractualReserveTab }
export type { IContractualReserveTabProps }
