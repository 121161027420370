import { isNil } from 'lodash'
import { type AircraftEngine, type StandAloneEngineDTO } from '../../../../models'
import { StandAloneEngineDetails } from '../StandAloneEngineDetails'
import { EngineDetails } from './EngineDetails'

interface IEngineTechSpecProps {
  engine: AircraftEngine | undefined
  standAloneEngine: StandAloneEngineDTO | undefined
}

export const EngineTechSpec = ({ engine, standAloneEngine }: IEngineTechSpecProps) => {
  return (
    <div className="flex flex-col gap-y-4">
      {!isNil(standAloneEngine) && <StandAloneEngineDetails standAloneEngine={standAloneEngine} />}
      {!isNil(engine) && <EngineDetails key={engine.componentId} engine={engine} />}
    </div>
  )
}
