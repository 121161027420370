import { APIRoutes } from '@flyward/platform/constants'
import { http, HttpResponse } from 'msw'
import { type IGetAuxiliaryPowerUnitMaintenanceProgramDetailsQueryResponse } from './types'
import { ComponentCategory } from '@flyward/platform/models'

const auxiliaryPowerUnitMaintenanceProgramMocks = [
  http.get(APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController.AuxiliaryPowerUnits.GetSingleAuxiliaryPowerUnitProgram('*'), () => {
    return HttpResponse.json<IGetAuxiliaryPowerUnitMaintenanceProgramDetailsQueryResponse>(
      {
        check: {
          componentCategory: ComponentCategory.LandingGear,
          componentModel: 'test',
          kbReferenceYear: 2024,
          masterComponentKBProgramId: 'asdasdsa',
          auxiliaryPowerUnitkbProgramId: 'd591edde-cd67-4b2b-b614-6ef436ca1032',
          auxiliaryPowerUnitCheckLimit: {
            id: 'bc30e84b-8416-42ca-bb46-18ef41097a66',
            auxiliaryPowerUnitCheckId: 'd591edde-cd67-4b2b-b614-6ef436ca1032',
            limitId: 'bc30e84b-8416-42ca-bb46-18ef41097a66',
            utilizationUnit: 4,
            utilization: 6000,
          },
          checkId: 'd591edde-cd67-4b2b-b614-6ef436ca1032',
          checkType: 5,
          utilizationUnit: 4,
          baseCost: 299943,
        },
        inflation: {
          kbProgramId: 'd591edde-cd67-4b2b-b614-6ef436ca1032',
          componentType: ComponentCategory.Airframe,
          referenceYear: 2017,
          percentage: 3.5,
          id: '9deab9c2-68e7-43f2-95b9-f76c1e54e6e4',
        },
        maintenanceProgramName: 'Hamilton - APS 3200 - 2021',
        isActive: true,
        dateOfCreation: '2024-07-10T06:50:07.3496167Z',
        masterComponentKBProgramId: 'd591edde-cd67-4b2b-b614-6ef436ca1032',
        compatibleComponentModels: ['APS 3200'],
        kbReferenceYear: 2021,
        originalEquipmentManufacturer: 'Hamilton',
        basicAssumptions: {
          kbProgramId: 'd591edde-cd67-4b2b-b614-6ef436ca1032',
          checkDowntimeMonths: 1,
          monthsDelayInPaymentOnClaims: 1,
        },
        createdAt: '2024-07-16T08:19:19.8818824+00:00',
        lastModifiedAt: '2024-07-16T08:19:19.8818824+00:00',
      },
      {
        status: 200,
      },
    )
  }),
]

export { auxiliaryPowerUnitMaintenanceProgramMocks }
