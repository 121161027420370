import { type AssetSearchDTO } from '@flyward/assets'
import { ComponentContainer, type PaginatedResult } from '@flyward/platform'
import { setSelectedAssetId, setSuccessfulReportId, useFlyForwardDefaultParametersPrefetch } from '@flyward/platform/store'
import { useAppDispatch, useAppSelector } from '@flyward/platform/store/configureHooks'
import { selectSortedReportAssets, selectSelectedAssetId } from '@flyward/platform/store/slices/features/flyForward/flyForwardSelectors'
import React, { useEffect } from 'react'
import { FlyForwardAssetDetails } from './FlyForwardAssetDetails'
import { FlyForwardAssetSelection } from './FlyForwardAssetSelection'
import { useSearchParams } from 'react-router-dom'
import { isNil } from 'lodash'

interface IFlyForwardPageProps {
  allAssetsData: PaginatedResult<AssetSearchDTO>
}

const FlyForwardPageBody = ({ allAssetsData }: IFlyForwardPageProps) => {
  const reportAssets = useAppSelector(selectSortedReportAssets)
  const selectedAssetId = useAppSelector(selectSelectedAssetId)

  const prefetchGetAllAssetDefaultFlyForwardParameters = useFlyForwardDefaultParametersPrefetch('getFlyForwardDefaultParameters')

  const [searchParams] = useSearchParams()

  const initialParams: string[] = searchParams.getAll('assetIds')

  const selectedAsset = allAssetsData.items.find((asset: AssetSearchDTO) => asset.assetId === selectedAssetId)

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setSelectedAssetId({ assetId: initialParams?.[0] }))
    dispatch(setSuccessfulReportId({ reportId: '' }))

    for (const assetId of initialParams) {
      prefetchGetAllAssetDefaultFlyForwardParameters({ assetId })
    }
    // intended for [] so that we 100% run it only once
  }, [])

  return (
    <>
      <ComponentContainer className="p-6">
        <FlyForwardAssetSelection allAssetsData={allAssetsData.items} />
      </ComponentContainer>
      {reportAssets.map((reportAsset) => (
        <React.Fragment key={reportAsset.assetId}>
          {!isNil(selectedAsset) && selectedAssetId === reportAsset.assetId && (
            <FlyForwardAssetDetails currentAssetData={reportAsset} selectedAsset={selectedAsset} />
          )}
        </React.Fragment>
      ))}
    </>
  )
}

export { FlyForwardPageBody }
