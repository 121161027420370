export enum TypeOfEOLAdjustment {
  MultipleInputMultipleOutput = 1,
  PowerInPowerOut = 2,
}

export const TypeOfEOLAdjustmentDisplay = (value: TypeOfEOLAdjustment | undefined | null): string => {
  if (value === null || value === undefined) {
    return '-'
  }
  switch (value) {
    case TypeOfEOLAdjustment.MultipleInputMultipleOutput:
      return 'MIMO'
    case TypeOfEOLAdjustment.PowerInPowerOut:
      return 'PIPO'
  }
}
