export enum MaintenanceReserveCollectionType {
  None = 0,
  Cash = 1,
  LetterOfCredit = 2,
}

export const MaintenanceReserveCollectionTypeDisplay = (
  value: MaintenanceReserveCollectionType | undefined | null,
): string => {
  if (value === null || value === undefined) {
    return '-'
  }
  switch (value) {
    case MaintenanceReserveCollectionType.None:
      return 'None'
    case MaintenanceReserveCollectionType.Cash:
      return 'Cash'
    case MaintenanceReserveCollectionType.LetterOfCredit:
      return 'LOC'
  }
}
