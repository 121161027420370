import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from './utils'

export enum InputVariant {
  Checkbox = 'checkbox',
  Email = 'email',
  Number = 'number',
  Password = 'password',
  Radio = 'radio',
  Search = 'search',
  Text = 'text',
}

const inputVariants = cva(
  'flex h-10 w-full rounded border border-black-20 bg-black-0 px-3 pr-4 py-2 !text-sm file:border-0 file:bg-transparent file:text-sm placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed active:border-primary focus-visible:border-primary hover:border-primary disabled:text-black-20 disabled:border-black-10',
  {
    variants: {
      variant: {
        [InputVariant.Checkbox]:
          'appearance-none p-0 m-0 w-5 h-5 border-2 rounded-md border-text-1 text-text-1 outline-none checked:border-primary hover:border-primary checked:before:content-[""] checked:before:block checked:before:w-3 checked:before:h-3 checked:before:m-0.5 checked:before:rounded-full checked:before:bg-primary',
        [InputVariant.Number]: '',
        [InputVariant.Email]: '',
        [InputVariant.Password]: '',
        [InputVariant.Radio]:
          'appearance-none p-0 m-0 min-w-5 min-h-5 w-5 h-5 border-2 rounded-full border-text-1 text-text-1 outline-none checked:border-primary hover:border-primary checked:before:content-[""] checked:before:block checked:before:w-3 checked:before:h-3 checked:before:m-0.5 checked:before:rounded-full checked:before:bg-primary',
        [InputVariant.Search]: '',
        [InputVariant.Text]: '',
      },
    },
    defaultVariants: {
      variant: InputVariant.Text,
    },
  },
)

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement>, VariantProps<typeof inputVariants> {
  variant: InputVariant
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, variant = InputVariant.Text, ...props }, ref) => {
  return <input type={variant} className={cn(inputVariants({ variant }), className)} ref={ref} {...props} />
})

export { Input }
