import { APIRoutes } from '@flyward/platform/constants'
import { flywardApi } from '@flyward/platform/store/slices/api/flywardApi/flywardApiSlice'
import {
  type IPutEnginesMaintenanceProgramMutationRequest,
  type IGetEnginesByAssetIdQueryRequest,
  type IGetEnginesByAssetIdQueryResponse,
  type IGetAllEngineChecksWithDetailsByAssetIdQueryResponse,
  type IGetAllEngineChecksWithDetailsByAssetIdQueryRequest,
} from './types'
import { ComponentCategory, ComponentTypeURI } from '@flyward/platform'

const enginesApi = flywardApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllEnginesByAssetId: builder.query<IGetEnginesByAssetIdQueryResponse, IGetEnginesByAssetIdQueryRequest>({
      query: ({ assetId, includeLLPs }) => ({
        url: APIRoutes.AssetsModule.Components.EnginesController.GetAll(assetId),
        params: {
          includeLLPs,
        },
        method: 'GET',
      }),
      providesTags: (_, __, request) => [{ type: 'engines', id: `${request.assetId}-${request.includeLLPs}` }],
    }),
    getAllEngineChecksWithDetailsByAssetId: builder.query<
      IGetAllEngineChecksWithDetailsByAssetIdQueryResponse,
      IGetAllEngineChecksWithDetailsByAssetIdQueryRequest
    >({
      query: ({ assetId }) => ({
        url: APIRoutes.AssetsModule.Components.EnginesController.GetAllChecksWithDetails(assetId),
        method: 'GET',
      }),
      providesTags: (_, __, request) => [{ type: 'componentChecks', id: `${request.assetId}-${ComponentTypeURI(ComponentCategory.EngineUnit)}` }],
    }),
    updateEnginesMaintenanceProgram: builder.mutation<void, IPutEnginesMaintenanceProgramMutationRequest>({
      query: ({ assetId, kbProgramId }) => ({
        url: APIRoutes.AssetsModule.Components.EnginesController.MaintenanceProgram(assetId),
        method: 'PUT',
        data: kbProgramId,
      }),
      invalidatesTags: (_, __, request) => [
        { type: 'engines', id: `${request.assetId}-true` },
        { type: 'engines', id: `${request.assetId}-false` },
      ],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAllEnginesByAssetIdQuery,
  useGetAllEngineChecksWithDetailsByAssetIdQuery,
  useUpdateEnginesMaintenanceProgramMutation,
  util: enginesApiSliceUtil,
} = enginesApi

type Endpoints = typeof enginesApi.endpoints
type InitiateActions = {
  [K in keyof Endpoints]: ReturnType<ReturnType<Endpoints[K]['initiate']>>
}
export type TEnginesApiActions = InitiateActions[keyof InitiateActions]
