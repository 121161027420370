import { Command as CmdkCommand } from 'cmdk'
import React, { useRef, useState } from 'react'
import {
  ButtonVariant,
  cn,
  Command as CnCommand,
  CommandGroup as CnCommandGroup,
  CommandItem as CnCommandItem,
  CommandList as CnCommandList,
  Label as CnLabel,
} from '../_shadcn'
import { Icon, IconVariant } from '../Icon'
import { Button } from '../Button'

interface MultiSelectValue {
  value: string
  label: string | React.ReactNode
}

interface IMultiSelectProps {
  placeholder: string
  onValueSelected: (value: MultiSelectValue) => void
  selectedValues: Array<Pick<MultiSelectValue, 'value'>>
  values: MultiSelectValue[]
  disabled?: boolean
  selectClassName?: string
  labelClassName?: string
  label?: string
  listContainerClassName?: string
}

const MultiSelect = ({
  placeholder,
  onValueSelected,
  selectedValues,
  values,
  disabled,
  selectClassName,
  labelClassName,
  label,
  listContainerClassName = 'max-h-[calc(100vh-12rem)]',
}: IMultiSelectProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [open, setOpen] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')

  const labelColor = disabled ?? false ? 'text-text-3' : 'text-text-1'

  const selectables = values.filter((v) => !selectedValues.some((sv) => sv.value === v.value))

  return (
    <div className={cn('flex flex-col justify-center gap-y-0.4', selectClassName)}>
      {label != null && (
        <CnLabel data-testid="select-label">
          <p className={cn(`!text-xs font-semibold ${labelColor}`, labelClassName)} data-testid="select-label-text">
            {label}
          </p>
        </CnLabel>
      )}
      <CnCommand className="overflow-visible bg-black-0" id="command-wrapper">
        <div
          className="border-input group flex cursor-pointer flex-row items-center rounded-md border text-sm ring-0 hover:border-primary"
          tabIndex={-1}
        >
          <CmdkCommand.Input
            ref={inputRef}
            value={inputValue}
            onValueChange={setInputValue}
            placeholder={placeholder}
            onFocus={() => {
              setOpen(true)
            }}
            onBlur={(e) => {
              if (e.relatedTarget === inputRef.current?.parentElement || e.relatedTarget === document.getElementById('command-wrapper')) {
                return
              }

              setOpen(false)
            }}
            className="w-[90%] flex-1 bg-transparent py-2 pl-3 text-sm font-normal outline-none placeholder:text-text-2 placeholder:opacity-40"
          />
          <Button
            className="z-10 py-2 pr-3"
            onClick={(e) => {
              e.stopPropagation()

              if (!open) {
                inputRef.current?.focus()
              } else {
                inputRef.current?.blur()
              }
            }}
            variant={ButtonVariant.Ghost}
          >
            {!open ? (
              <Icon variant={IconVariant.ExpandMore} className="m-0 text-text-1" />
            ) : (
              <Icon variant={IconVariant.ExpandLess} className="m-0 text-text-1" />
            )}
          </Button>
        </div>
        <div className="relative mt-2">
          <CnCommandList>
            {open && selectables.length > 0 && (
              <div className="absolute top-0 z-[1000] w-full rounded-lg border bg-black-0 text-text-1 shadow-dropdown outline-none animate-in">
                <CnCommandGroup className={cn('overflow-auto', listContainerClassName)}>
                  {selectables.map((fancyMultiSelectValue) => {
                    return (
                      <CnCommandItem
                        key={fancyMultiSelectValue.value}
                        onMouseDown={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                        }}
                        onSelect={() => {
                          setInputValue('')
                          onValueSelected(fancyMultiSelectValue)
                        }}
                        className="cursor-pointer"
                      >
                        {fancyMultiSelectValue.label}
                      </CnCommandItem>
                    )
                  })}
                </CnCommandGroup>
              </div>
            )}
          </CnCommandList>
        </div>
      </CnCommand>
    </div>
  )
}

export { MultiSelect, type MultiSelectValue }
