import { useState } from 'react'
import { type ISelectOption, type IYearMonth } from '../../models'
import { Select } from '../Select'
import { format, setMonth } from 'date-fns'

interface IYearsInterval {
  startYear: number
  endYear: number
}

interface IYearMonthSelectorProps {
  setValue: (value: IYearMonth) => void
  disabled?: boolean
  value?: IYearMonth
  yearsInterval: IYearsInterval
}

const YearMonthSelector = ({ setValue, disabled = false, value = undefined, yearsInterval }: IYearMonthSelectorProps) => {
  const [selectedDate, setSelectedDate] = useState<IYearMonth | undefined>(value)

  const getAllMonthsAsSelectOptions = (): ISelectOption[] => {
    const months: ISelectOption[] = []

    for (let i = 1; i <= 12; i++) {
      // months in JS are 0-indexed, so we need to subtract 1 from the month index
      const monthDate = setMonth(new Date(), i - 1)
      months.push({
        value: i.toString(), // Month index as value
        label: format(monthDate, 'MMMM'), // Full month name as label
      })
    }

    return months
  }

  const getYearsAsSelectOptions = (yearsInterval: IYearsInterval): ISelectOption[] => {
    const years: ISelectOption[] = []
    const { startYear, endYear } = yearsInterval

    for (let year = startYear; year <= endYear; year++) {
      years.push({
        value: year.toString(),
        label: year.toString(),
      })
    }

    return years
  }

  const monthsArray = getAllMonthsAsSelectOptions()
  const yearsArray = getYearsAsSelectOptions(yearsInterval)

  return (
    <div className="flex h-6 w-full justify-between gap-4">
      <Select
        label="Month"
        options={monthsArray}
        value={selectedDate?.month?.toString() ?? monthsArray[0].value}
        setValue={(value: string) => {
          setSelectedDate((prev) => ({
            year: prev?.year ?? new Date().getFullYear(),
            month: parseInt(value, 10),
          }))
          setValue({
            year: selectedDate?.year ?? new Date().getFullYear(),
            month: parseInt(value, 10),
          })
        }}
        selectClassName="flex-1"
        selectTriggerClassName="border-2 border-black-20 py-4 "
        disabled={disabled}
      />

      <Select
        label="Year"
        options={yearsArray}
        value={selectedDate?.year?.toString() ?? yearsArray[0].value}
        setValue={(value: string) => {
          setSelectedDate((prev) => ({
            month: prev?.month ?? 0,
            year: parseInt(value, 10),
          }))
          setValue({
            month: selectedDate?.month ?? 0,
            year: parseInt(value, 10),
          })
        }}
        selectClassName="flex-1"
        selectTriggerClassName="border-2 border-black-20 py-4"
        disabled={disabled}
      />
    </div>
  )
}

export { YearMonthSelector }
export type { IYearsInterval, IYearMonth }
