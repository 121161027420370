import { cn } from '../../utils'
import { type TableColumn, type TableRowData } from '../../common'
import {
  Table as CnTable,
  TableCell as CnTableCell,
  TableHead as CnTableHead,
  TableHeader as CnTableHeader,
  TableRow as CnTableRow,
} from '../../_shadcn'
import { isNil } from 'lodash'

interface IDynamicTableProps<T> {
  columns: Array<TableColumn<T>>
  data: TableRowData<T | undefined> | undefined
  summaryRow?: React.ReactElement
}

const defaultGetValue: <T>(value: T[keyof T] | object | null | string | number) => React.ReactNode = (value) => <>{value}</>

const DynamicTable = <T extends object>({ columns, data, summaryRow }: Readonly<IDynamicTableProps<T>>) => {
  const idField = columns.find((c) => c.isIdField)!

  if (isNil(data) || data.length === 0) {
    return <p>No data</p>
  }

  return (
    <CnTable>
      <CnTableHeader>
        <CnTableRow>
          {columns.map((column: TableColumn<T>) => (
            <CnTableHead
              key={column.accessor.toString()}
              className={cn('whitespace-nowrap p-1 font-bold', column.headerClassName, {
                hidden: column.isHidden,
              })}
            >
              {column.title}
            </CnTableHead>
          ))}
        </CnTableRow>
      </CnTableHeader>
      <tbody>
        {data.map((row) => {
          if (isNil(row)) {
            return <></>
          }

          return (
            <CnTableRow key={row[idField.accessor] as string}>
              {columns.map((column) => {
                const valueGetter = column.valueGetter ?? defaultGetValue
                const cellValue = valueGetter(row[column.accessor])
                return (
                  <CnTableCell
                    key={`${row[idField.accessor] as string}_${column.accessor.toString()}`}
                    data-accessor={column.accessor}
                    className={cn('whitespace-nowrap px-1', column.cellClassName, {
                      hidden: column.isHidden,
                    })}
                  >
                    {cellValue as string}
                  </CnTableCell>
                )
              })}
            </CnTableRow>
          )
        })}
        {summaryRow}
      </tbody>
    </CnTable>
  )
}

export { DynamicTable }
