import { ComponentContainer, ComponentTabs, ErrorBoundary, TextCellWithLabel } from '@flyward/platform'
import { type LandingGear } from '../../../../../models'
import { LGTechnicalTab } from './LGTechnicalTab'
import { LGContractualTab } from './LGContractualTab'
import { useState } from 'react'

interface ILandingGearDetailsProps {
  landingGear: LandingGear
}

export const LandingGearDetails = ({ landingGear }: ILandingGearDetailsProps): React.ReactElement<ILandingGearDetailsProps> => {
  const [currentTab, setCurrentTab] = useState<string>('Technical')
  const onTabChanged = (activeTab: string) => {
    setCurrentTab(activeTab)
  }
  const tabs = {
    Technical: {
      content: (
        <ErrorBoundary>
          <LGTechnicalTab landingGear={landingGear} />{' '}
        </ErrorBoundary>
      ),
    },
    Contractual: {
      content: (
        <ErrorBoundary>
          <LGContractualTab landingGear={landingGear} />{' '}
        </ErrorBoundary>
      ),
    },
  }

  return (
    <ComponentContainer className="p-6">
      <div className="flex w-full flex-col">
        <p className="mb-4 w-full border-b border-primary-light-2 pb-2 text-base font-semibold text-text-1">Landing Gear</p>
        <div className="flex flex-col gap-y-2">
          <div className="flex gap-x-6">
            <TextCellWithLabel className="basis-1/6" label="Manufacturer" info={landingGear.manufacturingDetail.manufacturer} />
            <TextCellWithLabel className="basis-1/6" label="P/N" info={landingGear.partNumber} />
            <TextCellWithLabel className="basis-1/6" label="S/N" info={landingGear.componentSerialNumber} />
            <div className="mr-12 basis-3/6" />
          </div>
          <ComponentTabs
            tabTriggerHeight="h-9"
            tabTriggerLabelHeight="h-8"
            tabs={tabs}
            controlledActiveTab={currentTab}
            onTabChanged={onTabChanged}
          />
        </div>
      </div>
    </ComponentContainer>
  )
}
