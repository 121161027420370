import { HeaderContentContextProvider, type IconVariant, NavigationStateContextProvider } from '@flyward/platform'
import { ProtectedRouteOutlet } from './ProtectedRouteOutlet'
import { UserAuthorizationProvider, ZitadelAuthenticatedProvider } from '@flyward/appIdentity'
import { MasterLayout } from '../MasterLayout'

interface Route {
  path: string
  label: string
  icon: IconVariant
}

interface IProtectedRoutesProp {
  routes: Route[]
}

export const ProtectedRoutes = ({ routes }: IProtectedRoutesProp) => {
  return (
    <ZitadelAuthenticatedProvider>
      <UserAuthorizationProvider>
        <NavigationStateContextProvider>
          <HeaderContentContextProvider>
            <MasterLayout routes={routes}>
              <ProtectedRouteOutlet />
            </MasterLayout>
          </HeaderContentContextProvider>
        </NavigationStateContextProvider>
      </UserAuthorizationProvider>
    </ZitadelAuthenticatedProvider>
  )
}
