export enum EnvironmentalImpactType {
  Benign = 1,
  Moderate = 2,
  Harsh = 3,
  VeryHarsh = 4,
}

export const EnvironmentalImpactTypeDisplay = (value: EnvironmentalImpactType) => {
  switch (value) {
    case EnvironmentalImpactType.Benign:
      return 'Benign'
    case EnvironmentalImpactType.Moderate:
      return 'Moderate'
    case EnvironmentalImpactType.Harsh:
      return 'Harsh'
    case EnvironmentalImpactType.VeryHarsh:
      return 'Very Harsh'
  }
}
