import { AzureAppInsightsInstance, Button, ButtonVariant, Size } from '@flyward/platform'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const UnauthorizedErrorPage = () => {
  const navigate = useNavigate()
  const appInsights = AzureAppInsightsInstance.getInstance()

  const handleGoHome = () => {
    navigate('/')
  }

  useEffect(() => {
    const currentUrl = window.location.href
    const previousUrl = document.referrer

    appInsights?.trackException({
      error: new Error('Page Not Authorized (403)'),
      severityLevel: 2,
      properties: {
        url: currentUrl,
        previousUrl,
        source: 'UnauthorizedErrorPage',
      },
    })
  }, [])

  return (
    <div className="flex h-screen flex-col items-center justify-center bg-gray-100 p-6">
      <h1 className="text-h2 font-bold text-red-600">403 - ACCESS DENIED</h1>
      <p className="mt-4 text-h6 text-gray-700">Oops, You do not have permission to access this page.</p>
      <Button variant={ButtonVariant.Primary} label="Go to Homepage" size={Size.Large} onClick={handleGoHome} className="mt-2" />
    </div>
  )
}
