import React, { type PropsWithChildren, useCallback, useMemo, useRef, useState } from 'react'
import { FullPageLoadingSpinner } from '../../components/FullPageSpinner/FullPageSpinner'
import { isNil } from 'lodash'

interface SpinnerStateContextType {
  isShowing: boolean
  showSpinner: (controlledDelaySeconds?: number) => void
  hideSpinner: (controlledDelaySeconds?: number) => void
}

const SpinnerStateContext = React.createContext<SpinnerStateContextType>({
  isShowing: false,
  showSpinner: () => {},
  hideSpinner: () => {},
})

const SpinnerStateContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [isShowing, setIsShowing] = useState<boolean>(false)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const changeSpinnerState = useCallback((state: boolean, controlledDelaySeconds?: number) => {
    if (!isNil(timeoutRef.current)) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(
      () => {
        setIsShowing(state)
      },
      !isNil(controlledDelaySeconds) ? controlledDelaySeconds * 1000 : 1000,
    )
  }, [])

  const value = useMemo(
    (): SpinnerStateContextType => ({
      isShowing,
      showSpinner: (controlledDelaySeconds) => {
        changeSpinnerState(true, controlledDelaySeconds)
      },
      hideSpinner: (controlledDelaySeconds) => {
        changeSpinnerState(false, controlledDelaySeconds)
      },
    }),
    [isShowing, changeSpinnerState],
  )

  return (
    <SpinnerStateContext.Provider value={value}>
      <FullPageLoadingSpinner isShowing={isShowing} />
      {children}
    </SpinnerStateContext.Provider>
  )
}

const useFullPageLoadingSpinner = () => React.useContext(SpinnerStateContext)
export { SpinnerStateContextProvider, useFullPageLoadingSpinner as useSpinnerState }
