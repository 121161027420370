import {
  BasicAssumptionsDisplay,
  DerateRatiosTable,
  EngineLifeLimitedPartsTable,
  EnginePerformanceRestorationTable,
  EnvironmentalImpactsTable,
  FlightHoursFlightCyclesRatioTable,
  InflationDisplay,
  MaintenanceProgramDetailsArea,
  StubLifeDisplay,
} from '@flyward/knowledgeBase'
import {
  ButtonVariant,
  ErrorBoundary,
  Input,
  InputType,
  Size,
  useHeaderContent,
  useNavigationState,
  type IButtonProps,
  type IInputProps,
} from '@flyward/platform'
import { useGetEngineMaintenanceProgramDetailsQuery } from '@flyward/knowledgeBase/store'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { PageContainer } from '../../../layout'
import { isEmpty, isNil } from 'lodash'

export const EngineMaintenanceProgramPage = () => {
  const { id } = useParams()
  const { setTitle, setInputs, setButtons, setHasBackButton } = useHeaderContent()
  const { toggleVisibility } = useNavigationState()

  const { data: engineProgram, isLoading } = useGetEngineMaintenanceProgramDetailsQuery(
    {
      programId: id!,
    },
    {
      skip: isNil(id) || isEmpty(id),
    },
  )

  useEffect(() => {
    const buttonProps: IButtonProps[] = [
      {
        variant: ButtonVariant.Primary,
        size: Size.Medium,
        label: 'Save',
        onClick: () => {},
      },
    ]

    const inputProps: IInputProps[] = [
      {
        label: 'Base year',
        controlledValue: (engineProgram?.kbReferenceYear ?? 0).toString(),
        setValueAfterValidation: () => {},
        type: InputType.NaturalNumber,
        inputClassName: 'w-20',
      },
    ]

    toggleVisibility()
    setTitle('Engine Maintenance Program')
    setButtons(buttonProps)
    setHasBackButton(true)
    setInputs(inputProps)

    return () => {
      setTitle('')
      setButtons([])
      setHasBackButton(false)
      setInputs([])
      toggleVisibility()
    }
  }, [setButtons, setTitle, setInputs, setHasBackButton, engineProgram?.kbReferenceYear, toggleVisibility])

  return (
    <PageContainer>
      <ErrorBoundary isLoadingControlled={isLoading}>
        <MaintenanceProgramDetailsArea title="Engine">
          <div className="flex gap-6">
            <Input controlledValue={engineProgram?.originalEquipmentManufacturer} label="Manufacturer" />
            <Input controlledValue={engineProgram?.compatibleComponentModels?.join(', ')} label="Engine Model" />
          </div>
          <EnginePerformanceRestorationTable
            enginePerformanceRestorationMaintenanceProgram={engineProgram?.enginePerformanceRestorationMaintenanceProgram}
          />
          <div className="py-2">
            <Input controlledValue={'LLP replacement'} label="Event type" />
          </div>
          <EngineLifeLimitedPartsTable engineLifeLimitedPartsMaintenancePrograms={engineProgram?.lifeLimitedPartPrograms} />
        </MaintenanceProgramDetailsArea>
        <MaintenanceProgramDetailsArea title="Inflation">
          <InflationDisplay inflation={engineProgram?.inflation} />
        </MaintenanceProgramDetailsArea>
        <MaintenanceProgramDetailsArea title="FH/FC Ratio">
          <FlightHoursFlightCyclesRatioTable flightHoursFlightCyclesRatios={engineProgram?.flightHoursFlightCyclesRatios} />
        </MaintenanceProgramDetailsArea>
        <MaintenanceProgramDetailsArea title="Derate Ratio">
          <DerateRatiosTable derateRatios={engineProgram?.derateRatios} />
        </MaintenanceProgramDetailsArea>
        <MaintenanceProgramDetailsArea title="Environmental Impact">
          <EnvironmentalImpactsTable environmentalImpacts={engineProgram?.environmentalImpacts} />
        </MaintenanceProgramDetailsArea>
        <MaintenanceProgramDetailsArea title="Basic Assumptions">
          <BasicAssumptionsDisplay basicAssumption={engineProgram?.basicAssumptions} />
        </MaintenanceProgramDetailsArea>
        <MaintenanceProgramDetailsArea title="Stub Life">
          <StubLifeDisplay stubLife={engineProgram?.stubLife} />
        </MaintenanceProgramDetailsArea>
      </ErrorBoundary>
    </PageContainer>
  )
}
