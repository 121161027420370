import { type AssetDetailsSnapshot } from '@flyward/forecasts/models/entities/AssetDetailsSnapshot'
import {
  type TableColumn,
  AssetType,
  EnvironmentalImpactType,
  fromIsoToFormatDate,
  getAssetTypeDisplayName,
  TextCellWithLabel,
} from '@flyward/platform'
import { isEmpty, isNil } from 'lodash'

export const columnsEngine: Array<TableColumn<AssetDetailsSnapshot>> = [
  { isHidden: true, title: '', isIdField: true, accessor: 'assetId' },
  { isHidden: true, title: '', accessor: 'serialNumber' },
  { title: 'Operator', accessor: 'lessee' },
  { title: 'Engine Model', accessor: 'engineModel' },
  {
    title: 'Op Environment',
    accessor: 'operatingEnvironment',
    valueGetter: (value) => EnvironmentalImpactType[value as number],
  },
  {
    title: 'DOM',
    accessor: 'dom',
    valueGetter: (value) => !isEmpty(value) && !isNil(value) && fromIsoToFormatDate(value as string),
  },
  { title: 'Region', accessor: 'region' },
  { title: 'Portfolio', accessor: 'portfolio' },
]

export const columnsAircrafts: Array<TableColumn<AssetDetailsSnapshot>> = [
  columnsEngine[0],
  columnsEngine[1],
  columnsEngine[2],
  { title: 'A/C type', accessor: 'type' },
  { title: 'Engine', accessor: 'engineModel' },
  ...columnsEngine.slice(4),
]

export const chooseColumns = (selectedAsset: AssetDetailsSnapshot) => {
  if (selectedAsset.assetType === AssetType.Aircraft) {
    return columnsAircrafts
  }
  return columnsEngine
}

interface IAssetInformationProps {
  asset: AssetDetailsSnapshot
  showSerialNumber?: boolean
}

const AssetInformation = ({ asset, showSerialNumber = false }: IAssetInformationProps) => {
  return (
    <>
      <p className={'mr-auto w-full border-b border-primary-light-2 pb-2 !text-base font-semibold text-text-1'}>
        {getAssetTypeDisplayName(asset.assetType)} {showSerialNumber && asset.serialNumber} Information
      </p>
      <div className="grid grid-cols-5 gap-4">
        {chooseColumns(asset).map((column) => {
          if (column.isHidden ?? false) {
            return null
          }

          const value =
            column.valueGetter != null ? (column.valueGetter(asset[column.accessor] as string) as string) : (asset[column.accessor] as string)

          return <TextCellWithLabel key={column.title} label={column.title} info={value} />
        })}
      </div>
    </>
  )
}

export { AssetInformation }
