import { type AircraftComponentDetailsDTO, type AircraftEngine, type Airframe, type AuxiliaryPowerUnit, type LandingGear } from '../../../../models'
import { AirframeDetails } from './Airframe'
import { AuxiliaryPowerUnitDetails } from './AuxiliaryPowerUnit'
import { EngineDetails } from '../../EngineDetails'
import { LandingGearDetails } from './LandingGear'
import { AircraftDetails } from '../AircraftDetails'
import { ErrorBoundary } from '@flyward/platform'

interface IAircraftTechSpecProps {
  assetDetails: AircraftComponentDetailsDTO | null | undefined
  airframes: Airframe[] | undefined
  engines: AircraftEngine[] | undefined
  auxiliaryPowerUnits: AuxiliaryPowerUnit[] | undefined
  landingGears: LandingGear[] | undefined
}

export const AircraftTechSpec = ({ assetDetails, airframes, engines, auxiliaryPowerUnits, landingGears }: IAircraftTechSpecProps) => {
  return (
    <div className="flex flex-col gap-y-4">
      <ErrorBoundary>
        {assetDetails?.aircraftDetails !== undefined && <AircraftDetails aircraft={assetDetails.aircraftDetails} />}
        {assetDetails?.aircraftDetails !== undefined &&
          airframes?.map((airframe) => <AirframeDetails key={airframe.componentId} aircraft={assetDetails?.aircraftDetails} airframe={airframe} />)}
        {engines?.map((engine) => <EngineDetails key={engine.componentId} engine={engine} />)}
        {landingGears?.map((landingGear) => <LandingGearDetails key={landingGear.componentId} landingGear={landingGear} />)}
        {auxiliaryPowerUnits?.map((auxiliaryPowerUnit) => (
          <AuxiliaryPowerUnitDetails key={auxiliaryPowerUnit.componentId} auxiliaryPowerUnit={auxiliaryPowerUnit} />
        ))}
      </ErrorBoundary>
    </div>
  )
}
