import { BasicAssumptionsDisplay, InflationDisplay, LandingGearChecksTable, MaintenanceProgramDetailsArea } from '@flyward/knowledgeBase'
import { PageContainer } from '@flyward/main-app/layout'
import {
  type IButtonProps,
  type IInputProps,
  ButtonVariant,
  ErrorBoundary,
  Input,
  InputType,
  Size,
  useHeaderContent,
  useNavigationState,
} from '@flyward/platform'
import { useGetLandingGearMaintenanceProgramDetailsQuery } from '@flyward/knowledgeBase/store'
import { isEmpty, isNil } from 'lodash'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

export const LandingGearMaintenanceProgramPage = () => {
  const { id } = useParams()
  const { setTitle, setInputs, setButtons, setHasBackButton } = useHeaderContent()
  const { toggleVisibility } = useNavigationState()

  const { data: landingGearMaintenanceProgram } = useGetLandingGearMaintenanceProgramDetailsQuery(
    { programId: id! },
    {
      skip: isNil(id) || isEmpty(id),
    },
  )

  useEffect(() => {
    const buttonProps: IButtonProps[] = [
      {
        variant: ButtonVariant.Primary,
        size: Size.Medium,
        label: 'Save',
        onClick: () => {},
      },
    ]

    const inputProps: IInputProps[] = [
      {
        label: 'Base year',
        controlledValue: (landingGearMaintenanceProgram?.kbReferenceYear ?? 0).toString(),
        setValueAfterValidation: () => {},
        type: InputType.NaturalNumber,
        inputClassName: 'w-20',
      },
    ]

    toggleVisibility()
    setTitle('Landing Gear Program')
    setButtons(buttonProps)
    setInputs(inputProps)
    setHasBackButton(true)

    return () => {
      setTitle('')
      setButtons([])
      setHasBackButton(false)
      setInputs([])
      toggleVisibility()
    }
  }, [setButtons, setTitle, setInputs, setHasBackButton, landingGearMaintenanceProgram?.kbReferenceYear, toggleVisibility])

  return (
    landingGearMaintenanceProgram !== undefined && (
      <PageContainer>
        <ErrorBoundary>
          <MaintenanceProgramDetailsArea title="Landing Gear OEM">
            <div className="flex gap-6">
              <Input controlledValue={landingGearMaintenanceProgram?.originalEquipmentManufacturer} label="Manufacturer" />
              <Input controlledValue={landingGearMaintenanceProgram?.compatibleComponentModels?.join(', ')} label="Landing gear model" />
            </div>
            <LandingGearChecksTable canAddNewRow checks={[landingGearMaintenanceProgram?.check]} />
          </MaintenanceProgramDetailsArea>
          <MaintenanceProgramDetailsArea title="Inflation">
            <InflationDisplay inflation={landingGearMaintenanceProgram?.inflation} />
          </MaintenanceProgramDetailsArea>
          <MaintenanceProgramDetailsArea title="Basic Assumptions">
            <BasicAssumptionsDisplay basicAssumption={landingGearMaintenanceProgram?.basicAssumptions} />
          </MaintenanceProgramDetailsArea>
        </ErrorBoundary>
      </PageContainer>
    )
  )
}
