import { ErrorBoundary } from '@flyward/platform'
import { NumberCellWithLabel, NumberDisplayType } from '@flyward/platform/components'
import { CheckTypesDisplay, ComponentCategoryDisplay } from '@flyward/platform/models'
import { type FlyForwardParametersDTO } from '@flyward/platform/models/DTOs/FlyForwardParametersDTO'
import { cloneDeep } from 'lodash'

interface IRatesParametersProps {
  flyForwardParameters: FlyForwardParametersDTO
}

const ReadonlyRatesParameters = ({ flyForwardParameters }: IRatesParametersProps) => {
  const sortedMasterComponentsRates = cloneDeep(flyForwardParameters.masterComponentsRates).sort((a, b) => {
    if (a.componentCategory < b.componentCategory) return -1
    if (a.componentCategory > b.componentCategory) return 1

    if (a.masterComponentSerialNumber > b.masterComponentSerialNumber) return -1
    if (a.masterComponentSerialNumber < b.masterComponentSerialNumber) return 1

    return 0
  })

  return (
    <div className="flex flex-col pt-4">
      <ErrorBoundary>
        {sortedMasterComponentsRates.map((masterComponentRate) => (
          <div
            key={masterComponentRate.masterComponentId + masterComponentRate.masterComponentSerialNumber}
            className="flex items-center gap-x-6 py-4"
          >
            <div>
              <p className="pb-2 text-base font-semibold text-gray-700">
                {ComponentCategoryDisplay(masterComponentRate.componentCategory)} {masterComponentRate.masterComponentSerialNumber}
              </p>
              <div className="flex gap-x-6">
                {masterComponentRate.mrRates.map((mrRate) => {
                  return (
                    <NumberCellWithLabel
                      key={`${masterComponentRate.masterComponentSerialNumber}-mr-${mrRate.checkType}-amount`}
                      label={`${CheckTypesDisplay(mrRate.checkType)} MR Rate`}
                      info={mrRate.rateAmount}
                      className="w-42"
                      displayType={NumberDisplayType.Currency}
                      currencyDecimals={2}
                    />
                  )
                })}
                {masterComponentRate.mrRates.map((mrRate) => {
                  return (
                    <NumberCellWithLabel
                      key={`${masterComponentRate.masterComponentSerialNumber}-mr-${mrRate.checkType}-fund`}
                      label={`Current ${CheckTypesDisplay(mrRate.checkType)} MR Fund`}
                      info={Number(mrRate.rateFund)}
                      className="w-42"
                      displayType={NumberDisplayType.CurrencyRounded}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        ))}
      </ErrorBoundary>
    </div>
  )
}

export { ReadonlyRatesParameters }
