import { type EngineLifeLimitedPart } from '@flyward/assets/models'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { llpTechnicalColumns } from './constants'
import { GenericTable } from '@flyward/platform/components/Table/GenericTable'

interface ILLPTechnicalTabProps {
  engineLifeLimitedParts: EngineLifeLimitedPart[]
}

const LLPTechnicalTab = ({ engineLifeLimitedParts }: ILLPTechnicalTabProps) => {
  const llpTechnicalTable = useReactTable<EngineLifeLimitedPart>({
    data: engineLifeLimitedParts,
    columns: llpTechnicalColumns,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection: false,
  })

  return <GenericTable {...llpTechnicalTable} />
}

export { LLPTechnicalTab }
export type { ILLPTechnicalTabProps }
