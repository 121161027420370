import { APIRoutes } from '@flyward/platform/constants'
import { flywardApi } from '@flyward/platform/store/slices/api/flywardApi/flywardApiSlice'
import {
  type IGetLandingGearMaintenanceProgramsQueryResponse,
  type IGetLandingGearMaintenanceProgramDetailsQueryRequest,
  type IGetLandingGearMaintenanceProgramDetailsQueryResponse,
} from './types'

const landingGearMaintenanceProgramsApi = flywardApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllLandingGearMaintenancePrograms: builder.query<IGetLandingGearMaintenanceProgramsQueryResponse, boolean>({
      query: (includeInactive) => ({
        url: APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController.LandingGears.GetAllLandingGearMaintenancePrograms(),
        method: 'GET',
        params: { includeInactive },
      }),
      providesTags: ['landingGearMaintenancePrograms'],
    }),
    getLandingGearMaintenanceProgramDetails: builder.query<
      IGetLandingGearMaintenanceProgramDetailsQueryResponse,
      IGetLandingGearMaintenanceProgramDetailsQueryRequest
    >({
      query: ({ programId }) => ({
        url: APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController.LandingGears.GetSingleLandingGearProgram(programId),
        method: 'GET',
      }),
      providesTags: (_, __, request) => [{ type: 'landingGearMaintenancePrograms', id: request.programId }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAllLandingGearMaintenanceProgramsQuery,
  useGetLandingGearMaintenanceProgramDetailsQuery,
  util: landingGearMaintenanceProgramsApiSliceUtil,
} = landingGearMaintenanceProgramsApi

type Endpoints = typeof landingGearMaintenanceProgramsApi.endpoints
type InitiateActions = {
  [K in keyof Endpoints]: ReturnType<ReturnType<Endpoints[K]['initiate']>>
}
export type TLandingGearMaintenanceProgramsApiActions = InitiateActions[keyof InitiateActions]
