import { AssetType, Label, RadioGroup, RadioGroupItem } from '@flyward/platform'

interface IAssetTypeFilterProps {
  assetType: AssetType | undefined
  setAssetType: (assetType: AssetType | undefined) => void
}

export const AssetTypeFilter = ({ assetType = undefined, setAssetType }: IAssetTypeFilterProps) => {
  return (
    <div className="flex">
      <RadioGroup
        defaultValue={assetType?.toString() ?? 'all'}
        className="flex"
        onValueChange={(value) => {
          if (value === 'all') {
            setAssetType(undefined)
          } else {
            setAssetType(value as unknown as AssetType)
          }
        }}
      >
        <div className="flex items-center space-x-2">
          <RadioGroupItem value="all" id="all" />
          <Label htmlFor="all">All</Label>
        </div>
        <div className="flex items-center space-x-2">
          <RadioGroupItem value={AssetType.Aircraft.toString()} id="option-two" />
          <Label htmlFor="option-two">Aircraft</Label>
        </div>
        <div className="flex items-center space-x-2">
          <RadioGroupItem value={AssetType.StandaloneEngine.toString()} id="option-two" />
          <Label htmlFor="option-two">Standalone Engine</Label>
        </div>
      </RadioGroup>
    </div>
  )
}
