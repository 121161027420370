import type { AircraftMaintenancePrograms } from '@flyward/assets/models'
import { Combobox } from '@flyward/platform'
import { isNil } from 'lodash'

export const EditMaintenanceProgram = ({
  assignedAirframeProgramId,
  assignedAPUProgramId,
  assignedEngineProgramId,
  assignedLandingGearProgramId,
  availablePrograms,
  changePrograms,
}: AircraftMaintenancePrograms) => {
  return (
    <div className="flex w-full gap-x-6">
      <Combobox
        items={availablePrograms.availableAirframePrograms}
        id="airframeProgram"
        itemLabel="Airframe Program"
        className="basis-3/12"
        label="Assign Airframe Program"
        selectedValues={isNil(assignedAirframeProgramId) ? [] : [assignedAirframeProgramId?.toString()]}
        setSelectedValues={(value: string[]) => {
          changePrograms.changeAirframeProgram(value[0])
        }}
      />
      <Combobox
        items={availablePrograms.availableEnginePrograms}
        id="engineProgram"
        itemLabel="Engine Program"
        className="basis-3/12"
        label="Assign Engine Program"
        selectedValues={isNil(assignedEngineProgramId) ? [] : [assignedEngineProgramId?.toString()]}
        setSelectedValues={(value: string[]) => {
          changePrograms.changeEngineProgram(value[0])
        }}
      />
      <Combobox
        items={availablePrograms.availableLandingGearPrograms}
        id="landingGearProgram"
        itemLabel="Landing Gear Program"
        className="basis-3/12"
        label="Assign Landing Gear Program"
        selectedValues={isNil(assignedLandingGearProgramId) ? [] : [assignedLandingGearProgramId?.toString()]}
        setSelectedValues={(value: string[]) => {
          changePrograms.changeLandingGearProgram(value[0])
        }}
      />
      <Combobox
        items={availablePrograms.availableAuxiliaryPowerUnitPrograms}
        id="apuProgram"
        itemLabel="APU Program"
        className="basis-3/12"
        label="Assign APU Program"
        selectedValues={isNil(assignedAPUProgramId) ? [] : [assignedAPUProgramId?.toString()]}
        setSelectedValues={(value: string[]) => {
          changePrograms.changeAuxiliaryPowerUnitProgram(value[0])
        }}
      />
    </div>
  )
}
