import { AuxiliaryPowerUnitChecksTable, BasicAssumptionsDisplay, InflationDisplay, MaintenanceProgramDetailsArea } from '@flyward/knowledgeBase'
import { PageContainer } from '@flyward/main-app/layout'
import {
  type IButtonProps,
  type IInputProps,
  ButtonVariant,
  ErrorBoundary,
  Input,
  InputType,
  Size,
  useHeaderContent,
  useNavigationState,
  useSpinnerState,
} from '@flyward/platform'
import { useGetAuxiliaryPowerUnitMaintenanceProgramDetailsQuery } from '@flyward/platform/store'
import { isEmpty, isNil } from 'lodash'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

export const AuxiliaryPowerUnitMaintenanceProgramPage = () => {
  const { id } = useParams()
  const { setTitle, setInputs, setButtons, setHasBackButton } = useHeaderContent()
  const { toggleVisibility } = useNavigationState()
  const { showSpinner, hideSpinner } = useSpinnerState()

  const { data: auxiliaryPowerUnitMaintenanceProgram, isLoading } = useGetAuxiliaryPowerUnitMaintenanceProgramDetailsQuery(
    { programId: id! },
    {
      skip: isNil(id) || isEmpty(id),
    },
  )

  useEffect(() => {
    const buttonProps: IButtonProps[] = [
      {
        variant: ButtonVariant.Primary,
        size: Size.Medium,
        label: 'Save',
        onClick: () => {},
      },
    ]

    const inputProps: IInputProps[] = [
      {
        label: 'Base year',
        controlledValue: (auxiliaryPowerUnitMaintenanceProgram?.kbReferenceYear ?? 0).toString(),
        setValueAfterValidation: () => {},
        type: InputType.NaturalNumber,
        inputClassName: 'w-20',
      },
    ]

    toggleVisibility()
    setTitle('APU Maintenance Program')
    setButtons(buttonProps)
    setHasBackButton(true)
    setInputs(inputProps)

    return () => {
      setTitle('')
      setButtons([])
      setHasBackButton(false)
      setInputs([])
      toggleVisibility()
    }
  }, [setButtons, setTitle, setInputs, setHasBackButton, auxiliaryPowerUnitMaintenanceProgram?.kbReferenceYear, toggleVisibility])

  isLoading ? showSpinner() : hideSpinner()

  return (
    <ErrorBoundary>
      {!isNil(auxiliaryPowerUnitMaintenanceProgram) && (
        <PageContainer>
          <MaintenanceProgramDetailsArea title="APU Information">
            <div className="flex gap-6">
              <Input controlledValue={auxiliaryPowerUnitMaintenanceProgram?.originalEquipmentManufacturer} label="Manufacturer" />
              <Input controlledValue={auxiliaryPowerUnitMaintenanceProgram?.compatibleComponentModels?.join(', ')} label="APU Model" />
            </div>
            <AuxiliaryPowerUnitChecksTable canAddNewRow checks={[auxiliaryPowerUnitMaintenanceProgram?.check]} />
          </MaintenanceProgramDetailsArea>
          <MaintenanceProgramDetailsArea title="Inflation">
            <InflationDisplay inflation={auxiliaryPowerUnitMaintenanceProgram?.inflation} />
          </MaintenanceProgramDetailsArea>
          <MaintenanceProgramDetailsArea title="Basic Assumptions">
            <BasicAssumptionsDisplay basicAssumption={auxiliaryPowerUnitMaintenanceProgram?.basicAssumptions} />
          </MaintenanceProgramDetailsArea>
        </PageContainer>
      )}
    </ErrorBoundary>
  )
}
