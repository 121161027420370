import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { generateColumnsForGroupedComponents, getCommonPinningStyles } from './customStatisticsColumns'
import { flexRender, getCoreRowModel, getExpandedRowModel, useReactTable } from '@tanstack/react-table'
import { type MonthlyStatistics, CheckTypes, cn, type GroupedComponentMonthlyStatistics } from '@flyward/platform'
import { CustomStatisticsExpandedTable } from './Expandable/CustomStatisticsExpandedTable'
import { CustomLLPTableExpandableRow } from './Expandable/CustomLLPTableExpandableRow'
import { CustomLLPMileageTableExpandableRow } from './Expandable/Mileage'

interface ICustomStatisticsTableProps {
  componentsMonthlyStatistics: GroupedComponentMonthlyStatistics[]
  propertyName: keyof MonthlyStatistics
}

const lastIndexOfEpr = (array: GroupedComponentMonthlyStatistics[]) => {
  for (let i = array.length - 1; i >= 0; i--) {
    if (array[i].forecastedComponent.checkType === CheckTypes.EnginePerformanceRestoration) {
      return i
    }
  }
  return -1
}

export const CustomStatisticsTable = ({ componentsMonthlyStatistics, propertyName: columnName }: Readonly<ICustomStatisticsTableProps>) => {
  const columns = useMemo(() => {
    if (componentsMonthlyStatistics.length === 0) {
      return []
    }
    return generateColumnsForGroupedComponents(columnName, componentsMonthlyStatistics[0])
  }, [columnName, componentsMonthlyStatistics])

  const [tableWidth, setTableWidth] = useState<number | undefined>(undefined)
  const tableContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (tableContainerRef.current != null) {
      const observer = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.contentRect != null) {
            setTableWidth(entry.contentRect.width)
          }
        }
      })

      const currentRef = tableContainerRef.current
      observer.observe(currentRef)

      return () => {
        if (currentRef != null) {
          observer.unobserve(currentRef)
        }
      }
    }
  }, [tableContainerRef])

  const table = useReactTable<GroupedComponentMonthlyStatistics>({
    data: componentsMonthlyStatistics,
    columns,
    initialState: {
      columnPinning: { left: ['forecastedComponent-checkType'] },
    },
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    enableMultiRowSelection: false,
  })

  const indexOfLastEpr = lastIndexOfEpr(componentsMonthlyStatistics)

  const columnWidth = 32 * 16
  const headerColumnSpan = tableWidth !== undefined ? Math.ceil(tableWidth / columnWidth) + 2 : 10

  return (
    <div className="relative block w-full overflow-auto" ref={tableContainerRef}>
      <table className="grid w-full">
        <thead className="sticky top-0 z-20 grid bg-black-0 p-0">
          {table.getHeaderGroups().map((headerGroup) => (
            <Fragment key={headerGroup.id}>
              <tr key={headerGroup.id} className="sticky z-20 m-0 flex w-full border-b border-primary-light-2 p-0">
                {headerGroup.headers.map((header, index) => {
                  const { column } = header

                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className={`m-0 flex p-0 ${index === 0 ? 'sticky left-0 z-[25] bg-black-0' : ''}`}
                      style={{ ...getCommonPinningStyles(column, false) }}
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          className={cn(
                            'items-center justify-end py-2 pr-3',
                            'bg-black-0 text-right text-xs font-semibold text-primary-dark-1',
                            `${index === 0 ? 'w-40 max-w-40' : 'w-32 max-w-32'}`,
                          )}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </div>
                      )}
                    </th>
                  )
                })}
              </tr>
            </Fragment>
          ))}
        </thead>

        <tbody>
          {table.getRowModel().rows.map((row, rowIndex) => {
            const componentId = row.original.forecastedComponent.componentId
            const checkType = row.original.forecastedComponent.checkType
            const visibleCells = row.getVisibleCells()
            const columnSpan = visibleCells.length
            const firstColumn = columnSpan > 0 ? visibleCells[0]?.column : undefined

            const isEnginePerformanceRestoration = row.original.forecastedComponent.checkType === CheckTypes.EnginePerformanceRestoration

            return (
              <Fragment key={`${componentId}-${checkType}-container`}>
                {isEnginePerformanceRestoration && (
                  <tr key={`${componentId}-${checkType}-header`} className="flex w-full border-b border-black-10">
                    <td
                      key={`${componentId}-${checkType}-epr-header`}
                      colSpan={headerColumnSpan}
                      className="text-center text-xs font-semibold text-text-1"
                      style={{ ...getCommonPinningStyles(firstColumn, false) }}
                    >
                      <div style={{ width: tableWidth }}>{row.original.forecastedComponent.componentSerialNumber}</div>
                    </td>
                  </tr>
                )}

                {rowIndex > indexOfLastEpr && checkType !== CheckTypes.EngineLifeLimitedPartReplacement ? (
                  <tr key={`${componentId}-${checkType}-header-blank`} className="flex h-2 w-full border-b border-black-10 bg-black-5" />
                ) : null}

                {checkType === CheckTypes.EngineLifeLimitedPartReplacement ? (
                  columnName === 'forecastedMileage' ? (
                    <CustomLLPMileageTableExpandableRow
                      key={`${componentId}-${checkType}-expander`}
                      row={row}
                      expandedComponent={CustomStatisticsExpandedTable}
                      propertyName={columnName}
                    />
                  ) : (
                    <CustomLLPTableExpandableRow
                      key={`${componentId}-${checkType}-expander`}
                      row={row}
                      expandedComponent={CustomStatisticsExpandedTable}
                      propertyName={columnName}
                    />
                  )
                ) : (
                  <tr key={`${componentId}-${checkType}-parent`} className="m-0 flex w-full">
                    {row.getVisibleCells().map(({ id, column, getContext }, index) => {
                      return (
                        <td
                          key={id}
                          className={cn(
                            'flex h-7 items-center border-b border-black-10',
                            'bg-black-0 pl-4 pr-2 text-xs',
                            `${
                              index === 0
                                ? 'sticky left-0 z-10 w-40 max-w-40 font-semibold text-text-1'
                                : 'w-32 max-w-32 justify-end text-right text-text-2'
                            }`,
                          )}
                          style={{ ...getCommonPinningStyles(column) }}
                        >
                          {flexRender(column.columnDef.cell, getContext())}
                        </td>
                      )
                    })}
                  </tr>
                )}
              </Fragment>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
