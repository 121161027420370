import { NumberDisplay, NumberDisplayType, type UtilizationUnits, UtilizationUnitsDisplay, type TableColumn } from '@flyward/platform'
import { MaintenanceProgramDetailsTable } from '../MaintenanceProgramDetailsTable'
import { type EnginePerformanceRestorationMaintenanceProgram, CheckLimitType } from '../../../models'

interface EnginePerformanceRestorationMaintenanceProgramDisplay {
  programId: string
  eventType: string
  unit?: UtilizationUnits
  firstRunLimit?: number
  matureLimit?: number
  baseCost?: number
}

interface IEnginePerformanceRestorationTableProps {
  enginePerformanceRestorationMaintenanceProgram: EnginePerformanceRestorationMaintenanceProgram | undefined
}
const ePRTableColumns: Array<TableColumn<EnginePerformanceRestorationMaintenanceProgramDisplay>> = [
  {
    accessor: 'programId',
    isIdField: true,
    title: '',
    isHidden: true,
  },
  {
    accessor: 'eventType',
    title: 'Event Type',
  },
  {
    accessor: 'unit',
    title: 'Unit',
    valueGetter: (value) => UtilizationUnitsDisplay(value as UtilizationUnits),
  },
  {
    accessor: 'firstRunLimit',
    title: 'First Run Limit',
    valueGetter: (value) => <NumberDisplay value={value} />,
  },
  {
    accessor: 'matureLimit',
    title: 'Mature Limit',
    valueGetter: (value) => <NumberDisplay value={value} />,
  },
  {
    accessor: 'baseCost',
    title: 'Cost',
    valueGetter: (value) => <NumberDisplay displayType={NumberDisplayType.CurrencyRounded} value={value} />,
  },
]

export const EnginePerformanceRestorationTable = ({
  enginePerformanceRestorationMaintenanceProgram,
}: IEnginePerformanceRestorationTableProps): React.ReactElement<IEnginePerformanceRestorationTableProps> => {
  const limits = enginePerformanceRestorationMaintenanceProgram?.check?.enginePerformanceRestorationCheckLimits

  const eprTableData: EnginePerformanceRestorationMaintenanceProgramDisplay | undefined =
    enginePerformanceRestorationMaintenanceProgram !== undefined
      ? {
          eventType: 'EPR',
          programId: enginePerformanceRestorationMaintenanceProgram.enginePerformanceRestorationkbProgramId,
          unit: enginePerformanceRestorationMaintenanceProgram.check?.utilizationUnit,
          firstRunLimit: limits?.find((t) => t.checkLimitType === CheckLimitType.FirstRun)?.utilization,
          matureLimit: limits?.find((t) => t.checkLimitType === CheckLimitType.Mature)?.utilization,
          baseCost: enginePerformanceRestorationMaintenanceProgram.check?.baseCost,
        }
      : undefined

  return <MaintenanceProgramDetailsTable columns={ePRTableColumns} data={[eprTableData]} canAddNewRow />
}
