export enum CheckTypes {
  EnginePerformanceRestoration = 1,
  EngineLifeLimitedPartReplacement = 2,
  LandingGearOverhaul = 4,
  AuxiliaryPowerUnitPerformanceRestoration = 5,
  AuxiliaryPowerUnitLifeLimitedPartReplacement = 6,

  // number of months
  Airframe3y = 36,
  Airframe6y = 72,
  Airframe9y = 108,
  Airframe12y = 144,
  Airframe18y = 216,
  Airframe24y = 288,
}

export const CheckTypesDisplay = (value: CheckTypes) => {
  switch (value) {
    case CheckTypes.EnginePerformanceRestoration:
      return 'PR'
    case CheckTypes.EngineLifeLimitedPartReplacement:
      return 'LLP'
    case CheckTypes.LandingGearOverhaul:
      return 'Overhaul'
    case CheckTypes.AuxiliaryPowerUnitPerformanceRestoration:
      return 'PR'
    case CheckTypes.AuxiliaryPowerUnitLifeLimitedPartReplacement:
      return 'LLP'
    case CheckTypes.Airframe3y:
      return '3y'
    case CheckTypes.Airframe6y:
      return '6Y'
    case CheckTypes.Airframe9y:
      return '9y'
    case CheckTypes.Airframe12y:
      return '12Y'
    case CheckTypes.Airframe18y:
      return '18y'
    case CheckTypes.Airframe24y:
      return '24y'
  }
}

export const CheckTypesTableDisplay = (value: CheckTypes) => {
  switch (value) {
    case CheckTypes.EnginePerformanceRestoration:
      return 'PR'
    case CheckTypes.EngineLifeLimitedPartReplacement:
      return 'LLP'
    case CheckTypes.LandingGearOverhaul:
      return 'LG'
    case CheckTypes.AuxiliaryPowerUnitPerformanceRestoration:
      return 'APU'
    case CheckTypes.AuxiliaryPowerUnitLifeLimitedPartReplacement:
      return 'LLP'
    case CheckTypes.Airframe3y:
      return '3y'
    case CheckTypes.Airframe6y:
      return '6y'
    case CheckTypes.Airframe9y:
      return '9y'
    case CheckTypes.Airframe12y:
      return '12y'
    case CheckTypes.Airframe18y:
      return '18y'
    case CheckTypes.Airframe24y:
      return '24y'
  }
}

export enum ComponentTypesAPIURIs {
  Airframe = 'airframes',
  EngineUnit = 'engine-units',
  AuxiliaryPowerUnit = 'auxiliary-power-units',
  EnginePerformanceRestoration = 'engine-performance-restorations',
  EngineLifeLimitedPart = 'engine-llps',
  LandingGear = 'landing-gears',
  ThrustReversers = 'thrust-reversers',
}

export const getEngineChecks = (): CheckTypes[] => {
  return [CheckTypes.EnginePerformanceRestoration, CheckTypes.EngineLifeLimitedPartReplacement]
}

export const isEngineCheck = (checkType: CheckTypes): boolean => {
  return getEngineChecks().includes(checkType)
}
