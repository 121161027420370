import { Size } from '../common'
import { Icon } from '../Icon'
import { Toast, ToastClose, ToastDescription, ToastProvider, ToastTitle, ToastViewport } from './toast'
import { useToast } from './use-toast'

// TODO: export this to ui-molecules as well and then to components so that we can use the Icon
export function Toaster() {
  const { toasts } = useToast()

  return (
    <ToastProvider>
      {toasts.map(function ({ id, title, description, icon, action, ...props }) {
        return (
          <Toast key={id} {...props}>
            <div className="grid gap-1">
              {title && <ToastTitle>{title}</ToastTitle>}
              <div className="flex">
                {description && (
                  <ToastDescription className="flex text-base font-semibold">
                    {icon !== undefined && <Icon variant={icon} size={Size.Medium} className="mr-2" />}
                    {description}
                  </ToastDescription>
                )}
              </div>
            </div>
            {action}
            <ToastClose />
          </Toast>
        )
      })}
      <ToastViewport />
    </ToastProvider>
  )
}
