import { Role } from '@flyward/platform/models'
import { type LoggedInUserDto } from '@flyward/platform/models/DTOs/LoggedInUserDto'
import React, { useMemo, type PropsWithChildren } from 'react'

interface UserStateContextType {
  user: LoggedInUserDto | null
}

const UserStateContext = React.createContext<UserStateContextType>({ user: null })

const UserStateContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const value = useMemo(
    (): UserStateContextType => ({
      user: {
        id: '123456',
        email: 'johndoe@example.com',
        fullName: 'John Doe',
        role: Role.None,
      },
    }),
    [],
  )

  return <UserStateContext.Provider value={value}>{children}</UserStateContext.Provider>
}

const useUser = () => React.useContext(UserStateContext)
export { UserStateContextProvider, useUser as useUserState }
