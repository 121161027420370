import { CheckTypesDisplay, isArrayEmptyOrNull, NumberCellWithLabel, NumberDisplayType, TextCellWithLabel } from '@flyward/platform'
import { Fragment } from 'react'
import { type ComponentEndOfLeaseRate, TypeOfEOLAdjustmentDisplay } from '../../../../../models'
import { cloneDeep } from 'lodash'

interface IEndOfLeaseRatesProps {
  rates: ComponentEndOfLeaseRate[]
  isEolApplicable: boolean
}

export const EndOfLeaseRates = ({ rates, isEolApplicable }: IEndOfLeaseRatesProps): React.ReactElement<IEndOfLeaseRatesProps> => {
  if (isArrayEmptyOrNull(rates)) {
    return (
      <Fragment>
        <TextCellWithLabel className="basis-1/6" label={`EOL Rate`} preventInfo={!isEolApplicable} />
        <TextCellWithLabel className="basis-1/6" label={`Type of EOL Adjustment`} preventInfo={!isEolApplicable} />
      </Fragment>
    )
  }

  cloneDeep(rates).sort((a, b) => Number(a.checkType) - Number(b.checkType))

  const eolRates = rates.map((eolRate: ComponentEndOfLeaseRate) => {
    return (
      <Fragment key={`${eolRate.id} - ${eolRate.checkType}`}>
        <NumberCellWithLabel
          className="basis-1/6"
          label={`EOL Rates ${CheckTypesDisplay(eolRate?.checkType)}`}
          info={eolRate?.rateAmount}
          preventInfo={!isEolApplicable}
          displayType={NumberDisplayType.Currency}
        />
        <TextCellWithLabel
          className="basis-1/6"
          label={`Type of EOL Adjustment ${CheckTypesDisplay(eolRate.checkType)}`}
          info={TypeOfEOLAdjustmentDisplay(eolRate.typeOfEOLAdjustment)}
          preventInfo={!isEolApplicable}
        />
      </Fragment>
    )
  })

  return <>{eolRates}</>
}
