import { Input, InputType } from '@flyward/platform'
import { type StubLife } from '../../../models'

interface IStubLifeDisplayProps {
  stubLife: StubLife | undefined
}

export const StubLifeDisplay = ({ stubLife }: IStubLifeDisplayProps): React.ReactElement<IStubLifeDisplayProps> => {
  return (
    <div className="flex gap-6">
      <Input controlledValue={stubLife?.intervalStart.toString()} type={InputType.Percentage} />
      <Input controlledValue={stubLife?.intervalEnd.toString()} type={InputType.Percentage} />
    </div>
  )
}
