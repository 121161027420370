import { APIRoutes } from '@flyward/platform/constants'
import { flywardApi } from '@flyward/platform/store/slices/api/flywardApi/flywardApiSlice'
import {
  type IGetAllAuxiliaryPowerUnitsByAssetIdQueryResponse,
  type IGetAllAuxiliaryPowerUnitsByAssetIdQueryRequest,
  type IPutAuxiliaryPowerUnitsMaintenanceProgramMutationRequest,
  type IGetAllAuxiliaryPowerUnitChecksWithDetailsByAssetIdQueryResponse,
  type IGetAllAuxiliaryPowerUnitChecksWithDetailsByAssetIdQueryRequest,
} from './types'
import { ComponentCategory, ComponentTypeURI } from '@flyward/platform'

const auxiliaryPowerUnitsApi = flywardApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllAuxiliaryPowerUnitsByAssetId: builder.query<
      IGetAllAuxiliaryPowerUnitsByAssetIdQueryResponse,
      IGetAllAuxiliaryPowerUnitsByAssetIdQueryRequest
    >({
      query: ({ assetId }) => ({
        url: APIRoutes.AssetsModule.Components.AuxiliaryPowerUnitsController.GetAll(assetId),
        method: 'GET',
      }),
      providesTags: (_, __, request) => [{ type: 'auxiliaryPowerUnits', id: request.assetId }],
    }),
    getAllAuxiliaryPowerUnitChecksWithDetailsByAssetId: builder.query<
      IGetAllAuxiliaryPowerUnitChecksWithDetailsByAssetIdQueryResponse,
      IGetAllAuxiliaryPowerUnitChecksWithDetailsByAssetIdQueryRequest
    >({
      query: ({ assetId }) => ({
        url: APIRoutes.AssetsModule.Components.AuxiliaryPowerUnitsController.GetAllChecksWithDetails(assetId),
        method: 'GET',
      }),
      providesTags: (_, __, request) => [
        { type: 'componentChecks', id: `${request.assetId}-${ComponentTypeURI(ComponentCategory.AuxiliaryPowerUnit)}` },
      ],
    }),
    updateAuxiliaryPowerUnitsMaintenanceProgram: builder.mutation<void, IPutAuxiliaryPowerUnitsMaintenanceProgramMutationRequest>({
      query: ({ assetId, kbProgramId }) => ({
        url: APIRoutes.AssetsModule.Components.AuxiliaryPowerUnitsController.MaintenanceProgram(assetId),
        method: 'PUT',
        data: kbProgramId,
      }),
      invalidatesTags: (_, __, request) => [{ type: 'auxiliaryPowerUnits', id: request.assetId }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAllAuxiliaryPowerUnitsByAssetIdQuery,
  useGetAllAuxiliaryPowerUnitChecksWithDetailsByAssetIdQuery,
  useUpdateAuxiliaryPowerUnitsMaintenanceProgramMutation,
  util: auxiliaryPowerUnitsApiSliceUtil,
} = auxiliaryPowerUnitsApi

type Endpoints = typeof auxiliaryPowerUnitsApi.endpoints
type InitiateActions = {
  [K in keyof Endpoints]: ReturnType<ReturnType<Endpoints[K]['initiate']>>
}
export type TAuxiliaryPowerUnitsApiActions = InitiateActions[keyof InitiateActions]
