import { APIRoutes } from '@flyward/platform/constants'
import { http, HttpResponse } from 'msw'
import { type IGetLandingGearMaintenanceProgramsQueryResponse, type IGetLandingGearMaintenanceProgramDetailsQueryResponse } from './types'
import { ComponentCategory } from '@flyward/platform/models'

const landingGearMaintenanceProgramApiMocks = [
  http.get(APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController.LandingGears.GetSingleLandingGearProgram('*'), () => {
    return HttpResponse.json<IGetLandingGearMaintenanceProgramDetailsQueryResponse>(
      {
        check: {
          componentCategory: ComponentCategory.LandingGear,
          componentModel: 'test',
          kbReferenceYear: 2024,
          masterComponentKBProgramId: 'asdasdsa',
          landingGearkbProgramId: '95b7596f-fc52-440a-a22f-49f59c92aef2',
          landingGearCheckLimit: {
            landingGearCheckId: '95b7596f-fc52-440a-a22f-49f59c92aef2',
            limitId: '5545434a-de63-437e-81eb-5eb1f4624dfa',
            utilizationUnit: 1,
            utilization: 120,
            id: 'f3b3b3b3-3b3b-3b3b-3b3b-3b3b3b3b3b3b',
          },
          checkId: '95b7596f-fc52-440a-a22f-49f59c92aef2',
          checkType: 4,
          utilizationUnit: 1,
          baseCost: 309584,
        },
        inflation: {
          kbProgramId: '95b7596f-fc52-440a-a22f-49f59c92aef2',
          componentType: ComponentCategory.Airframe,
          referenceYear: 2017,
          percentage: 3.5,
          id: '238f6119-2795-465f-9aaf-cb26699b9957',
        },
        maintenanceProgramName: 'Messier - B737-800 - 2021',
        isActive: true,
        dateOfCreation: '2024-07-10T06:50:07.5704516Z',
        masterComponentKBProgramId: '95b7596f-fc52-440a-a22f-49f59c92aef2',
        compatibleComponentModels: ['B737-800'],
        kbReferenceYear: 2021,
        originalEquipmentManufacturer: 'Messier',
        basicAssumptions: {
          checkDowntimeMonths: 2,
          monthsDelayInPaymentOnClaims: 1,
          kbProgramId: '38bb5312-9440-4b52-98e6-ab51674af1af',
        },
        createdAt: '2024-07-15T12:50:54.8906672+00:00',
        lastModifiedAt: '2024-07-15T12:50:54.8906672+00:00',
      },
      {
        status: 200,
      },
    )
  }),

  http.get(APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController.LandingGears.GetAllLandingGearMaintenancePrograms(), () => {
    return HttpResponse.json<IGetLandingGearMaintenanceProgramsQueryResponse>([
      {
        inflation: {
          id: '238f6119-2795-465f-9aaf-cb26699b9957',
          kbProgramId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
          percentage: 200,
          referenceYear: 2017,
          componentType: ComponentCategory.Airframe,
        },
        basicAssumptions: {
          kbProgramId: '38bb5312-9440-4b52-98e6-ab51674af1af',
          checkDowntimeMonths: 12,
          monthsDelayInPaymentOnClaims: 33,
        },
        maintenanceProgramName: 'Messier - B737-800 - 2021',
        isActive: true,
        dateOfCreation: '2024-07-10T06:50:07.5704516Z',
        masterComponentKBProgramId: '95b7596f-fc52-440a-a22f-49f59c92aef2',
        compatibleComponentModels: ['B737-800'],
        kbReferenceYear: 2021,
        originalEquipmentManufacturer: 'Messier',
        createdAt: '2024-07-16T08:35:33.1117158+00:00',
        lastModifiedAt: '2024-07-16T08:35:33.1117159+00:00',
      },
    ])
  }),
]

export { landingGearMaintenanceProgramApiMocks }
