import { Button, ButtonVariant, Combobox, Dialog, getAssetAge, IconVariant, Size } from '@flyward/platform'
import { useState } from 'react'
import { type AssetSearchDTO } from '../../models/AssetSearchDTO'
import { type FleetSearchInput } from '@flyward/assets/models/FleetSearchInput'
import { type IFilterData, type IFilterableData, type IFilterableDataCollection } from './FilterData'

export const initialFilterData: IFilterData = {
  msns: [],
  lessees: [],
  aircraftModels: [],
  engineTypes: [],
  aircraftAges: [],
  redeliveryDates: [],
  regions: [],
  portfolio: [],
  selectedMsn: [],
  selectedLessee: [],
  selectedAircraftModel: [],
  selectedEngineType: [],
  selectedAircraftAge: [],
  selectedRedeliveryDate: [],
  selectedRegions: [],
  selectedPortfolio: [],
}

export const filterAssets = (assets: AssetSearchDTO[], filters: IFilterData) => {
  const filteredAircrafts = assets.filter((aircraft: AssetSearchDTO) => {
    return (
      (filters.selectedMsn.length === 0 || filters.selectedMsn.includes(aircraft.serialNumber)) &&
      (filters.selectedLessee.length === 0 || filters.selectedLessee.includes(aircraft.lessee)) &&
      (filters.selectedAircraftModel.length === 0 || filters.selectedAircraftModel.includes(aircraft.aircraftModel)) &&
      (filters.selectedEngineType.length === 0 || filters.selectedEngineType.includes(aircraft.engineModel)) &&
      (filters.selectedAircraftAge.length === 0 || filters.selectedAircraftAge.includes(aircraft.dom)) &&
      (filters.selectedRedeliveryDate.length === 0 || filters.selectedRedeliveryDate.includes(aircraft.redeliveryDate)) &&
      (filters.selectedRegions.length === 0 || filters.selectedRegions.includes(aircraft.region)) &&
      (filters.selectedPortfolio.length === 0 || filters.selectedPortfolio.includes(aircraft.portfolio))
    )
  })

  return filteredAircrafts
}

export const updateFilterSelections = (newFilters: IFilterData, prevFilters: IFilterData) => {
  const selections = {
    selectedMsn: prevFilters.selectedMsn,
    selectedLessee: prevFilters.selectedLessee,
    selectedAircraftModel: prevFilters.selectedAircraftModel,
    selectedEngineType: prevFilters.selectedEngineType,
    selectedAircraftAge: prevFilters.selectedAircraftAge,
    selectedRedeliveryDate: prevFilters.selectedRedeliveryDate,
    selectedRegions: prevFilters.selectedRegions,
    selectedPortfolio: prevFilters.selectedPortfolio,
  }

  return {
    ...newFilters,
    ...selections,
  }
}

export interface IAssetFilterDialogProps {
  applyFilter: (filterData: IFilterData) => void
  filterData: IFilterData
  availableFilterOptions: IFilterableDataCollection
  filter: FleetSearchInput
  onFilterUpdate: (filter: Partial<FleetSearchInput>) => void
}

export const AssetFilterDialog = ({
  applyFilter: applyFilterExternal,
  filterData: filterDataProps,
  availableFilterOptions,
  filter,
  onFilterUpdate,
}: IAssetFilterDialogProps) => {
  const [filterDataCombobox, setFilterDataCombobox] = useState<IFilterData>(filterDataProps)

  const triggerButton = ({ onClick = () => {} }) => {
    const onClickHandler = () => {
      setFilterDataCombobox(filterDataProps)
      onClick()
    }

    return <Button variant={ButtonVariant.Secondary} size={Size.Small} label="Filter" onClick={onClickHandler} leftIcon={IconVariant.FilterList} />
  }

  const resetFilterData = () => {
    setFilterDataCombobox((prevData: IFilterData) => {
      return {
        ...prevData,
        selectedMsn: [],
        selectedLessee: [],
        selectedAircraftModel: [],
        selectedEngineType: [],
        selectedAircraftAge: [],
        selectedRedeliveryDate: [],
        selectedRegions: [],
        selectedPortfolio: [],
      }
    })
  }

  const applyFilter = () => {
    applyFilterExternal(filterDataCombobox)
    onFilterUpdate({ ...filter, filterData: filterDataCombobox })
  }

  const onSelectedFilterChanged = (values: string[], key: string) => {
    setFilterDataCombobox((prev: IFilterData) => ({ ...prev, [key]: values }))
  }

  const getAircraftAges = (aircraftsDOM: IFilterableData[]) => {
    const aircraftAges: IFilterableData[] = []
    aircraftsDOM.forEach((el) => {
      const newAircraftAge: IFilterableData = { label: getAssetAge(el.value), value: el.value }
      aircraftAges.push(newAircraftAge)
    })
    return aircraftAges
  }

  return (
    <Dialog
      contentClassName="w-150"
      triggerButton={triggerButton}
      headerActions={<Button variant={ButtonVariant.Ghost} label="Reset All" onClick={resetFilterData} className="ml-auto font-semibold" />}
      footerActions={[
        {
          variant: ButtonVariant.Secondary,
          size: Size.Small,
          label: 'Cancel',
          onClick: resetFilterData,
          isClose: true,
        },
        {
          variant: ButtonVariant.Primary,
          size: Size.Small,
          label: 'Apply',
          onClick: applyFilter,
          isClose: true,
        },
      ]}
    >
      <div className="my-4 grid grid-cols-2 gap-x-6 gap-y-10">
        <Combobox
          className="!w-67 gap-y-2"
          isMultiselect
          items={availableFilterOptions.msns}
          itemLabel="MSN/ESN"
          label="MSN/ESN"
          labelClassName="!text-base font-semibold text-text-1"
          selectedValues={filterDataCombobox.selectedMsn}
          setSelectedValues={(values: string[]) => {
            onSelectedFilterChanged(values, 'selectedMsn')
          }}
        />
        <Combobox
          className="!w-67 gap-y-2"
          isMultiselect
          items={availableFilterOptions.lessees}
          itemLabel="Lessee"
          label="Lessee"
          labelClassName="!text-base font-semibold text-text-1"
          selectedValues={filterDataCombobox.selectedLessee}
          setSelectedValues={(values: string[]) => {
            onSelectedFilterChanged(values, 'selectedLessee')
          }}
        />
        <Combobox
          className="!w-67 gap-y-2"
          isMultiselect
          items={availableFilterOptions.aircraftModels}
          itemLabel="Aircraft Model"
          label="Aircraft Model"
          labelClassName="!text-base font-semibold text-text-1"
          selectedValues={filterDataCombobox.selectedAircraftModel}
          setSelectedValues={(values: string[]) => {
            onSelectedFilterChanged(values, 'selectedAircraftModel')
          }}
        />
        <Combobox
          className="!w-67 gap-y-2"
          isMultiselect
          items={availableFilterOptions.engineTypes}
          itemLabel="Engine Type"
          label="Engine Type"
          labelClassName="!text-base font-semibold text-text-1"
          selectedValues={filterDataCombobox.selectedEngineType}
          setSelectedValues={(value: string[]) => {
            setFilterDataCombobox((prev: IFilterData) => ({ ...prev, selectedEngineType: value }))
          }}
        />
        <Combobox
          className="!w-67 gap-y-2"
          isMultiselect
          items={getAircraftAges(availableFilterOptions.aircraftAges)}
          itemLabel="Aircraft Age"
          label="Aircraft Age"
          labelClassName="!text-base font-semibold text-text-1"
          selectedValues={filterDataCombobox.selectedAircraftAge}
          setSelectedValues={(values: string[]) => {
            onSelectedFilterChanged(values, 'selectedAircraftAge')
          }}
        />
        <Combobox
          className="!w-67 gap-y-2"
          isMultiselect
          items={availableFilterOptions.redeliveryDates}
          itemLabel="Redelivery Date"
          label="Redelivery Date"
          labelClassName="!text-base font-semibold text-text-1"
          selectedValues={filterDataCombobox.selectedRedeliveryDate}
          setSelectedValues={(values: string[]) => {
            onSelectedFilterChanged(values, 'selectedRedeliveryDate')
          }}
        />
        <Combobox
          className="!w-67 gap-y-2"
          isMultiselect
          items={availableFilterOptions.regions}
          itemLabel="Region"
          label="Region"
          labelClassName="!text-base font-semibold text-text-1"
          selectedValues={filterDataCombobox.selectedRegions}
          setSelectedValues={(values: string[]) => {
            onSelectedFilterChanged(values, 'selectedRegions')
          }}
        />
        <Combobox
          className="!w-67 gap-y-2"
          isMultiselect
          items={availableFilterOptions.portfolio}
          itemLabel="Portfolio"
          label="Portfolio"
          labelClassName="!text-base font-semibold text-text-1"
          selectedValues={filterDataCombobox.selectedPortfolio}
          setSelectedValues={(values: string[]) => {
            onSelectedFilterChanged(values, 'selectedPortfolio')
          }}
        />
      </div>
    </Dialog>
  )
}
