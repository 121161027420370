import { NumberDisplay, NumberDisplayType, type TableColumn } from '@flyward/platform'
import { type DerateRatio } from '../../../models'
import { MaintenanceProgramDetailsTable } from '../MaintenanceProgramDetailsTable'
import { cloneDeep } from 'lodash'

interface IDerateRatiosTableProps {
  derateRatios: DerateRatio[] | undefined
}
const tableColumns: Array<TableColumn<DerateRatio>> = [
  {
    accessor: 'id',
    title: '',
    isIdField: true,
    isHidden: true,
  },
  {
    accessor: 'intervalStart',
    title: 'Interval start',
    valueGetter: (value) => <NumberDisplay value={value} displayType={NumberDisplayType.Percentage} />,
  },
  {
    accessor: 'intervalEnd',
    title: 'Interval end',
    valueGetter: (value) => <NumberDisplay value={value} displayType={NumberDisplayType.Percentage} />,
  },
  {
    accessor: 'percentage',
    title: 'Percentage',
    valueGetter: (value) => <NumberDisplay value={value} displayType={NumberDisplayType.Percentage} />,
  },
]
export const DerateRatiosTable = ({ derateRatios }: IDerateRatiosTableProps): React.ReactElement<IDerateRatiosTableProps> => {
  return <MaintenanceProgramDetailsTable columns={tableColumns} data={cloneDeep(derateRatios)?.sort((a, b) => a.intervalStart - b.intervalStart)} />
}
