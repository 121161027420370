import { type LandingGearCheck } from '../../../models'
import { ChecksTable } from '../ChecksTable'

interface ICyclesHoursRatioTableProps {
  checks: LandingGearCheck[] | undefined
  canAddNewRow: boolean
}

export const LandingGearChecksTable = ({
  checks,
  canAddNewRow,
}: ICyclesHoursRatioTableProps): React.ReactElement<ICyclesHoursRatioTableProps> => {
  const mappedData = checks?.map((t) => {
    return {
      checkId: t.checkId,
      checkType: t.checkType,
      utilizationUnit: t.utilizationUnit,
      utilization: t.landingGearCheckLimit.utilization,
      baseCost: t.baseCost,
    }
  })
  return <ChecksTable canAddNewRow={canAddNewRow} checks={mappedData} />
}
