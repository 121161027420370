/* eslint-disable max-len */
import * as React from 'react'

interface IProps {
  height: number
  width: number
}

export const KnowledgeBaseEmptyState = ({ height, width }: IProps): React.ReactElement<IProps> => {
  return (
    <svg width={width} height={height} viewBox="0 0 285 290" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M240.047 7.37793H2.59644V144.068H240.047V7.37793Z" fill="#FAFAFA" />
      <path
        d="M13.2032 13.6987C13.2032 14.0557 13.0973 14.4047 12.899 14.7015C12.7007 14.9984 12.4187 15.2297 12.0889 15.3663C11.7591 15.503 11.3961 15.5387 11.046 15.4691C10.6958 15.3994 10.3742 15.2275 10.1217 14.975C9.86927 14.7226 9.69735 14.401 9.6277 14.0508C9.55805 13.7007 9.5938 13.3377 9.73042 13.0079C9.86705 12.678 10.0984 12.3961 10.3953 12.1978C10.6921 11.9994 11.0411 11.8936 11.3981 11.8936C11.8769 11.8936 12.336 12.0837 12.6745 12.4223C13.013 12.7608 13.2032 13.2199 13.2032 13.6987Z"
        fill="white"
      />
      <path
        d="M19.8886 13.6987C19.8898 14.0575 19.7845 14.4086 19.5859 14.7075C19.3874 15.0063 19.1045 15.2395 18.7733 15.3774C18.442 15.5153 18.0772 15.5517 17.7253 15.482C17.3733 15.4123 17.0499 15.2397 16.7962 14.9859C16.5425 14.7322 16.3698 14.4089 16.3001 14.0569C16.2304 13.7049 16.2668 13.3401 16.4047 13.0089C16.5426 12.6776 16.7758 12.3948 17.0747 12.1962C17.3735 11.9976 17.7247 11.8923 18.0835 11.8936C18.5622 11.8936 19.0213 12.0837 19.3599 12.4223C19.6984 12.7608 19.8886 13.2199 19.8886 13.6987Z"
        fill="white"
      />
      <path
        d="M26.5729 13.6987C26.5742 14.0575 26.4688 14.4086 26.2703 14.7075C26.0717 15.0063 25.7889 15.2395 25.4576 15.3774C25.1263 15.5153 24.7616 15.5517 24.4096 15.482C24.0576 15.4123 23.7342 15.2397 23.4805 14.9859C23.2268 14.7322 23.0541 14.4089 22.9844 14.0569C22.9148 13.7049 22.9512 13.3401 23.0891 13.0089C23.227 12.6776 23.4601 12.3948 23.759 12.1962C24.0579 11.9976 24.409 11.8923 24.7678 11.8936C25.2465 11.8936 25.7057 12.0837 26.0442 12.4223C26.3827 12.7608 26.5729 13.2199 26.5729 13.6987Z"
        fill="white"
      />
      <path d="M284.81 131.809H162.465V202.233H284.81V131.809Z" fill="#F5F5F5" />
      <path
        d="M167.925 135.081C167.925 135.265 167.87 135.445 167.768 135.598C167.665 135.751 167.52 135.871 167.35 135.941C167.18 136.012 166.992 136.03 166.812 135.994C166.631 135.958 166.465 135.869 166.335 135.739C166.205 135.609 166.116 135.443 166.08 135.262C166.044 135.082 166.063 134.894 166.133 134.724C166.204 134.554 166.323 134.409 166.476 134.306C166.629 134.204 166.809 134.149 166.994 134.149C167.24 134.149 167.477 134.248 167.652 134.422C167.827 134.597 167.925 134.834 167.925 135.081Z"
        fill="white"
      />
      <path
        d="M171.37 135.081C171.37 135.265 171.315 135.445 171.213 135.598C171.111 135.751 170.965 135.871 170.795 135.941C170.625 136.012 170.438 136.03 170.257 135.994C170.076 135.958 169.91 135.869 169.78 135.739C169.65 135.609 169.561 135.443 169.525 135.262C169.489 135.082 169.508 134.894 169.578 134.724C169.649 134.554 169.768 134.409 169.921 134.306C170.074 134.204 170.255 134.149 170.439 134.149C170.686 134.149 170.923 134.248 171.097 134.422C171.272 134.597 171.37 134.834 171.37 135.081Z"
        fill="white"
      />
      <path
        d="M174.814 135.081C174.814 135.265 174.759 135.445 174.657 135.598C174.555 135.751 174.409 135.871 174.239 135.941C174.069 136.012 173.882 136.03 173.701 135.994C173.52 135.958 173.354 135.869 173.224 135.739C173.094 135.609 173.005 135.443 172.969 135.262C172.933 135.082 172.952 134.894 173.022 134.724C173.093 134.554 173.212 134.409 173.365 134.306C173.518 134.204 173.698 134.149 173.883 134.149C174.13 134.149 174.366 134.248 174.541 134.422C174.716 134.597 174.814 134.834 174.814 135.081Z"
        fill="white"
      />
      <path d="M66.1822 165.429H0.254639V248.431H66.1822V165.429Z" fill="#FAFAFA" />
      <path
        d="M66.1763 248.438C66.1763 248.438 66.1763 248.298 66.1763 248.03C66.1763 247.762 66.1763 247.392 66.1763 246.869C66.1763 245.829 66.1763 244.318 66.1763 242.353C66.1763 238.405 66.138 232.651 66.1125 225.355C66.1125 210.773 66.0487 190.05 66.0041 165.429L66.2273 165.659L0.261516 165.697L0.510275 165.442C0.510275 196.18 0.510275 224.761 0.465626 248.451L0.261516 248.24L48.0998 248.349L61.4945 248.4H65.0218C65.8255 248.4 66.2465 248.4 66.2465 248.4H65.0664H61.5838L48.2656 248.451L0.255138 248.617H0.0446489V248.406C0.0446489 224.698 0.0446491 196.103 0 165.397V165.148H0.261516H66.1763H66.4059V165.372C66.3549 190.056 66.3166 210.831 66.2911 225.444C66.2911 232.715 66.2465 238.456 66.2273 242.391C66.2273 244.337 66.2273 245.836 66.2273 246.856C66.2273 247.347 66.2273 247.724 66.2273 247.992C66.2273 248.259 66.1763 248.438 66.1763 248.438Z"
        fill="#EBEBEB"
      />
      <path
        d="M66.1768 207.042C66.1768 207.176 51.5383 207.291 33.4937 207.291C15.4491 207.291 0.804199 207.176 0.804199 207.042C0.804199 206.908 15.4363 206.787 33.4937 206.787C51.5511 206.787 66.1768 206.902 66.1768 207.042Z"
        fill="#EBEBEB"
      />
      <path
        d="M66.1768 228.664C66.1768 228.805 51.5383 228.913 33.4937 228.913C15.4491 228.913 0.804199 228.805 0.804199 228.664C0.804199 228.524 15.4363 228.409 33.4937 228.409C51.5511 228.409 66.1768 228.524 66.1768 228.664Z"
        fill="#EBEBEB"
      />
      <path
        d="M66.1768 186.236C66.1768 186.376 51.5383 186.484 33.4937 186.484C15.4491 186.484 0.804199 186.376 0.804199 186.236C0.804199 186.095 15.4363 185.98 33.4937 185.98C51.5511 185.98 66.1768 186.108 66.1768 186.236Z"
        fill="#EBEBEB"
      />
      <path
        d="M56.3854 176.827C56.3854 176.961 46.3457 177.076 33.9652 177.076C21.5846 177.076 11.5386 176.961 11.5386 176.827C11.5386 176.693 21.5782 176.572 33.9652 176.572C46.3521 176.572 56.3854 176.687 56.3854 176.827Z"
        fill="#E0E0E0"
      />
      <path
        d="M56.3854 196.639C56.3854 196.779 46.3457 196.888 33.9652 196.888C21.5846 196.888 11.5386 196.779 11.5386 196.639C11.5386 196.499 21.5782 196.384 33.9652 196.384C46.3521 196.384 56.3854 196.499 56.3854 196.639Z"
        fill="#E0E0E0"
      />
      <path
        d="M52.6604 239.311C52.6604 239.445 43.4563 239.56 32.1027 239.56C20.749 239.56 11.5386 239.445 11.5386 239.311C11.5386 239.177 20.7427 239.056 32.1027 239.056C43.4627 239.056 52.6604 239.17 52.6604 239.311Z"
        fill="#E0E0E0"
      />
      <path
        d="M56.3854 217.816C56.3854 217.95 46.3457 218.064 33.9652 218.064C21.5846 218.064 11.5386 217.95 11.5386 217.816C11.5386 217.682 21.5782 217.561 33.9652 217.561C46.3521 217.561 56.3854 217.675 56.3854 217.816Z"
        fill="#E0E0E0"
      />
      <path d="M142.015 271.56V289.419H168.129H196.94V271.56H142.015Z" fill="#F5F5F5" />
      <path d="M139.713 266.572V271.56H198.86V266.572H168.129H139.713Z" fill="#E0E0E0" />
      <path
        d="M169.284 289.42H196.941V271.56H198.861V266.572H168.13V271.101L169.284 289.42Z"
        fill="#E0E0E0"
      />
      <path d="M160.832 274.443H149.696V277.129H160.832V274.443Z" fill="#E0E0E0" />
      <path d="M162.561 280.489H148.26V286.166H162.561V280.489Z" fill="white" />
      <path d="M150.467 248.731V266.591H176.58H205.385V248.731H150.467Z" fill="#F5F5F5" />
      <path d="M148.158 243.743V248.731H207.305V243.743H176.58H148.158Z" fill="#E0E0E0" />
      <path
        d="M177.735 266.591H205.385V248.731H207.305V243.743H176.58V248.272L177.735 266.591Z"
        fill="#E0E0E0"
      />
      <path d="M169.284 251.614H158.147V254.3H169.284V251.614Z" fill="#E0E0E0" />
      <path d="M171.012 257.661H156.712V263.338H171.012V257.661Z" fill="white" />
      <path d="M20.009 22.5645V170.991H265.809V40.6282H86.1471L85.9238 22.5645H20.009Z" fill="white" />
      <path d="M85.6056 42.925L85.376 25.9775H146.188V42.925H85.6056Z" fill="#F5F5F5" />
      <path d="M206.183 29.1279H146.188V42.9181H206.183V29.1279Z" fill="#EBEBEB" />
      <path d="M206.043 42.9248L206.177 30.793H265.809V42.9248H206.043Z" fill="#E0E0E0" />
      <path
        d="M73.4674 34.4475C73.4674 34.5432 64.0146 34.6133 52.3612 34.6133C40.7078 34.6133 31.2485 34.5432 31.2485 34.4475C31.2485 34.3518 40.695 34.3008 52.3612 34.3008C64.0273 34.3008 73.4674 34.3582 73.4674 34.4475Z"
        fill="#00838F"
      />
      <path
        d="M137.194 34.4481C137.194 34.5437 127.742 34.6139 116.088 34.6139C104.435 34.6139 94.9756 34.5437 94.9756 34.4481C94.9756 34.3524 104.422 34.2822 116.088 34.2822C127.754 34.2822 137.194 34.3588 137.194 34.4481Z"
        fill="#263238"
      />
      <path
        d="M198.414 36.8524C198.414 36.9417 188.961 37.0182 177.301 37.0182C165.642 37.0182 156.189 36.9417 156.189 36.8524C156.189 36.7631 165.642 36.6865 177.301 36.6865C188.961 36.6865 198.414 36.7631 198.414 36.8524Z"
        fill="#263238"
      />
      <path
        d="M257.868 37.324C257.868 37.4197 248.415 37.4963 236.755 37.4963C225.096 37.4963 215.643 37.4197 215.643 37.324C215.643 37.2284 225.096 37.1582 236.755 37.1582C248.415 37.1582 257.868 37.2347 257.868 37.324Z"
        fill="#263238"
      />
      <path d="M265.809 41.6299H20.009V56.1089H265.809V41.6299Z" fill="#FAFAFA" />
      <path d="M137.194 55.1328H20.009V69.6119H137.194V55.1328Z" fill="#00838F" />
      <path d="M265.809 55.1328H206.177V69.6119H265.809V55.1328Z" fill="#00838F" />
      <path
        d="M121.232 88.0171L35.9009 88.1064L35.968 152.242L121.299 152.152L121.232 88.0171Z"
        fill="#00838F"
      />
      <g opacity="0.2">
        <path
          d="M35.9683 152.244L65.73 112.341L84.2211 137.268L96.7293 123.918L121.299 152.149L35.9683 152.244Z"
          fill="black"
        />
      </g>
      <g opacity="0.2">
        <path
          d="M92.0285 107.43C92.031 108.76 91.639 110.061 90.9021 111.168C90.1652 112.275 89.1165 113.138 87.8887 113.649C86.6608 114.16 85.309 114.296 84.0042 114.038C82.6995 113.781 81.5004 113.142 80.5586 112.203C79.6169 111.264 78.9748 110.067 78.7137 108.763C78.4525 107.459 78.584 106.107 79.0915 104.877C79.5989 103.648 80.4596 102.597 81.5646 101.857C82.6696 101.117 83.9693 100.721 85.2993 100.72C87.0806 100.72 88.7894 101.426 90.0508 102.684C91.3122 103.942 92.0234 105.648 92.0285 107.43Z"
          fill="black"
        />
      </g>
      <path
        d="M185.715 89.577C185.715 89.6727 177.512 89.7428 167.396 89.7428C157.279 89.7428 149.077 89.6727 149.077 89.577C149.077 89.4813 157.279 89.4111 167.396 89.4111C177.512 89.4111 185.715 89.4877 185.715 89.577Z"
        fill="#00838F"
      />
      <path
        d="M241.098 101.868C241.098 101.957 220.496 102.034 195.091 102.034C169.686 102.034 149.077 101.957 149.077 101.868C149.077 101.779 169.673 101.702 195.091 101.702C220.509 101.702 241.098 101.772 241.098 101.868Z"
        fill="#263238"
      />
      <path
        d="M241.098 109.88C241.098 109.969 220.496 110.046 195.091 110.046C169.686 110.046 149.077 109.969 149.077 109.88C149.077 109.79 169.673 109.714 195.091 109.714C220.509 109.714 241.098 109.784 241.098 109.88Z"
        fill="#263238"
      />
      <path
        d="M241.098 117.858C241.098 117.954 220.496 118.03 195.091 118.03C169.686 118.03 149.077 117.954 149.077 117.858C149.077 117.763 169.673 117.692 195.091 117.692C220.509 117.692 241.098 117.794 241.098 117.858Z"
        fill="#263238"
      />
      <path
        d="M241.098 125.895C241.098 125.991 220.496 126.061 195.091 126.061C169.686 126.061 149.077 125.991 149.077 125.895C149.077 125.8 169.673 125.729 195.091 125.729C220.509 125.729 241.098 125.806 241.098 125.895Z"
        fill="#263238"
      />
      <path
        d="M241.098 133.907C241.098 134.003 220.496 134.073 195.091 134.073C169.686 134.073 149.077 134.003 149.077 133.907C149.077 133.811 169.673 133.741 195.091 133.741C220.509 133.741 241.098 133.805 241.098 133.907Z"
        fill="#263238"
      />
      <path
        d="M241.098 141.918C241.098 142.013 220.496 142.084 195.091 142.084C169.686 142.084 149.077 142.013 149.077 141.918C149.077 141.822 169.673 141.752 195.091 141.752C220.509 141.752 241.098 141.828 241.098 141.918Z"
        fill="#263238"
      />
      <path
        d="M241.098 148.724C241.098 148.82 220.496 148.89 195.091 148.89C169.686 148.89 149.077 148.82 149.077 148.724C149.077 148.629 169.673 148.559 195.091 148.559C220.509 148.559 241.098 148.635 241.098 148.724Z"
        fill="#263238"
      />
      <path
        d="M20.0094 22.5649L85.9242 22.4756H86.0135V22.5649C86.09 28.5606 86.1708 34.9157 86.2559 41.63L86.1538 41.528L265.809 41.4706H265.968V41.63C265.968 81.6547 265.968 125.417 265.968 170.991V171.157H265.713L20.0094 171.119H19.8818V170.991C19.9584 80.0601 20.0094 23.6109 20.0094 22.5649C20.0094 23.6109 20.0604 80.0601 20.137 170.991L20.0094 170.864L265.72 170.8H265.809L265.643 170.966C265.643 125.392 265.643 81.6292 265.643 41.6045L265.796 41.764L86.141 41.7066H86.0645V41.6045C85.9965 34.8944 85.9263 28.5394 85.854 22.5394L85.9433 22.6287L20.0094 22.5649Z"
        fill="#263238"
      />
      <path
        d="M65.1363 62.1873C65.1363 62.2766 57.9861 62.3532 49.1902 62.3532C40.3944 62.3532 33.2441 62.2766 33.2441 62.1873C33.2441 62.098 40.3944 62.0215 49.1902 62.0215C57.9861 62.0215 65.1363 62.0916 65.1363 62.1873Z"
        fill="#263238"
      />
      <path
        d="M78.5701 69.6507C78.4808 69.6507 78.4043 66.5062 78.4043 62.6344C78.4043 58.7627 78.4808 55.6182 78.5701 55.6182C78.6594 55.6182 78.736 58.7564 78.736 62.6344C78.736 66.5125 78.6658 69.6507 78.5701 69.6507Z"
        fill="#263238"
      />
      <path
        d="M123.639 62.7811C123.639 62.8768 117.261 62.9469 109.313 62.9469C101.366 62.9469 94.9939 62.8768 94.9939 62.7811C94.9939 62.6854 101.411 62.6152 109.32 62.6152C117.229 62.6152 123.639 62.6918 123.639 62.7811Z"
        fill="#263238"
      />
      <path
        d="M258.557 62.4813C258.557 62.5769 250.737 62.6535 241.099 62.6535C231.462 62.6535 223.635 62.5769 223.635 62.4813C223.635 62.3856 231.449 62.3154 241.099 62.3154C250.75 62.3154 258.557 62.3665 258.557 62.4813Z"
        fill="#263238"
      />
      <path
        d="M184.535 62.7811C184.535 62.8768 178.118 62.9469 170.209 62.9469C162.3 62.9469 155.877 62.8768 155.877 62.7811C155.877 62.6854 162.293 62.6152 170.209 62.6152C178.125 62.6152 184.535 62.6918 184.535 62.7811Z"
        fill="#263238"
      />
      <path
        d="M265.81 69.6124H264.732H261.574L249.545 69.6507L206.171 69.7081H206.037V69.5678C206.037 64.9817 206.037 60.1277 206.037 55.0887L206.184 55.2354H142.923H137.182L137.348 55.0696V69.74H137.182L53.6946 69.6826L28.9718 69.6316L53.6946 69.5805L137.182 69.5231L137.016 69.689V55.0186H142.903H206.165H206.311V55.1653C206.311 60.2042 206.311 65.0582 206.311 69.6443L206.171 69.5104L249.545 69.5678L261.574 69.606H264.732L265.81 69.6124Z"
        fill="#263238"
      />
      <path
        d="M251.776 88.6133L257.899 106.945L260.093 101.294L267.639 109.815L270.477 107.277L262.931 98.755L268.564 97.0838L251.776 88.6133Z"
        fill="#263238"
      />
      <path
        d="M248.714 89.4993C247.206 89.6873 245.686 89.7683 244.166 89.7417C242.648 89.8222 241.126 89.7924 239.612 89.6524C241.12 89.4606 242.64 89.3796 244.16 89.41C245.678 89.3257 247.201 89.3555 248.714 89.4993Z"
        fill="#263238"
      />
      <path
        d="M250.769 87.2414C250.218 86.9469 249.691 86.6099 249.193 86.2336C248.243 85.5958 246.942 84.6518 245.519 83.6121C244.097 82.5724 242.815 81.6156 241.903 80.914C241.387 80.5585 240.905 80.1571 240.461 79.7148C241.016 79.9992 241.544 80.3345 242.037 80.7163C242.987 81.3541 244.288 82.3045 245.717 83.3442C247.146 84.3839 248.415 85.3406 249.334 86.0423C249.848 86.3965 250.329 86.798 250.769 87.2414Z"
        fill="#263238"
      />
      <path
        d="M252.975 85.5387C252.761 84.5056 252.642 83.4552 252.618 82.4005C252.561 81.6223 252.516 80.9143 252.465 80.1744C252.428 79.8696 252.406 79.563 252.401 79.2559C252.561 79.2113 252.835 80.64 252.95 82.375C253.08 83.425 253.089 84.4866 252.975 85.5387Z"
        fill="#263238"
      />
      <path
        d="M103.661 276.969V289.834L129.609 289.713C129.418 287.104 115.181 281.899 115.181 281.899L115.461 276.969H103.661Z"
        fill="#00838F"
      />
      <g opacity="0.6">
        <path
          d="M107.859 280.732C107.609 280.826 107.4 281.007 107.271 281.24C107.141 281.474 107.099 281.746 107.15 282.008C107.211 282.266 107.371 282.49 107.595 282.632C107.819 282.774 108.09 282.822 108.35 282.767C108.616 282.665 108.836 282.471 108.972 282.221C109.108 281.971 109.149 281.68 109.09 281.402C109.045 281.272 108.975 281.153 108.885 281.05C108.794 280.948 108.683 280.865 108.56 280.805C108.436 280.746 108.302 280.712 108.165 280.705C108.029 280.697 107.892 280.718 107.763 280.764"
          fill="white"
        />
      </g>
      <path
        d="M103.661 289.833V287.779L128.435 288.073C128.435 288.073 129.711 288.851 129.609 289.712L103.661 289.833Z"
        fill="#263238"
      />
      <path
        d="M115.182 282.148C115.182 282.281 114.544 282.352 113.906 282.785C113.268 283.219 112.969 283.793 112.854 283.742C112.739 283.691 112.911 282.919 113.664 282.396C114.417 281.873 115.207 282.02 115.182 282.148Z"
        fill="#263238"
      />
      <path
        d="M118.594 283.334C118.626 283.468 118.077 283.698 117.662 284.272C117.248 284.846 117.127 285.439 117.025 285.439C116.923 285.439 116.808 284.68 117.344 283.978C117.879 283.277 118.594 283.206 118.594 283.334Z"
        fill="#263238"
      />
      <path
        d="M120.106 286.613C119.998 286.645 119.813 285.975 120.106 285.248C120.399 284.521 120.999 284.208 121.056 284.323C121.114 284.438 120.75 284.827 120.501 285.433C120.253 286.039 120.234 286.6 120.106 286.613Z"
        fill="#263238"
      />
      <path
        d="M114.926 278.486C114.875 278.607 114.288 278.435 113.587 278.486C112.885 278.537 112.311 278.792 112.254 278.678C112.196 278.563 112.713 278.078 113.529 278.04C114.346 278.002 114.99 278.384 114.926 278.486Z"
        fill="#263238"
      />
      <path
        d="M115.073 282.721C115.145 282.466 115.165 282.199 115.13 281.937C115.074 281.214 114.837 280.516 114.442 279.908C114.173 279.492 113.829 279.128 113.427 278.837C113.308 278.737 113.164 278.67 113.011 278.643C112.858 278.616 112.7 278.63 112.554 278.684C112.4 278.767 112.272 278.889 112.181 279.038C112.09 279.187 112.04 279.358 112.037 279.532C112.079 280.203 112.318 280.847 112.724 281.382C113.13 281.918 113.686 282.322 114.32 282.543C114.941 282.741 115.607 282.748 116.231 282.562C116.856 282.377 117.41 282.008 117.822 281.503C118.029 281.271 118.188 281 118.288 280.706C118.339 280.54 118.339 280.362 118.288 280.195C118.258 280.111 118.211 280.034 118.149 279.969C118.088 279.905 118.013 279.853 117.931 279.819C117.662 279.744 117.374 279.773 117.127 279.902C116.905 279.998 116.691 280.113 116.489 280.247C115.904 280.635 115.399 281.134 115.003 281.714C114.845 281.921 114.724 282.154 114.646 282.402C115.182 281.649 115.853 281.001 116.623 280.489C116.967 280.291 117.427 280.004 117.79 280.132C117.956 280.195 118.02 280.387 117.943 280.61C117.846 280.845 117.708 281.061 117.535 281.248C117.159 281.691 116.661 282.014 116.103 282.176C115.545 282.339 114.952 282.333 114.397 282.16C113.84 281.966 113.351 281.617 112.987 281.153C112.623 280.69 112.399 280.132 112.343 279.545C112.34 279.433 112.367 279.322 112.421 279.223C112.474 279.125 112.553 279.042 112.649 278.984C112.747 278.952 112.851 278.946 112.951 278.966C113.052 278.986 113.145 279.032 113.223 279.098C113.601 279.357 113.929 279.681 114.193 280.055C114.588 280.626 114.846 281.281 114.945 281.969C115.054 282.422 115.041 282.721 115.073 282.721Z"
        fill="#263238"
      />
      <path
        d="M94.1836 278.837L94.6238 282.141L94.3303 289.61L84.6032 289.846L84.2588 282.141L85.3623 277.567L94.1836 278.837Z"
        fill="#00838F"
      />
      <path
        d="M89.5209 289.049C89.4635 289.049 89.6038 286.766 89.8335 283.947C90.0631 281.127 90.2991 278.844 90.3629 278.844C90.4267 278.844 90.2799 281.121 90.0503 283.947C89.8207 286.772 89.5783 289.043 89.5209 289.049Z"
        fill="#263238"
      />
      <path d="M94.3687 288.366L84.5396 288.481V289.846L94.3751 289.833L94.3687 288.366Z" fill="#263238" />
      <path
        d="M84.5645 284.074C84.6191 283.938 84.6944 283.811 84.7877 283.698C85.4394 282.862 86.2726 282.186 87.2243 281.72C88.2214 281.231 89.3493 281.076 90.4415 281.277C91.5337 281.478 92.5323 282.025 93.2902 282.836C93.573 283.156 93.8112 283.512 93.9982 283.895C94.1257 284.17 94.1704 284.329 94.1512 284.335C93.8512 283.857 93.5119 283.405 93.1371 282.983C92.1654 282.023 90.8546 281.484 89.4886 281.484C88.1262 281.53 86.8206 282.041 85.7891 282.932C85.0046 283.576 84.6091 284.106 84.5645 284.074Z"
        fill="#263238"
      />
      <path
        d="M89.4763 70.4794L89.3743 104.107L103.802 104.795L104.217 96.6119C104.217 96.6119 109.32 97.6325 110.665 92.8741C111.507 89.8316 111.303 66.3398 111.303 66.3398L89.4763 70.4794Z"
        fill="#FFBE9D"
      />
      <path
        d="M113.197 68.7058C110.926 69.3437 110.429 73.0687 110.429 73.0687L106.87 80.946C105.052 79.5236 102.615 79.6002 102.15 82.7958C101.818 85.0984 104.701 85.564 104.701 85.564C103.036 88.4088 101.901 90.705 99.4708 93.4031C96.8429 96.3308 95.3694 96.6242 92.5183 96.6689H89.3737C89.3737 96.6689 87.8365 91.6746 84.9853 79.1728C81.7961 65.3125 98.2142 67.0984 101.257 63.5584C106.187 57.7923 120.36 66.6583 113.197 68.7058Z"
        fill="#263238"
      />
      <path
        d="M85.2668 80.4806C82.607 78.701 82.3646 73.7067 83.9146 70.7088C84.7098 69.2127 85.8858 67.953 87.3239 67.057C88.7619 66.161 90.411 65.6605 92.1045 65.6061C92.5544 65.6356 93.006 65.5838 93.4376 65.453C94.0328 65.1422 94.5297 64.6717 94.8727 64.0944C97.2519 61.0072 99.5864 57.9711 104.013 57.9711C104.987 57.9264 105.953 58.1658 106.794 58.66C107.577 59.1967 108.163 59.9736 108.465 60.8733C109.279 60.4397 110.151 60.126 111.055 59.942C111.508 59.8542 111.975 59.8657 112.423 59.9758C112.871 60.086 113.29 60.2921 113.651 60.5799C114.002 60.8793 114.236 61.2935 114.31 61.7489C114.385 62.2043 114.296 62.6715 114.059 63.0675C114.809 63.0581 115.537 63.3162 116.113 63.7955C116.69 64.2749 117.076 64.9439 117.204 65.6826C117.44 67.1561 115.322 68.1957 115.099 68.3106C115.099 68.3106 116.26 70.7407 113.491 72.2014C112.382 72.7882 110.94 72.0802 110.761 71.9973"
        fill="#263238"
      />
      <path
        d="M95.7971 83.7148C96.0685 85.4287 95.6736 87.1815 94.6937 88.6135C94.598 88.5625 95.0636 87.5355 95.3315 86.1897C95.5994 84.8438 95.6887 83.7212 95.7971 83.7148Z"
        fill="#455A64"
      />
      <path
        d="M97.5444 82.0684C97.6631 82.8877 97.6176 83.7225 97.4104 84.5241C97.3197 85.3488 97.0591 86.1459 96.645 86.8649C96.7066 86.053 96.8411 85.2482 97.0469 84.4603C97.1344 83.6487 97.301 82.8475 97.5444 82.0684Z"
        fill="#455A64"
      />
      <path
        d="M102.539 76.5195C102.539 76.5642 102.163 76.864 101.646 77.4699C100.981 78.2717 100.455 79.1794 100.09 80.1552C99.7335 81.142 99.4458 82.1522 99.2286 83.1786C99.1685 83.6182 99.0483 84.0475 98.8714 84.4543C98.8439 84.0116 98.8782 83.5672 98.9734 83.134C99.1191 82.0733 99.3801 81.0317 99.7516 80.0277C100.122 79.0105 100.702 78.0826 101.455 77.3041C101.745 76.9566 102.118 76.687 102.539 76.5195Z"
        fill="#455A64"
      />
      <path
        d="M104.561 72.7373C104.727 73.0142 104.823 73.3272 104.841 73.6494C104.945 74.416 104.902 75.1953 104.714 75.9457C104.526 76.6974 104.195 77.4057 103.738 78.0314C103.387 78.5034 103.1 78.7267 103.062 78.7011C103.604 77.8096 104.032 76.854 104.337 75.8564C104.797 74.1725 104.446 72.7564 104.561 72.7373Z"
        fill="#455A64"
      />
      <path
        d="M87.339 81.1371C87.288 81.2264 86.8479 81.0733 86.3568 80.7927C85.8656 80.512 85.4957 80.2186 85.5467 80.1548C85.5977 80.091 86.0378 80.2186 86.529 80.4929C87.0201 80.7672 87.3901 81.0478 87.339 81.1371Z"
        fill="#455A64"
      />
      <path
        d="M94.3109 77.5467C94.4066 77.5467 94.3492 78.2738 93.8963 79.0073C93.4435 79.7408 92.8503 80.1299 92.7929 80.0534C92.7354 79.9768 93.1819 79.4985 93.5838 78.8223C93.9856 78.1462 94.2089 77.5211 94.3109 77.5467Z"
        fill="#455A64"
      />
      <path
        d="M96.4544 76.3984C96.4427 77.0358 96.2181 77.651 95.8165 78.1461C95.2425 79.02 94.5408 79.492 94.4579 79.4218C94.375 79.3517 94.9746 78.784 95.5167 77.9484C95.7976 77.4132 96.1107 76.8956 96.4544 76.3984Z"
        fill="#455A64"
      />
      <path
        d="M107.113 62.3146C107.113 62.3146 106.845 62.1041 106.322 61.8107C105.579 61.4081 104.75 61.1894 103.905 61.1729C102.656 61.1426 101.425 61.4725 100.358 62.1232C99.0766 62.964 98.0367 64.1249 97.3414 65.4911C96.8148 66.4922 96.4019 67.5491 96.1104 68.642L96.053 68.8525L95.8616 68.7504C94.8763 68.2337 93.7847 67.9521 92.6724 67.9276C91.7843 67.9067 90.8994 68.0405 90.0572 68.3231C89.3074 68.6045 88.5891 68.9637 87.9141 69.3947C88.055 69.2304 88.2182 69.0865 88.3988 68.9673C88.8846 68.6131 89.4169 68.3277 89.9807 68.119C90.9605 67.7461 92.0075 67.5825 93.0544 67.6385C94.1013 67.6945 95.1249 67.969 96.0593 68.4443L95.8042 68.5527C96.0921 67.4302 96.5095 66.345 97.048 65.3188C97.7793 63.8928 98.8809 62.6899 100.237 61.8362C101.361 61.1635 102.661 60.8436 103.969 60.9177C104.847 60.9527 105.702 61.2142 106.45 61.6768C106.646 61.8059 106.83 61.9533 106.998 62.1169C107.062 62.2381 107.113 62.3018 107.113 62.3146Z"
        fill="#455A64"
      />
      <path
        d="M114.696 64.145C114.696 64.145 115.092 64.0365 115.519 64.5149C115.642 64.6592 115.735 64.8267 115.792 65.0075C115.849 65.1883 115.869 65.3786 115.851 65.5673C115.817 65.8192 115.723 66.0593 115.578 66.2678C115.433 66.4763 115.24 66.6473 115.015 66.7665C114.484 67.0712 113.855 67.1581 113.261 67.0089C112.608 66.8389 111.995 66.542 111.456 66.135C110.884 65.6927 110.286 65.2858 109.664 64.9167C108.997 64.5566 108.278 64.3026 107.533 64.1641C106.081 63.9046 104.587 64.0076 103.183 64.4639C102.045 64.8456 100.97 65.396 99.9941 66.0968C99.1061 66.7765 98.2545 67.5026 97.4427 68.2718C97.5972 68.008 97.7883 67.7675 98.0104 67.5574C98.5759 66.9486 99.1926 66.3895 99.8538 65.8863C100.842 65.1347 101.945 64.5468 103.12 64.145C104.574 63.652 106.13 63.5357 107.642 63.8069C108.423 63.9525 109.176 64.2194 109.874 64.5978C110.498 64.9844 111.097 65.4105 111.667 65.8735C112.169 66.2625 112.74 66.5526 113.351 66.7282C113.872 66.8698 114.429 66.8083 114.907 66.556C115.102 66.4576 115.272 66.3164 115.404 66.143C115.537 65.9695 115.628 65.7684 115.672 65.5546C115.712 65.2227 115.625 64.8881 115.43 64.617C115.251 64.3754 114.99 64.2075 114.696 64.145Z"
        fill="#455A64"
      />
      <path
        d="M157.884 80.1104L146.123 103.749L118.383 105.165L119.525 120.473C119.525 120.473 150.441 118.419 153.56 114.733C156.94 110.759 164.965 86.3548 164.971 86.2783C164.977 86.2018 165.94 83.4208 165.928 83.4144L157.884 80.1104Z"
        fill="#FFBE9D"
      />
      <path
        d="M156.342 83.651L158.721 78.4972C159.665 75.2314 162.758 68.9869 162.758 68.9869C162.758 68.9869 168.065 63.0422 168.76 63.2463C169.456 63.4505 169.813 64.1585 169.099 65.0578C168.384 65.9572 165.782 70.4157 165.782 70.4157C165.782 70.4157 167.268 70.6964 167.447 71.6531C167.569 72.1378 167.547 72.648 167.383 73.1202C167.383 73.1202 168.78 73.5093 169.239 74.1917C169.512 74.5964 169.706 75.0486 169.813 75.5248C170.065 75.5348 170.306 75.6297 170.497 75.7942C170.688 75.9586 170.818 76.1829 170.865 76.4306C171.04 77.0267 171.117 77.6469 171.095 78.2676C171.095 78.2676 169.239 81.3675 168.34 81.865C167.44 82.3625 166.222 82.9685 166.222 82.9685L164.264 88.1988"
        fill="#FFBE9D"
      />
      <path
        d="M122.16 236.497C122.249 235.553 122.293 234.832 122.293 234.379L125.189 188.142C125.189 188.142 122.753 174.645 121.605 171.06L111.252 167.055L97.4622 172.425C97.3665 171.315 97.3155 170.627 97.3155 170.627L77.0002 177.643C77.0002 177.643 76.4708 191.503 77.0002 195.719C77.166 197.078 77.7018 200.58 78.346 204.7C78.346 204.7 78.9839 213.668 79.1306 214.166C79.6217 215.996 79.7684 220.474 79.7684 224.958C79.7684 230.654 80.081 237.441 80.4063 240.662C80.7316 243.883 80.9357 279.341 80.9357 279.341H95.925L97.8386 222.866C98.1639 216.819 98.6486 207.558 98.9548 200.867L102.476 221.718C102.476 222.483 104.619 234.405 104.619 234.405C104.122 238.156 103.815 241.931 103.7 245.714C103.452 256.225 102.221 279.341 102.221 279.341H116.891C116.891 279.341 121.298 245.962 122.153 236.49L122.16 236.497Z"
        fill="#263238"
      />
      <path
        d="M124.685 185.342C124.203 185.164 123.748 184.921 123.333 184.621C121.25 183.286 119.453 181.552 118.045 179.518C117.729 179.114 117.472 178.667 117.279 178.191C117.622 178.568 117.937 178.969 118.223 179.391C118.971 180.352 119.783 181.262 120.654 182.114C121.534 182.958 122.472 183.74 123.46 184.455C123.89 184.719 124.3 185.016 124.685 185.342Z"
        fill="#455A64"
      />
      <path
        d="M97.5066 190.923C97.39 190.782 97.2934 190.625 97.2196 190.458C96.9822 190.026 96.7692 189.58 96.5817 189.125C95.9542 187.622 95.4964 186.054 95.2167 184.449C94.9364 182.845 94.8379 181.215 94.9233 179.589C94.9437 179.095 94.9905 178.604 95.0637 178.115C95.0715 177.934 95.1103 177.755 95.1785 177.586C95.2295 177.586 95.1785 178.358 95.1466 179.595C95.1042 182.819 95.6623 186.022 96.7922 189.042C97.226 190.202 97.5449 190.898 97.5066 190.923Z"
        fill="#455A64"
      />
      <path
        d="M100.734 211.15C100.643 210.889 100.579 210.62 100.542 210.346C100.434 209.759 100.3 209.019 100.134 208.133L98.9095 200.817C98.0803 195.631 97.5126 192.104 97.2511 190.458C97.5861 191.431 97.8233 192.436 97.9591 193.456C98.3163 195.318 98.7692 197.921 99.2348 200.766C99.7004 203.61 100.096 206.219 100.37 208.101C100.491 208.987 100.593 209.734 100.67 210.327C100.723 210.598 100.744 210.874 100.734 211.15Z"
        fill="#455A64"
      />
      <path
        d="M65.3276 134.282C65.3276 134.282 63.3503 148.213 62.9803 153.099C62.7571 156.039 65.7422 159.943 68.0193 160.223C70.2964 160.504 77.9824 160.97 77.9824 160.97L78.6203 152.231L78.4225 150.49L76.037 150.043L76.3304 135.979L65.3276 134.282Z"
        fill="#FFBE9D"
      />
      <path
        d="M76.4387 136.406C76.5013 136.654 76.5313 136.909 76.528 137.165C76.5663 137.656 76.5982 138.364 76.6173 139.238C76.6556 140.986 76.6173 143.41 76.4642 146.076C76.3112 148.742 76.1007 151.147 75.9285 152.888C75.8392 153.762 75.7626 154.464 75.7052 154.948C75.6909 155.204 75.6459 155.456 75.5713 155.701C75.5466 155.446 75.5466 155.19 75.5713 154.936C75.5713 154.381 75.6478 153.66 75.6925 152.869C75.8136 151.051 75.9667 148.678 76.1326 146.057C76.2984 143.435 76.3622 140.954 76.3813 139.238C76.3813 138.422 76.3813 137.727 76.3813 137.172C76.3762 136.915 76.3955 136.659 76.4387 136.406Z"
        fill="#EB996E"
      />
      <path
        d="M135.892 103.749C136.288 103.647 114.945 101.465 114.945 101.465C111.476 101.058 107.982 100.904 104.491 101.006H89.9291C89.9291 101.006 72.2799 106.211 69.365 111.007L61.8257 133.683L76.7767 135.941L78.6392 152.231C78.6392 152.231 76.7831 171.092 76.7831 173.829C76.7414 175.096 76.8205 176.365 77.0191 177.617C77.0191 177.617 106.647 170.952 114.014 173.35C121.381 175.749 123.543 176.406 123.543 176.406C123.543 176.406 123.04 169.791 122.497 165.25L120.788 142.517L119.436 123.937L135.918 122.023L135.892 103.749Z"
        fill="#455A64"
      />
      <path
        d="M76.215 136.496C76.1639 136.496 76.03 135.539 75.7174 133.996C75.3127 131.989 74.7735 130.011 74.1037 128.077C73.4231 126.142 72.6221 124.253 71.7054 122.419L70.8634 120.754C70.7403 120.555 70.6438 120.34 70.5764 120.116C70.7314 120.288 70.8643 120.479 70.9719 120.684C71.2079 121.054 71.5268 121.609 71.9031 122.304C72.886 124.118 73.7221 126.008 74.4035 127.955C75.0842 129.905 75.5985 131.908 75.9407 133.945C76.0683 134.723 76.1512 135.354 76.1831 135.801C76.2273 136.03 76.238 136.264 76.215 136.496Z"
        fill="#263238"
      />
      <path
        d="M104.836 142.555C104.82 142.657 104.795 142.758 104.759 142.855C104.683 143.078 104.587 143.359 104.479 143.69C104.37 144.022 104.198 144.5 103.994 145.004C103.79 145.508 103.573 146.095 103.286 146.72C102.75 147.996 102.01 149.45 101.168 151.051C100.214 152.776 99.1732 154.452 98.0491 156.071C96.9135 157.682 95.6958 159.234 94.4006 160.721C93.1951 162.079 92.0597 163.272 91.0583 164.197C90.5672 164.675 90.0952 165.077 89.6869 165.473C89.2787 165.868 88.9215 166.111 88.6217 166.372L87.9329 166.927C87.7734 167.048 87.6841 167.112 87.6777 167.099C87.7383 167.016 87.809 166.941 87.8882 166.876L88.5261 166.283C88.8131 166.028 89.1639 165.715 89.553 165.339C89.9421 164.962 90.4077 164.548 90.8797 164.063C91.862 163.113 92.9782 161.92 94.1646 160.561C95.351 159.203 96.5693 157.627 97.7684 155.918C98.9676 154.208 100.001 152.512 100.894 150.943C101.787 149.374 102.501 147.906 103.063 146.656C103.362 146.018 103.592 145.47 103.809 144.972C104.026 144.475 104.204 144.035 104.345 143.697L104.676 142.88C104.778 142.644 104.823 142.549 104.836 142.555Z"
        fill="#263238"
      />
      <path
        opacity="0.3"
        d="M93.8199 151.804C92.1629 154.463 90.7822 157.284 89.6995 160.224C94.3769 156.409 98.0602 151.517 100.434 145.968C97.6406 147.039 95.4209 149.272 93.8199 151.804Z"
        fill="black"
      />
      <path
        d="M115.321 116.174C115.169 116.194 115.015 116.194 114.862 116.174L113.548 116.13C112.413 116.079 110.767 116.015 108.732 115.958C104.669 115.843 99.0436 115.817 92.8437 116.091C86.6439 116.366 81.0436 116.902 76.9997 117.367C74.9777 117.61 73.3448 117.814 72.2222 117.967L70.9466 118.133C70.7943 118.154 70.641 118.167 70.4873 118.171C70.6315 118.119 70.7814 118.085 70.9338 118.069L72.2095 117.839C73.3321 117.648 74.9586 117.405 76.9805 117.138C81.0181 116.595 86.6184 116.028 92.831 115.747C99.0436 115.466 104.676 115.537 108.777 115.715C110.812 115.798 112.457 115.894 113.593 115.989L114.9 116.098C115.042 116.113 115.183 116.139 115.321 116.174Z"
        fill="white"
      />
      <path
        d="M114.766 102.964C114.637 102.982 114.506 102.982 114.377 102.964H113.274C112.311 102.964 110.927 102.964 109.211 103.009C105.779 103.066 101.04 103.271 95.8161 103.698C90.5922 104.125 85.8849 104.68 82.4725 105.082L78.4349 105.56L77.3378 105.682C77.2084 105.694 77.0781 105.694 76.9487 105.682C77.0696 105.635 77.1962 105.605 77.3251 105.592L78.4158 105.414C79.3662 105.267 80.7439 105.063 82.4469 104.833C85.8466 104.368 90.5603 103.781 95.7906 103.347C101.021 102.913 105.767 102.754 109.185 102.754C110.908 102.754 112.298 102.754 113.255 102.811L114.365 102.862C114.502 102.883 114.636 102.917 114.766 102.964Z"
        fill="white"
      />
      <path
        d="M124.219 122.648C124.035 122.455 123.875 122.241 123.741 122.01C123.332 121.396 122.962 120.757 122.631 120.097C122.14 119.118 121.713 118.108 121.355 117.074C120.924 115.804 120.59 114.503 120.354 113.183C120.13 111.859 120.008 110.52 119.99 109.177C119.978 108.081 120.042 106.986 120.182 105.899C120.269 105.166 120.401 104.44 120.577 103.723C120.629 103.458 120.706 103.197 120.807 102.945C120.87 102.945 120.602 104.081 120.418 105.924C120.311 107.003 120.27 108.087 120.296 109.171C120.334 110.496 120.462 111.817 120.679 113.125C120.916 114.421 121.24 115.7 121.649 116.952C121.988 117.98 122.391 118.985 122.854 119.963C123.639 121.685 124.27 122.616 124.219 122.648Z"
        fill="white"
      />
      <path
        d="M131.243 121.787C131.105 121.587 130.987 121.373 130.892 121.149C130.681 120.735 130.413 120.116 130.114 119.344C128.582 115.262 128.091 110.862 128.685 106.543C128.771 105.899 128.89 105.261 129.042 104.629C129.083 104.392 129.154 104.16 129.253 103.94C129.31 103.94 129.093 104.955 128.921 106.594C128.46 110.878 128.944 115.21 130.337 119.287C130.866 120.831 131.3 121.762 131.243 121.787Z"
        fill="white"
      />
      <path
        d="M74.8126 129.671C74.2588 129.72 73.7017 129.72 73.1479 129.671L69.1294 129.62C67.5603 129.62 66.138 129.55 65.111 129.473C64.5527 129.468 63.9966 129.402 63.4526 129.276C64.0074 129.212 64.5667 129.199 65.1238 129.237L69.1358 129.288C70.7049 129.288 72.1273 129.365 73.1542 129.435C73.7139 129.454 74.2698 129.534 74.8126 129.671Z"
        fill="white"
      />
      <path
        d="M69.8058 121.047C69.2012 121.167 68.5822 121.197 67.9688 121.136C67.3484 121.182 66.7254 121.099 66.1382 120.894C66.7428 120.774 67.3618 120.744 67.9752 120.805C68.5955 120.759 69.2186 120.842 69.8058 121.047Z"
        fill="white"
      />
      <path
        d="M119.168 130.493C119.168 130.583 109.536 130.87 97.6534 131.131C85.7704 131.393 76.1326 131.527 76.1326 131.437C76.1326 131.348 85.764 131.061 97.647 130.8C109.53 130.538 119.162 130.404 119.168 130.493Z"
        fill="white"
      />
      <path
        d="M103.158 145.796C102.83 145.855 102.497 145.889 102.163 145.898C101.525 145.942 100.594 145.987 99.446 146.032C97.1498 146.114 93.9733 146.159 90.4716 146.07C86.9698 145.981 83.7997 145.783 81.5418 145.585C80.4 145.489 79.4751 145.4 78.8373 145.317C78.5042 145.296 78.1735 145.247 77.8486 145.17C78.1797 145.141 78.5126 145.141 78.8437 145.17L81.5609 145.336C83.8507 145.47 87.0145 145.63 90.4907 145.719C93.9669 145.808 97.1562 145.802 99.4524 145.776H102.17C102.499 145.752 102.83 145.759 103.158 145.796Z"
        fill="white"
      />
      <path
        d="M120.508 145.955C119.732 145.99 118.954 145.971 118.18 145.898L112.586 145.598L106.986 145.311C106.211 145.301 105.437 145.239 104.67 145.126C104.879 145.079 105.094 145.064 105.308 145.081C105.71 145.081 106.29 145.081 107.011 145.081C108.446 145.081 110.43 145.158 112.618 145.273C114.806 145.387 116.783 145.534 118.212 145.668C118.926 145.738 119.487 145.796 119.902 145.847C120.108 145.858 120.311 145.895 120.508 145.955Z"
        fill="white"
      />
      <path
        d="M92.8116 161.462C92.8116 161.557 89.4885 161.583 85.3871 161.532C81.2858 161.481 77.9626 161.36 77.9626 161.27C77.9626 161.181 81.2858 161.149 85.3871 161.2C89.4885 161.251 92.818 161.372 92.8116 161.462Z"
        fill="white"
      />
      <path
        d="M121.323 161.646C120.987 161.663 120.649 161.648 120.315 161.602L117.586 161.372C115.283 161.174 112.1 160.907 108.579 160.658C105.058 160.409 101.863 160.23 99.5537 160.103L96.8173 159.95C96.4813 159.949 96.146 159.917 95.8159 159.854C96.1514 159.829 96.4883 159.829 96.8237 159.854C97.4616 159.854 98.4056 159.854 99.5664 159.905C101.882 159.969 105.077 160.109 108.598 160.358C112.119 160.607 115.308 160.913 117.611 161.174C118.759 161.302 119.69 161.417 120.328 161.506C120.663 161.527 120.996 161.574 121.323 161.646Z"
        fill="white"
      />
      <path
        d="M123.333 174.696C123.189 174.625 123.051 174.544 122.918 174.454C122.65 174.282 122.281 174.039 121.726 173.752C120.241 172.942 118.671 172.3 117.044 171.839C114.631 171.166 112.148 170.774 109.645 170.671C106.572 170.565 103.495 170.62 100.428 170.837C97.1559 171.016 94.0496 171.195 91.2175 171.475C88.7184 171.707 86.2336 172.073 83.7739 172.572C82.1177 172.911 80.4804 173.337 78.8689 173.848C78.2948 174.02 77.8675 174.199 77.5613 174.288C77.4129 174.349 77.2591 174.396 77.1021 174.428C77.24 174.349 77.3852 174.283 77.5358 174.231C77.8356 174.116 78.2566 173.918 78.8115 173.727C80.4107 173.155 82.0448 172.687 83.7037 172.323C86.1673 171.8 88.659 171.418 91.1665 171.182C93.9922 170.888 97.1112 170.697 100.383 170.544C103.461 170.325 106.549 170.278 109.632 170.404C112.159 170.536 114.661 170.964 117.088 171.679C118.723 172.161 120.293 172.84 121.764 173.701C122.166 173.931 122.556 174.182 122.931 174.454C123.074 174.519 123.209 174.601 123.333 174.696Z"
        fill="white"
      />
      <path
        d="M114.996 102.965C114.42 103.793 114.026 104.735 113.842 105.727C113.657 106.803 113.657 107.903 113.842 108.98C114.081 110.305 114.392 111.617 114.773 112.909C115.532 115.677 116.336 118.158 116.942 119.925C117.235 120.767 117.478 121.475 117.675 122.036C117.78 122.289 117.859 122.552 117.911 122.821C117.767 122.587 117.647 122.339 117.554 122.081C117.344 121.596 117.057 120.888 116.725 120.008C116.087 118.241 115.213 115.766 114.448 112.992C114.068 111.688 113.764 110.363 113.536 109.024C113.357 107.916 113.376 106.784 113.593 105.682C113.743 104.94 114.031 104.232 114.441 103.596C114.585 103.353 114.774 103.139 114.996 102.965Z"
        fill="#263238"
      />
      <path
        d="M236.596 161.283C229.912 164.747 224.784 170.711 221.097 177.28C217.41 183.85 215.031 191.051 212.677 198.202L210.623 235.094C218.399 221.859 224.656 206.296 226.334 191.039L221.161 190.573C224.637 190.037 227.539 187.524 229.402 184.539C231.264 181.554 232.208 178.122 233.139 174.735L226.825 175.673C231.811 172.311 235.311 167.158 236.596 161.283Z"
        fill="#455A64"
      />
      <g opacity="0.3">
        <path
          d="M236.596 161.283C229.912 164.747 224.783 170.711 221.097 177.28C217.41 183.85 215.031 191.051 212.677 198.202L210.623 235.094C218.398 221.859 224.656 206.296 226.333 191.039L221.16 190.573C224.637 190.037 227.539 187.524 229.401 184.539C231.264 181.554 232.208 178.122 233.139 174.735L226.824 175.673C231.811 172.311 235.31 167.158 236.596 161.283Z"
          fill="black"
        />
      </g>
      <path
        d="M209.475 243.744C209.475 243.744 192.585 223.524 192.783 204.229H199.601C199.601 204.229 192.196 199.356 192.776 196.626C193.357 193.896 193.561 183.557 193.561 183.557L199.799 185.113C199.799 185.113 195.136 182.791 194.537 179.092C193.937 175.392 195.902 158.234 195.902 158.234C195.902 158.234 203.499 166.227 205.839 174.416C208.18 182.606 214.316 192.149 212.518 217.101C210.719 242.053 209.475 243.744 209.475 243.744Z"
        fill="#455A64"
      />
      <path
        d="M218.035 245.651C218.035 245.651 236.335 233.462 240.398 217.905L234.912 216.412C234.912 216.412 241.928 214.116 242.062 211.794C242.196 209.473 244.295 201.123 244.295 201.123L238.937 201.008C238.937 201.008 243.211 200.154 244.493 197.303C245.775 194.451 247.956 180.24 247.956 180.24C247.956 180.24 240.098 184.999 236.424 191.084C232.75 197.169 225.753 203.477 221.715 223.926C217.678 244.375 218.035 245.651 218.035 245.651Z"
        fill="#455A64"
      />
      <path
        d="M200.513 175.852C200.541 175.92 200.562 175.99 200.577 176.062C200.609 176.222 200.66 176.426 200.724 176.7L201.26 179.098C201.496 180.138 201.77 181.414 202.063 182.925C202.357 184.437 202.701 186.114 203.058 187.983C203.779 191.727 204.545 196.212 205.329 201.193C206.114 206.175 206.854 211.679 207.53 217.464C208.206 223.25 208.703 228.78 209.099 233.812C209.494 238.845 209.737 243.38 209.902 247.207C209.998 249.121 210.043 250.836 210.087 252.355C210.132 253.873 210.164 255.174 210.177 256.239C210.19 257.304 210.177 258.114 210.177 258.695V259.333C210.186 259.405 210.186 259.477 210.177 259.549C210.169 259.477 210.169 259.405 210.177 259.333C210.177 259.167 210.177 258.956 210.145 258.695C210.145 258.108 210.094 257.285 210.056 256.239C210.017 255.193 209.985 253.866 209.915 252.355C209.845 250.843 209.788 249.114 209.679 247.207C209.501 243.38 209.201 238.864 208.812 233.812C208.423 228.761 207.874 223.262 207.211 217.477C206.547 211.692 205.788 206.207 205.042 201.219C204.296 196.231 203.53 191.747 202.842 187.996C202.51 186.121 202.204 184.424 201.891 182.938C201.579 181.452 201.362 180.17 201.145 179.111C200.928 178.052 200.781 177.268 200.673 176.7L200.552 176.062C200.533 175.993 200.521 175.923 200.513 175.852Z"
        fill="#263238"
      />
      <path
        d="M207.134 256.118C207.134 256.118 191.679 238.584 186.876 227.983L190.499 226.433C187.878 226.867 185.186 225.738 183.151 224.028C181.117 222.319 179.618 220.061 178.151 217.835L182.922 217.031C178.538 215.78 174.805 212.888 172.499 208.956C178.125 209.881 183.221 212.974 187.418 216.84C190.753 220.044 193.781 223.552 196.463 227.32C197.816 229.127 198.993 231.06 199.978 233.092C202.574 238.463 207.141 249.682 207.134 256.118Z"
        fill="#455A64"
      />
      <path
        d="M240.64 190.445C240.624 190.507 240.6 190.567 240.57 190.624C240.512 190.758 240.43 190.924 240.334 191.134C240.111 191.6 239.792 192.25 239.396 193.08C238.567 194.763 237.393 197.213 235.977 200.249C234.561 203.285 232.909 206.933 231.149 211.003C229.388 215.072 227.545 219.588 225.689 224.359C223.833 229.13 222.194 233.723 220.746 237.914C219.298 242.104 218.073 245.906 217.084 249.114C216.096 252.322 215.305 254.918 214.788 256.724L214.15 258.79L213.978 259.326C213.94 259.447 213.914 259.511 213.914 259.511C213.907 259.447 213.907 259.383 213.914 259.32C213.953 259.179 213.997 259.001 214.048 258.777L214.603 256.685C215.088 254.874 215.834 252.259 216.797 249.031C217.761 245.804 218.979 242.015 220.395 237.799C221.811 233.583 223.482 229.003 225.325 224.225C227.169 219.448 229.05 214.938 230.811 210.869C232.571 206.8 234.255 203.164 235.703 200.14C237.151 197.117 238.357 194.674 239.224 193.009C239.645 192.193 239.977 191.549 240.219 191.096C240.327 190.892 240.417 190.732 240.481 190.598C240.544 190.464 240.634 190.439 240.64 190.445Z"
        fill="#263238"
      />
      <path
        d="M208.442 263.632C208.4 263.53 208.374 263.423 208.365 263.313C208.327 263.064 208.276 262.752 208.212 262.382C208.097 261.572 207.893 260.405 207.613 258.969C207.332 257.534 206.937 255.844 206.426 253.975C205.916 252.106 205.265 250.091 204.481 248.005C203.696 245.919 202.829 243.98 201.993 242.264C201.158 240.548 200.322 238.992 199.582 237.736C198.842 236.479 198.23 235.458 197.784 234.776L197.28 233.972C197.242 233.987 197.199 233.988 197.161 233.974C197.122 233.959 197.091 233.932 197.071 233.895C197.052 233.859 197.048 233.817 197.058 233.777C197.068 233.737 197.092 233.703 197.126 233.68C197.16 233.657 197.202 233.648 197.242 233.654C197.283 233.66 197.32 233.681 197.346 233.712C197.372 233.744 197.386 233.784 197.384 233.825C197.382 233.866 197.366 233.905 197.337 233.934C197.484 234.145 197.669 234.393 197.892 234.699C198.37 235.369 199.021 236.358 199.805 237.614C200.59 238.871 201.419 240.395 202.287 242.124C203.154 243.852 204.015 245.817 204.838 247.916C205.661 250.014 206.26 252.049 206.752 253.924C207.243 255.799 207.619 257.509 207.868 258.957C208.117 260.405 208.289 261.572 208.372 262.388C208.404 262.765 208.436 263.077 208.461 263.332C208.466 263.433 208.46 263.533 208.442 263.632Z"
        fill="#263238"
      />
      <path d="M228.833 259.511H195.009V266.412H228.833V259.511Z" fill="#00838F" />
      <path d="M198.236 266.406L201.891 289.617H222.334L225.74 266.406H198.236Z" fill="#00838F" />
      <path
        d="M226.76 266.406C226.76 266.502 220.382 266.572 212.472 266.572C204.563 266.572 198.178 266.502 198.178 266.406C198.178 266.31 204.557 266.24 212.472 266.24C220.388 266.24 226.76 266.317 226.76 266.406Z"
        fill="#263238"
      />
      <path
        d="M264.864 289.834C264.864 289.929 207.937 290 137.736 290C67.5345 290 10.5942 289.929 10.5942 289.834C10.5942 289.738 67.509 289.668 137.736 289.668C207.962 289.668 264.864 289.745 264.864 289.834Z"
        fill="#263238"
      />
    </svg>
  )
}
