import { type TRootState } from '@flyward/platform/store/configureReducers'
import { cloneDeep } from 'lodash'

const selectSortedReportAssets = (state: TRootState) =>
  cloneDeep(state.flyForward.reportAssets)?.sort((a, b) => a.assetSerialNumber.localeCompare(b.assetSerialNumber)) ?? []

const selectSelectedAssetId = (state: TRootState) => state.flyForward.selectedAssetId
const selectSuccessfulReportId = (state: TRootState) => state.flyForward.successfulReportId

const selectReportAssetByAssetId = (state: TRootState, assetId: string) => {
  const reportAssetIdIndex = state.flyForward.reportAssets?.findIndex((reportAssetId) => reportAssetId.assetId === assetId) ?? -1

  if (reportAssetIdIndex === -1) {
    return
  }

  return state.flyForward.reportAssets[reportAssetIdIndex]
}

const selectReportAssetFlyForwardParametersByAssetId = (state: TRootState, assetId: string) => {
  const reportAssetIdIndex = state.flyForward.reportAssets?.findIndex((reportAssetId) => reportAssetId.assetId === assetId) ?? -1

  if (reportAssetIdIndex === -1) {
    return
  }

  return state.flyForward.reportAssets[reportAssetIdIndex].flyForwardParameters
}

export {
  selectReportAssetByAssetId,
  selectReportAssetFlyForwardParametersByAssetId,
  selectSortedReportAssets,
  selectSelectedAssetId,
  selectSuccessfulReportId,
}
