import { type ContractualFlightHoursToFlightCyclesPerformanceRestorationReserve } from '@flyward/assets/models'
import { NumberDisplay, NumberDisplayType, SimpleHeader } from '@flyward/platform'
import { type ColumnDef } from '@tanstack/react-table'

const contractualReserveColumns: Array<ColumnDef<ContractualFlightHoursToFlightCyclesPerformanceRestorationReserve>> = [
  {
    id: 'ratio',
    accessorKey: 'flightHoursToFlightCyclesRatio',
    header: () => <SimpleHeader title="FH/FC ratio" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
    cell: ({ getValue }) => (
      <NumberDisplay className="flex justify-center p-0 text-xs text-text-1" displayType={NumberDisplayType.Decimal} value={getValue()} />
    ),
  },
  {
    id: 'rate',
    accessorKey: 'reserveAmount',
    header: () => <SimpleHeader title="PR MR rate" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
    cell: ({ getValue }) => (
      <NumberDisplay className="flex justify-end p-0 text-xs text-text-1" displayType={NumberDisplayType.Currency} value={getValue()} />
    ),
  },
]

export { contractualReserveColumns }
