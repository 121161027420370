import {
  NumberDisplay,
  type UtilizationUnits,
  UtilizationUnitsDisplay,
  type TableColumn,
  NumberDisplayType,
  TableRow,
  TableCell,
} from '@flyward/platform'
import { MaintenanceProgramDetailsTable } from '../MaintenanceProgramDetailsTable'
import { type EngineLifeLimitedPartMaintenanceProgram } from '../../../models'
import { cloneDeep } from 'lodash'

interface EngineLifeLimitedPartsMaintenanceProgramDisplay {
  programId: string
  componentModel?: string
  componentModule?: string
  unit?: UtilizationUnits
  limit?: number
  cost?: number
  costPerCycle?: number
}

interface IEngineLifeLimitedPartsTableProps {
  engineLifeLimitedPartsMaintenancePrograms: EngineLifeLimitedPartMaintenanceProgram[] | undefined
}

const llpTableColumns: Array<TableColumn<EngineLifeLimitedPartsMaintenanceProgramDisplay>> = [
  {
    accessor: 'programId',
    title: '',
    isIdField: true,
    isHidden: true,
  },
  {
    accessor: 'componentModel',
    title: 'Description',
  },
  {
    accessor: 'componentModule',
    title: 'Module',
  },
  {
    accessor: 'unit',
    title: 'Unit',
    valueGetter: (value) => UtilizationUnitsDisplay(value as UtilizationUnits),
  },
  {
    accessor: 'limit',
    title: 'Limit',
    valueGetter: (value) => <NumberDisplay value={value} />,
  },
  {
    accessor: 'cost',
    title: 'CLP',
    valueGetter: (value) => <NumberDisplay displayType={NumberDisplayType.CurrencyRounded} value={value} />,
  },
  {
    accessor: 'costPerCycle',
    title: 'Cost/Cycle',
    valueGetter: (value) => <NumberDisplay displayType={NumberDisplayType.Currency} currencyDecimals={2} value={value} />,
  },
]

export const EngineLifeLimitedPartsTable = ({
  engineLifeLimitedPartsMaintenancePrograms,
}: IEngineLifeLimitedPartsTableProps): React.ReactElement<IEngineLifeLimitedPartsTableProps> => {
  const llpTableData: EngineLifeLimitedPartsMaintenanceProgramDisplay[] | undefined =
    engineLifeLimitedPartsMaintenancePrograms !== undefined
      ? cloneDeep(engineLifeLimitedPartsMaintenancePrograms)
          .map((t) => ({
            programId: t.engineLifeLimitedPartkbProgramId,
            componentModel: t.componentModel,
            componentModule: t.componentModule,
            unit: t.check?.utilizationUnit,
            limit: t.check?.lifeLimitedPartCheckLimit?.utilization,
            cost: t.check?.baseCost,
            costPerCycle: t.check?.costPerCycle,
          }))
          ?.sort((a, b) => a.componentModule.localeCompare(b.componentModule))
      : undefined

  const totalLLPsCost = engineLifeLimitedPartsMaintenancePrograms?.[0].engineMaintenanceProgram.totalLLPsCost
  const totalLLPsCostPerCycle = engineLifeLimitedPartsMaintenancePrograms?.[0].engineMaintenanceProgram.totalLLPsCostPerCycle

  const summaryRow = (
    <TableRow className="font-semibold">
      <TableCell className="text-1 border border-black-20 bg-primary-dark-3 p-px" colSpan={1}>
        Total
      </TableCell>
      <TableCell className="border border-black-20 bg-primary-dark-3 p-px text-text-1" colSpan={3}></TableCell>
      <TableCell className="border border-black-20 bg-primary-dark-3 p-px text-text-1">
        <NumberDisplay displayType={NumberDisplayType.CurrencyRounded} value={totalLLPsCost} />
      </TableCell>
      <TableCell className="border border-black-20 bg-primary-dark-3 p-px text-text-1">
        <NumberDisplay displayType={NumberDisplayType.Currency} value={totalLLPsCostPerCycle} />
      </TableCell>
    </TableRow>
  )

  return <MaintenanceProgramDetailsTable columns={llpTableColumns} data={llpTableData} canAddNewRow canUploadFromFile summaryRow={summaryRow} />
}
