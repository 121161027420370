import { type ManualEventDto, type IMaintenanceScheduleDisplay } from '@flyward/forecasts/models'
import { useReScheduleWithManualEventMutation } from '@flyward/forecasts/store'
import { type CheckTypes, type AssetType, isEngineCheck, getEngineChecks, CheckTypesTableDisplay, YearMonth } from '@flyward/platform/models'
import { ActionDialog, type IYearMonth, YearMonthSelector } from '@flyward/platform/components'
import { type Row } from '@tanstack/react-table'
import { useEffect, useMemo, useState } from 'react'
import { useAppDispatch } from '@flyward/platform/store/configureHooks'
import { setAssetFlyResults } from '@flyward/platform/store'
import { isNil } from 'lodash'
import { useSpinnerState } from '@flyward/platform/contexts'

interface ISetManualEventProps {
  assetType: AssetType
  reportItemId: string
  assetSchedules: IMaintenanceScheduleDisplay[]
  startYearMonth: IYearMonth
  endYearMonth: IYearMonth
  row: Row<IMaintenanceScheduleDisplay>
  isEditConfirm: boolean
  setIsEditConfirm: (value: boolean) => void
}

const SetManualEvent = ({
  assetType,
  reportItemId,
  assetSchedules,
  startYearMonth,
  endYearMonth,
  row,
  isEditConfirm,
  setIsEditConfirm,
}: ISetManualEventProps) => {
  const { showSpinner, hideSpinner } = useSpinnerState()

  const dispatch = useAppDispatch()
  const [reScheduleWithManualEvent] = useReScheduleWithManualEventMutation()

  const startDateDisplay = `(${YearMonth.fromYearMonthOrIYearMonth(startYearMonth).toShortString()})`
  const endDateDisplay = `(${YearMonth.fromYearMonthOrIYearMonth(endYearMonth).toShortString()})`

  const editedMasterComponentId = row.original.forecastedComponent.masterComponentId ?? row.original.forecastedComponent.componentId
  const currentCheckType = row.original.forecastedComponent.checkType
  const originalYearMonth = row.original.yearMonth as IYearMonth
  const originalEventMonth = row.original.eventMonth

  const [checkTypesToConsider, setCheckTypesToConsider] = useState<CheckTypes[]>([currentCheckType])

  const [manualEvent, setManualEvent] = useState<ManualEventDto>({
    masterComponentId: editedMasterComponentId,
    affectedCheckTypes: checkTypesToConsider,
    originalYearMonth,
    changedYearMonth: originalYearMonth,
    originalEventMonth,
  })

  const componentEventsBeforeModifiedEvent: IMaintenanceScheduleDisplay[] = assetSchedules.filter((t) => {
    const eventYearMonth = t.yearMonth as IYearMonth
    return (
      (t.forecastedComponent.masterComponentId === editedMasterComponentId || t.forecastedComponent.componentId === editedMasterComponentId) &&
      (eventYearMonth.year < originalYearMonth.year ||
        (eventYearMonth.year === originalYearMonth.year && eventYearMonth.month < originalYearMonth.month))
    )
  })

  const lastComponentEventBeforeModifiedEvent = componentEventsBeforeModifiedEvent[componentEventsBeforeModifiedEvent.length - 1]

  // eslint-disable-next-line max-len
  const lastComponentEventBeforeModifiedEventDisplay = `(${!isNil(lastComponentEventBeforeModifiedEvent) && YearMonth.fromYearMonthOrIYearMonth(lastComponentEventBeforeModifiedEvent.yearMonth).toShortString()})`

  const componentCheckTypesForModifiedMonth = assetSchedules
    .filter((t) => {
      const eventYearMonth = t.yearMonth as IYearMonth
      return (
        eventYearMonth.year === originalYearMonth.year &&
        eventYearMonth.month === originalYearMonth.month &&
        (t.forecastedComponent.masterComponentId === editedMasterComponentId || t.forecastedComponent.componentId === editedMasterComponentId)
      )
    })
    .map((t) => t.forecastedComponent.checkType)

  const isFullEngineCheck = componentCheckTypesForModifiedMonth.filter((checkType) => isEngineCheck(checkType)).length === getEngineChecks().length

  // for now, we support only the case when all engine events are moved
  // so user doesn't need to confirm
  const [mustConfirmFullEngineCheck, setMustConfirmFullEngineCheck] = useState<boolean>(false)
  // force to move all engine checks
  const [moveAllEngineEvents, setMoveAllEngineEvents] = useState<boolean>(true)

  useEffect(() => {
    if (moveAllEngineEvents) {
      setCheckTypesToConsider(componentCheckTypesForModifiedMonth)
    }
  }, [componentCheckTypesForModifiedMonth, moveAllEngineEvents])

  useEffect(() => {
    setManualEvent((prevState) => ({ ...prevState, affectedCheckTypes: checkTypesToConsider }))
  }, [checkTypesToConsider])

  const isManualEventModifiedDateAfterLastEvent = useMemo(() => {
    const isAfter = isEngineCheck(currentCheckType)
      ? componentEventsBeforeModifiedEvent.filter(
          (t: IMaintenanceScheduleDisplay) =>
            YearMonth.fromObject(t.yearMonth as IYearMonth).compareTo(YearMonth.fromObject(manualEvent.changedYearMonth)) > 0,
        )
      : componentEventsBeforeModifiedEvent.filter(
          (t: IMaintenanceScheduleDisplay) =>
            // only engine can have multiple related check types for the same component
            t.forecastedComponent.checkType === currentCheckType &&
            YearMonth.fromObject(t.yearMonth as IYearMonth).compareTo(YearMonth.fromObject(manualEvent.changedYearMonth)) > 0,
        )

    return isAfter.length === 0
  }, [componentEventsBeforeModifiedEvent, currentCheckType, manualEvent.changedYearMonth])

  const isManualEventAfterStartDate = useMemo(() => {
    return YearMonth.fromObject(startYearMonth).compareTo(YearMonth.fromObject(manualEvent.changedYearMonth)) <= 0
  }, [startYearMonth, manualEvent])

  const isManualEventBeforeEndDate = useMemo(() => {
    return YearMonth.fromObject(endYearMonth).compareTo(YearMonth.fromObject(manualEvent.changedYearMonth)) >= 0
  }, [endYearMonth, manualEvent])

  return (
    <>
      {mustConfirmFullEngineCheck && (
        <ActionDialog
          isValid={true}
          confirmBtnLabel="Yes, move both"
          cancelBtnLabel={`No, move just ${CheckTypesTableDisplay(currentCheckType)}`}
          key={`conform-full-engine-${editedMasterComponentId}-${currentCheckType}-${originalYearMonth.year}-${originalYearMonth.month}`}
          onConfirm={async () => {
            setMoveAllEngineEvents(true)
          }}
          isOpen={mustConfirmFullEngineCheck}
          setIsOpen={setMustConfirmFullEngineCheck}
          dialogContent={
            <p className="font-semibold">
              This event includes both EPR and LLPs replacement.
              <br />
              Do you want to move both events?
            </p>
          }
        />
      )}
      {!mustConfirmFullEngineCheck && (
        <ActionDialog
          isValid={isManualEventModifiedDateAfterLastEvent && isManualEventAfterStartDate && isManualEventBeforeEndDate}
          confirmBtnLabel="Update"
          key={`set-event-${editedMasterComponentId}-${currentCheckType}-${originalYearMonth.year}-${originalYearMonth.month}`}
          onConfirm={async () => {
            showSpinner(0)
            const newSchedule = await reScheduleWithManualEvent({ manualEvent, reportItemId, assetType })
            hideSpinner(2)
            if (!isNil(newSchedule.data)) {
              dispatch(
                setAssetFlyResults({
                  assetId: newSchedule.data.assetId,
                  assetComponentsMonthlyStatistics: newSchedule.data.assetComponentsMonthlyStatistics,
                  eventSchedule: newSchedule.data.outputSchedules,
                  reportItemId: newSchedule.data.id,
                  reportItemStatus: newSchedule.data.status,
                }),
              )
            }
          }}
          isOpen={isEditConfirm}
          setIsOpen={setIsEditConfirm}
          dialogContent={
            <>
              <p className="pb-2 !text-sm font-medium">
                {isEngineCheck(currentCheckType) && !isFullEngineCheck && `${CheckTypesTableDisplay(currentCheckType)} only Event`}
              </p>
              <YearMonthSelector
                value={row.original.yearMonth as IYearMonth}
                setValue={(newValue: IYearMonth) => {
                  setManualEvent((prevState) => ({ ...prevState, changedYearMonth: newValue }))
                }}
                yearsInterval={{ startYear: startYearMonth.year, endYear: endYearMonth.year }}
              />
              {!isManualEventModifiedDateAfterLastEvent && (
                <p className="pt-2 !text-sm font-medium text-error">
                  Can&apos;t be before a previous event for the same component {lastComponentEventBeforeModifiedEventDisplay}
                </p>
              )}
              {!isManualEventAfterStartDate && (
                <p className="pt-2 !text-sm font-medium text-error">Can&apos;t be before the start date {startDateDisplay}</p>
              )}
              {!isManualEventBeforeEndDate && (
                <p className="pt-2 !text-sm font-medium text-error">Can&apos;t be after the end date {endDateDisplay}</p>
              )}
            </>
          }
        />
      )}
    </>
  )
}

export { SetManualEvent }
