import { flexRender, type Table } from '@tanstack/react-table'

const GenericTable = <TData,>(tableInstance: Table<TData>) => {
  return (
    <table>
      <thead>
        {tableInstance.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <th key={header.id} colSpan={header.colSpan} className="border border-black-20 bg-primary-dark-1">
                  {header.isPlaceholder ? null : <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>}
                </th>
              )
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {tableInstance.getRowModel().rows?.map((row) => {
          return (
            <tr key={row.id} className="bg-header-table">
              {row.getVisibleCells().map((cell) => {
                return (
                  <td key={cell.id} className="border border-black-20">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export { GenericTable }
