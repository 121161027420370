import { APIRoutes } from '@flyward/platform/constants'
import { flywardApi } from '@flyward/platform/store/slices/api/flywardApi/flywardApiSlice'
import { type IGetAircraftDetailsQueryRequest, type IGetAircraftDetailsQueryResponse } from './types'

const aircraftsApi = flywardApi.injectEndpoints({
  endpoints: (builder) => ({
    getAircraftDetails: builder.query<IGetAircraftDetailsQueryResponse, IGetAircraftDetailsQueryRequest>({
      query: ({ assetId }) => ({
        url: APIRoutes.AssetsModule.AircraftsController.GetDetails(assetId),
        method: 'GET',
      }),
      providesTags: (_, __, request) => [{ type: 'aircrafts', id: request.assetId }],
    }),
  }),
  overrideExisting: false,
})

export const { useGetAircraftDetailsQuery, util: aircraftsApiSliceUtil } = aircraftsApi

type Endpoints = typeof aircraftsApi.endpoints
type InitiateActions = {
  [K in keyof Endpoints]: ReturnType<ReturnType<Endpoints[K]['initiate']>>
}
export type TAircraftsApiActions = InitiateActions[keyof InitiateActions]
