import type { AircraftEngine } from '@flyward/assets/models'
import { CheckTypes, ComponentContainer, formatDate, getAssetAge, NumberCellWithLabel, NumberDisplayType, TextCellWithLabel } from '@flyward/platform'
import { isNil, maxBy, orderBy } from 'lodash'
import { EngineDetailsTabs } from './EngineDetailsTabs'
import { type ComponentUtilizationAtEvent } from '@flyward/platform/models/entities/Utilization'

interface IEngineDetailsProps {
  engine: AircraftEngine
}

const EngineDetails = ({ engine }: IEngineDetailsProps) => {
  let lastPerformanceRestoration: ComponentUtilizationAtEvent | null = null
  let performanceRestorationReserves = []

  const eprs = engine.assetComponentUtilizationSnapshot.componentUtilizationAtEvents.filter(
    (e) => e.eventType === CheckTypes.EnginePerformanceRestoration,
  )
  const lastEpr = maxBy(eprs, (e) => e.totalHoursSinceNewAtLastQualifyingEvent)
  if (!isNil(lastEpr)) {
    lastPerformanceRestoration = lastEpr
  }

  const orderedReserves = orderBy(
    engine.engineContract.contractualFlightHoursToFlightCyclesPerformanceRestorationReserves,
    (r) => r.flightHoursToFlightCyclesRatio,
  )
  performanceRestorationReserves = orderedReserves

  return (
    <ComponentContainer className="p-6">
      <div className="flex w-full flex-col">
        <p className="mb-4 w-full border-b border-primary-light-2 pb-2 text-base font-semibold text-text-1">Engine</p>
        <div className="mb-2 flex flex-col gap-y-2">
          <div className="flex gap-x-6">
            <TextCellWithLabel className="basis-1/6" label="ESN" info={engine.componentSerialNumber} />
            <TextCellWithLabel className="basis-1/6" label="Manufacturer" info={engine.manufacturingDetail.manufacturer} />
            <TextCellWithLabel className="basis-1/6" label="Engine Model" info={engine.componentModel} />
            <NumberCellWithLabel
              className="basis-1/6"
              label="Engine Thrust rating"
              info={engine.engineThrustRating}
              displayType={NumberDisplayType.Integer}
            />
            <TextCellWithLabel className="basis-1/6" label="DOM" info={formatDate(engine.manufacturingDetail.dom)} />
            <TextCellWithLabel className="basis-1/6" label="Engine Age" info={getAssetAge(engine.manufacturingDetail.dom)} />
          </div>
        </div>
        <EngineDetailsTabs
          engine={engine}
          lastPerformanceRestoration={lastPerformanceRestoration}
          performanceRestorationReserves={performanceRestorationReserves}
        />
      </div>
    </ComponentContainer>
  )
}

export { EngineDetails }
export type { IEngineDetailsProps }
