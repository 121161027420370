import { format, formatDate } from 'date-fns'
import { useState } from 'react'
import Calendar from 'react-calendar'
import { Button, ButtonVariant, Popover, PopoverContent, PopoverTrigger } from '../_shadcn'
import { cn } from '../_shadcn/utils'
import { Size } from '../common/Size'
import { Icon } from '../Icon/Icon'
import { IconVariant } from '../Icon/IconVariant'

import './styles.css'

interface IDateLimit {
  value: Date
  type: 'min' | 'max'
}

interface IDatePickerProps {
  setValue: (value?: Date) => void
  disabled?: boolean
  label?: string
  limit?: IDateLimit
  placeholder?: string
  value?: Date
}

const DatePicker = ({ label, setValue, disabled = false, limit = undefined, placeholder = 'Pick a date', value = undefined }: IDatePickerProps) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false)
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(value)

  const onDateSelected = (date: Date) => {
    setIsDatePickerOpen(false)

    if (selectedDate?.setHours(0, 0, 0, 0) === date.setHours(0, 0, 0, 0)) {
      setSelectedDate(undefined)
      setValue(undefined)

      return
    }

    setSelectedDate(date)
    setValue(date)
  }

  return (
    <Popover open={isDatePickerOpen} onOpenChange={setIsDatePickerOpen}>
      <PopoverTrigger asChild>
        <div className={cn('relative flex flex-col justify-center gap-y-0.4', disabled && 'pointer-events-none')} data-testid="date-picker-trigger">
          {label !== '' && (
            <p className={cn('!text-xs font-semibold', disabled ? 'text-text-3' : 'text-text-1')} data-testid="date-picker-trigger-label">
              {label}
            </p>
          )}
          <Button
            className={cn(
              // eslint-disable-next-line max-len
              'flex h-9 w-65 max-w-65 flex-row items-center justify-between rounded-s border border-solid border-black-20 bg-black-0 px-3 py-2 text-sm aria-expanded:border-primary hover:border-primary disabled:border-black-10',
            )}
            data-testid="date-picker-trigger-button"
            disabled={disabled}
            onClick={() => {
              setIsDatePickerOpen(!isDatePickerOpen)
            }}
            variant={ButtonVariant.Ghost}
          >
            {selectedDate !== undefined ? (
              <span className={cn('text-text-1', disabled && 'text-black-20')}>{format(selectedDate, 'dd-MMM-yyyy')}</span>
            ) : (
              <span className={cn('text-text-3', disabled && 'text-black-20')}>{placeholder}</span>
            )}
            <Icon className="text-primary" size={Size.Small} variant={IconVariant.Calendar} />
          </Button>
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" side="top">
        <Calendar
          data-testid="date-picker-calendar"
          formatMonth={(_, date) => formatDate(date, 'MMM')}
          locale="en-US"
          minDate={limit !== undefined && limit.type === 'min' ? limit.value : undefined}
          maxDate={limit !== undefined && limit.type === 'max' ? limit.value : undefined}
          nextLabel={<Icon className="text-primary" size={Size.Small} variant={IconVariant.KeyboardArrowRight} />}
          next2Label={null}
          onChange={(date) => {
            onDateSelected(date as Date)
          }}
          prevLabel={<Icon className="text-primary" size={Size.Small} variant={IconVariant.KeyboardArrowLeft} />}
          prev2Label={null}
          value={selectedDate}
        />
      </PopoverContent>
    </Popover>
  )
}

export { DatePicker }
export type { IDateLimit }
