import { AlertDialogWithTrigger, IconVariant, Input, InputType, ToastVariant, useHeaderContent, useToast } from '@flyward/platform'
import { useSaveReportWithNameMutation } from '@flyward/platform/store'
import { useAppSelector } from '@flyward/platform/store/configureHooks'
import { selectSuccessfulReportId } from '@flyward/platform/store/slices/features/flyForward/flyForwardSelectors'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'

const FlyForwardSave = () => {
  const reportId = useAppSelector(selectSuccessfulReportId)

  const { setCustomElements } = useHeaderContent()
  const { toast } = useToast()

  const [reportName, setReportName] = useState('')
  const [isReportNameValid, setIsReportNameValid] = useState(false)

  const [saveReportWithName] = useSaveReportWithNameMutation()

  useEffect(() => {
    const handleSave = async () => {
      if (reportId === undefined) return
      saveReportWithName({ id: reportId, name: reportName.trim() })
      toast({
        variant: ToastVariant.Success,
        description: 'Your forecast report has been successfully saved!',
        icon: IconVariant.Success,
      })
    }

    setCustomElements(
      !isEmpty(reportId)
        ? [
            <AlertDialogWithTrigger
              isValid={reportName.trim().length >= 3 && isReportNameValid}
              isTriggerVisible={true}
              confirmBtnLabel="Save"
              triggerBtnIcon={IconVariant.Save}
              triggerBtnLabel="Save"
              key={`save-${reportId}`}
              onConfirm={handleSave}
              onSuccess={() => {
                setReportName('')
              }}
              dialogContent={
                <Input
                  label="Report name"
                  labelClassName="text-semibold text-text-1"
                  setValueAfterValidation={(value: string) => {
                    setReportName(value.trimStart())
                  }}
                  type={InputType.Alphanumeric}
                  inputClassName="w-full"
                  setIsValid={setIsReportNameValid}
                />
              }
            />,
          ]
        : [],
    )
  }, [isReportNameValid, reportId, reportName, saveReportWithName, setCustomElements, toast])

  return null
}

export { FlyForwardSave }
