import { APIRoutes } from '@flyward/platform/constants'
import { flywardApi } from '@flyward/platform/store/slices/api/flywardApi/flywardApiSlice'
import {
  type IPutAirframeMaintenanceProgramMutationRequest,
  type IGetAllAirframesByAssetIdQueryRequest,
  type IGetAllAirframesByAssetIdQueryResponse,
  type IGetAllAirframeChecksWithDetailsByAssetIdQueryResponse,
  type IGetAllAirframeChecksWithDetailsByAssetIdQueryRequest,
} from './types'
import { ComponentCategory, ComponentTypeURI } from '@flyward/platform'

const airframesApi = flywardApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllAirframesByAssetId: builder.query<IGetAllAirframesByAssetIdQueryResponse, IGetAllAirframesByAssetIdQueryRequest>({
      query: ({ assetId }) => ({
        url: APIRoutes.AssetsModule.Components.AirframesController.GetAll(assetId),
        method: 'GET',
      }),
      providesTags: (_, __, request) => [{ type: 'airframes', id: request.assetId }],
    }),
    getAllAirframeChecksWithDetailsByAssetId: builder.query<
      IGetAllAirframeChecksWithDetailsByAssetIdQueryResponse,
      IGetAllAirframeChecksWithDetailsByAssetIdQueryRequest
    >({
      query: ({ assetId }) => ({
        url: APIRoutes.AssetsModule.Components.AirframesController.GetAllChecksWithDetails(assetId),
        method: 'GET',
      }),
      providesTags: (_, __, request) => [{ type: 'componentChecks', id: `${request.assetId}-${ComponentTypeURI(ComponentCategory.Airframe)}` }],
    }),
    updateAirframeMaintenanceProgram: builder.mutation<void, IPutAirframeMaintenanceProgramMutationRequest>({
      query: ({ assetId, kbProgramId }) => ({
        url: APIRoutes.AssetsModule.Components.AirframesController.MaintenanceProgram(assetId),
        method: 'PUT',
        data: kbProgramId,
      }),
      invalidatesTags: (_, __, request) => [{ type: 'airframes', id: request.assetId }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAllAirframesByAssetIdQuery,
  useGetAllAirframeChecksWithDetailsByAssetIdQuery,
  useUpdateAirframeMaintenanceProgramMutation,
  util: airframesApiSliceUtil,
} = airframesApi

type Endpoints = typeof airframesApi.endpoints
type InitiateActions = {
  [K in keyof Endpoints]: ReturnType<ReturnType<Endpoints[K]['initiate']>>
}
export type TAirframesApiActions = InitiateActions[keyof InitiateActions]
