import { APIRoutes } from '@flyward/platform/constants'
import { flywardApi } from '@flyward/platform/store/slices/api/flywardApi/flywardApiSlice'
import queryString from 'query-string'
import {
  type IGetUsersNamesQueryRequest,
  type IAddEditUserQueryRequest,
  type IGetMeQueryResponse,
  type IGetUsersNamesQueryResponse,
  type IGetUsersQueryResponse,
} from './types'
import { isNil } from 'lodash'

const usersApi = flywardApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsersNames: builder.query<IGetUsersNamesQueryResponse, IGetUsersNamesQueryRequest>({
      query: ({ hasReports }) => {
        const urlSearchParams = new URLSearchParams()

        !isNil(hasReports) && urlSearchParams.append('hasReports', hasReports?.toString().toLowerCase())

        return {
          url: APIRoutes.AppIdentityModule.UserController.GetAllUsersNames(),
          params: urlSearchParams,
        }
      },
      providesTags: ['userNames'],
    }),
    getMe: builder.query<IGetMeQueryResponse, void>({
      query: () => ({
        url: APIRoutes.AppIdentityModule.UserController.GetMe(),
        method: 'GET',
      }),
      providesTags: ['loggedUser'],
    }),
    getUsers: builder.query<IGetUsersQueryResponse, void>({
      query: () => ({
        url: APIRoutes.AppIdentityModule.UserController.GetAll(),
        method: 'GET',
      }),
      providesTags: (_, __, _request) => [{ type: 'users' }],
      keepUnusedDataFor: 0,
    }),
    addUser: builder.mutation<string, IAddEditUserQueryRequest>({
      query: (user) => ({
        url: APIRoutes.AppIdentityModule.UserController.AddUserEdit(),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(user),
      }),
      invalidatesTags: (_, __, _request) => [{ type: 'users' }],
    }),
    editUser: builder.mutation<string, IAddEditUserQueryRequest>({
      query: (user) => ({
        url: APIRoutes.AppIdentityModule.UserController.AddUserEdit(),
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(user),
      }),
      invalidatesTags: (_, __, _request) => [{ type: 'users' }],
    }),
    changeState: builder.mutation<string, string>({
      query: (userId) => {
        const params = queryString.stringify({ userId }, { arrayFormat: 'none' })
        return {
          url: `${APIRoutes.AppIdentityModule.UserController.ChangeState()}?${params}`,
          method: 'POST',
        }
      },
      invalidatesTags: (_, __, _request) => [{ type: 'users' }],
    }),
    resendEmail: builder.mutation<string, string>({
      query: (userId) => {
        const params = queryString.stringify({ userId }, { arrayFormat: 'none' })
        return {
          url: `${APIRoutes.AppIdentityModule.UserController.ResendInvitation()}?${params}`,
          method: 'POST',
        }
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetUsersNamesQuery,
  useGetUsersQuery,
  useAddUserMutation,
  useChangeStateMutation,
  useResendEmailMutation,
  useEditUserMutation,
  useGetMeQuery,
  util: usersApiSliceUtil,
} = usersApi

type Endpoints = typeof usersApi.endpoints
type InitiateActions = {
  [K in keyof Endpoints]: ReturnType<ReturnType<Endpoints[K]['initiate']>>
}
export type TUsersApiActions = InitiateActions[keyof InitiateActions]
