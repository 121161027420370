import { AircraftMaintenanceProgram } from '@flyward/assets'
import { AlertDialogWithTrigger, ErrorBoundary, IconVariant, ToastVariant, useHeaderContent, useToast } from '@flyward/platform'
import {
  useGetAllAirframeMaintenanceProgramsQuery,
  useGetAllAuxiliaryPowerUnitMaintenanceProgramsQuery,
  useGetAllEngineMaintenanceProgramsQuery,
  useGetAllLandingGearMaintenanceProgramsQuery,
  useUpdateAirframeMaintenanceProgramMutation,
  useUpdateAuxiliaryPowerUnitsMaintenanceProgramMutation,
  useUpdateEnginesMaintenanceProgramMutation,
  useUpdateLandingGearsMaintenanceProgramMutation,
} from '@flyward/platform/store'
import { isEmpty } from 'lodash'
import { useCallback, useEffect, useState } from 'react'

interface IAircraftMaintenanceProgramsSection {
  assetId: string
  airframeKBProgramId: string
  engineKBProgramId: string
  auxiliaryPowerUnitKBProgramId: string
  landingGearKBProgramId: string
}

export const AircraftMaintenanceProgramsSection = ({
  assetId,
  airframeKBProgramId,
  engineKBProgramId,
  auxiliaryPowerUnitKBProgramId,
  landingGearKBProgramId,
}: IAircraftMaintenanceProgramsSection) => {
  const { toast } = useToast()

  const { setCustomElements } = useHeaderContent()

  const initialValues = {
    airframeKBProgramId,
    engineKBProgramId,
    auxiliaryPowerUnitKBProgramId,
    landingGearKBProgramId,
  }

  const [airframeAssignedKBProgramId, setAirframeAssignedKBProgramId] = useState(airframeKBProgramId)
  const [engineAssignedKBProgramId, setEngineAssignedKBProgramId] = useState(engineKBProgramId)
  const [auxiliaryPowerUnitAssignedKBProgramId, setAuxiliaryPowerUnitAssignedKBProgramId] = useState(auxiliaryPowerUnitKBProgramId)
  const [landingGearAssignedKBProgramId, setLandingGearAssignedKBProgramId] = useState(landingGearKBProgramId)

  const [hasChanges, setHasChanges] = useState(false)

  const [updateAirframeMaintenanceProgram] = useUpdateAirframeMaintenanceProgramMutation()
  const [updateEngineMaintenanceProgram] = useUpdateEnginesMaintenanceProgramMutation()
  const [updateAuxiliaryPowerUnitMaintenanceProgram] = useUpdateAuxiliaryPowerUnitsMaintenanceProgramMutation()
  const [updateLandingGearMaintenanceProgram] = useUpdateLandingGearsMaintenanceProgramMutation()

  const handleSave = useCallback(async () => {
    try {
      if (!isEmpty(airframeAssignedKBProgramId)) {
        updateAirframeMaintenanceProgram({ assetId, kbProgramId: airframeAssignedKBProgramId })
      }
      if (!isEmpty(engineAssignedKBProgramId)) {
        updateEngineMaintenanceProgram({ assetId, kbProgramId: engineAssignedKBProgramId })
      }
      if (!isEmpty(auxiliaryPowerUnitAssignedKBProgramId)) {
        updateAuxiliaryPowerUnitMaintenanceProgram({ assetId, kbProgramId: auxiliaryPowerUnitAssignedKBProgramId })
      }
      if (!isEmpty(landingGearAssignedKBProgramId)) {
        updateLandingGearMaintenanceProgram({ assetId, kbProgramId: landingGearAssignedKBProgramId })
      }
    } catch (error) {
      toast({
        variant: ToastVariant.Destructive,
        description: 'Asset update failed!',
        icon: IconVariant.Success,
      })
    }
    toast({
      variant: ToastVariant.Success,
      description: 'Asset has been updated!',
      icon: IconVariant.Success,
    })
    setHasChanges(false)
  }, [
    airframeAssignedKBProgramId,
    assetId,
    auxiliaryPowerUnitAssignedKBProgramId,
    engineAssignedKBProgramId,
    landingGearAssignedKBProgramId,
    toast,
    updateAirframeMaintenanceProgram,
    updateAuxiliaryPowerUnitMaintenanceProgram,
    updateEngineMaintenanceProgram,
    updateLandingGearMaintenanceProgram,
  ])

  const handleCancel = useCallback(() => {
    setAirframeAssignedKBProgramId(initialValues.airframeKBProgramId)
    setEngineAssignedKBProgramId(initialValues.engineKBProgramId)
    setAuxiliaryPowerUnitAssignedKBProgramId(initialValues.auxiliaryPowerUnitKBProgramId)
    setLandingGearAssignedKBProgramId(initialValues.landingGearKBProgramId)
    setHasChanges(false)
  }, [
    initialValues.airframeKBProgramId,
    initialValues.auxiliaryPowerUnitKBProgramId,
    initialValues.engineKBProgramId,
    initialValues.landingGearKBProgramId,
  ])

  useEffect(() => {
    const isSaveButtonVisible =
      hasChanges &&
      !isEmpty(airframeAssignedKBProgramId) &&
      !isEmpty(engineAssignedKBProgramId) &&
      !isEmpty(auxiliaryPowerUnitAssignedKBProgramId) &&
      !isEmpty(landingGearAssignedKBProgramId)

    setCustomElements(
      hasChanges
        ? [
            <AlertDialogWithTrigger
              isValid={true}
              isTriggerVisible={isSaveButtonVisible}
              confirmBtnLabel="Save"
              triggerBtnIcon={IconVariant.Save}
              triggerBtnLabel="Save"
              key={`save-${assetId}`}
              onConfirm={handleSave}
              onCancel={handleCancel}
              dialogContent={<p>Save changes?</p>}
            />,
          ]
        : [],
    )
  }, [
    setCustomElements,
    hasChanges,
    assetId,
    airframeAssignedKBProgramId,
    engineAssignedKBProgramId,
    auxiliaryPowerUnitAssignedKBProgramId,
    landingGearAssignedKBProgramId,
    handleSave,
    handleCancel,
  ])

  const { data: availableEnginePrograms } = useGetAllEngineMaintenanceProgramsQuery(false)
  const { data: availableAirframePrograms } = useGetAllAirframeMaintenanceProgramsQuery(false)
  const { data: availableLandingGearPrograms } = useGetAllLandingGearMaintenanceProgramsQuery(false)
  const { data: availableAuxiliaryPowerUnitPrograms } = useGetAllAuxiliaryPowerUnitMaintenanceProgramsQuery(false)

  const changeAirframeProgram = (airframeProgramId: string) => {
    setAirframeAssignedKBProgramId(airframeProgramId)
    setHasChanges(true)
  }

  const changeEngineProgram = (engineProgramId: string) => {
    setEngineAssignedKBProgramId(engineProgramId)
    setHasChanges(true)
  }

  const changeAuxiliaryPowerUnitProgram = (auxiliaryPowerUnitProgramId: string) => {
    setAuxiliaryPowerUnitAssignedKBProgramId(auxiliaryPowerUnitProgramId)
    setHasChanges(true)
  }

  const changeLandingGearProgram = (landingGearProgramId: string) => {
    setLandingGearAssignedKBProgramId(landingGearProgramId)
    setHasChanges(true)
  }

  return (
    <ErrorBoundary>
      <AircraftMaintenanceProgram
        availablePrograms={{
          availableAirframePrograms:
            availableAirframePrograms?.map((program) => {
              return {
                value: program.masterComponentKBProgramId,
                label: program.maintenanceProgramName,
              }
            }) ?? [],
          availableEnginePrograms:
            availableEnginePrograms?.map((program) => {
              return {
                value: program.masterComponentKBProgramId,
                label: program.maintenanceProgramName,
              }
            }) ?? [],
          availableLandingGearPrograms:
            availableLandingGearPrograms?.map((program) => {
              return {
                value: program.masterComponentKBProgramId,
                label: program.maintenanceProgramName,
              }
            }) ?? [],
          availableAuxiliaryPowerUnitPrograms:
            availableAuxiliaryPowerUnitPrograms?.map((program) => {
              return {
                value: program.masterComponentKBProgramId,
                label: program.maintenanceProgramName,
              }
            }) ?? [],
        }}
        changePrograms={{
          changeAirframeProgram,
          changeEngineProgram,
          changeAuxiliaryPowerUnitProgram,
          changeLandingGearProgram,
        }}
        assignedAirframeProgramId={airframeAssignedKBProgramId}
        assignedEngineProgramId={engineAssignedKBProgramId}
        assignedAPUProgramId={auxiliaryPowerUnitAssignedKBProgramId}
        assignedLandingGearProgramId={landingGearAssignedKBProgramId}
      />
    </ErrorBoundary>
  )
}
